<template>
  <router-view v-if="$route.path.match(/^\/(login)|(register)|(reset)/)" class="bg-login"></router-view>
  <cf-container v-else-if="$route.matched.length" class="bg-home">
    <cf-sidebar class="active">
      <TabMenu :model="tabs" @tab-change="onTabChange" :active-index="activeIndex" :class="'main-menu flex-grow-1'">
        <template #item="{item}">
          <a role="menuitem" class="p-menuitem-link" aria-label="Container" tabindex="0"><span :class="'p-menuitem-icon '+item.icon"></span><span class="p-menuitem-text" v-html="item.label"></span></a>
        </template>
      </TabMenu>
      <div class="p-tabmenu p-component main-menu">
        <ul class="p-tabmenu-nav p-reset" role="tablist">
          <li class="p-tabmenuitem" >
            <a class="p-menuitem-link" role="presentation" @click.prevent="syncPush" v-if="suggestSaveStatePush">
              <span :class="syncIcon"></span><span class="p-menuitem-text"><small style="display:inline-block;white-space:nowrap;overflow:hidden;text-overflow:ellipsis;max-width:75px">Remote</small><br>Sync</span>
            </a>
          </li>
          <li class="p-tabmenuitem" role="tab">
            <a class="p-menuitem-link" role="presentation" @click.prevent="logout" v-if="localProfile">
              <span class="p-menuitem-icon pi pi-sign-out"></span><span class="p-menuitem-text"><small style="display:inline-block;white-space:nowrap;overflow:hidden;text-overflow:ellipsis;max-width:75px">{{localProfile.username}}</small><br>Abmelden</span>
            </a>
            <a class="p-menuitem-link" role="presentation" @click.prevent="login" v-else>
              <span class="p-menuitem-icon pi pi-sign-in"></span><span class="p-menuitem-text">Anmelden</span>
            </a>
          </li>
        </ul>
      </div>
    </cf-sidebar>
    <cf-content>
      <router-view v-slot="{ Component }">
        <keep-alive>
          <component :is="Component" :key="getCacheState(Component)"/>
        </keep-alive>
      </router-view>
    </cf-content>
  </cf-container>
  <DynamicDialog></DynamicDialog>
  <ConfirmDialog></ConfirmDialog>
  <Toast></Toast>
</template>
<script>
import {Bookeeper} from "@/bookeeper";
import {appState} from "@/reactive";

export default {
  name: 'App',
  components: {
  },
  data() {
    return {
      tabs: [
        {label: 'Klienten', root:"/clients", icon:"pi pi-users"},
        {label: 'Rechnungen', root:"/invoices", icon:"pi pi-credit-card"},
        {label: 'Kontoauszug', root: '/payments', icon:"pi pi-dollar"},
        {label: 'Abschreibungen', root: '/writeoffs', icon:"pi pi-times"},
        {label: 'Vorlagen', root: '/templates', icon:"pi pi-file"},
        {label: 'Einstellungen', root: '/settings', icon:"pi pi-cog"}
      ],
      activeIndex:1,
      sidebarVisible:true,
      localProfile:null,
      latestRemote:null,
      latestLocal:null,
      suggestSaveStatePush:false,
      store:null,
      syncIcon:"p-menuitem-icon pi pi-cloud-upload"
    }
  },
  watch:{
    $route (){
      this.onRouteChange(this.$route.path);
    }
  },
  async mounted() {
    if (!this.$route.matched.length)
      return;
    this.onRouteChange(this.$route.path);
    let debounceSwitch = 200;
    let lastScrollTop = 0;
    let lastSwitchTime = 0;
    window.addEventListener('scroll', () => {
      let node = document.querySelectorAll("#app .p-datatable-header");
      node = node && node.length ? node[node.length-1] : node;
      let now = new Date().getTime();
      if (now-lastSwitchTime>debounceSwitch) {
        lastSwitchTime = now;
        let scrollTop = document.scrollingElement.scrollTop;
        let up = scrollTop<lastScrollTop;
        if (up)
          node.classList.remove("stick-off");
        else if (scrollTop>500)
          node.classList.add("stick-off");
        lastScrollTop = scrollTop;
      }
    });
    this.store = await new Bookeeper().init();
    this.refreshSyncState(true);

    // needed for reactivity (filteredTabs)
    window.addEventListener('auth-change',()=>{
      this.localProfile = this.authService.getLocalProfile();
    });

    // needed for reactivity (filteredTabs)
    window.addEventListener('savestate-change',()=>{
      this.refreshSyncState();
    });

    window.addEventListener('login',async ()=>{
      await this.authService.ensureAuth();
      this.refreshSyncState(true);
    });

    document.addEventListener("visibilitychange", () => {
      if (!document.hidden)
        this.refreshSyncState(true);
    });
  },
  created() {
    this.localProfile = this.authService.getLocalProfile();
  },
  methods: {
    async logout() {
      await this.authService.logout();
      if (process.env.VUE_APP_EXTAUTH_LOGOUT)
        window.location.href = `${process.env.VUE_APP_EXTAUTH_LOGOUT}/?r=${window.location.origin}/auth`;
      else {
        this.login();
      }
    },
    login() {
      this.$router.push("/login");
    },
    onTabChange(e) {
      this.$router.push(this.activeIndex==e.index ? this.tabs[e.index].root  : this.tabs[e.index].path || this.tabs[e.index].root)
    },
    onRouteChange(currentPath) {
      let i=0;
      while (i<this.tabs.length && !currentPath.match(new RegExp("^"+this.tabs[i].root))) {
        i++;
      }
      if (i<this.tabs.length) {
        this.tabs[i].path = currentPath;
        this.activeIndex = i;
      }
    },
    getCacheState(Component) {
      return `${this.$route.path}-${Component.type.name==='ClientsView' || Component.type.name==='InvoicesView' || Component.type.name==='PaymentsView' ? appState.getCacheId(this.$route.path) : Math.random()}`;
    },
    updateSyncIcon(syncing=false) {
      this.syncIcon = syncing ? "p-menuitem-icon pi pi-sync pi-spin" : "p-menuitem-icon pi pi-cloud-upload";
    },
    async refreshSyncState(remote=false) {
      if (remote)
        this.latestRemote = await this.store.getRemoteSaveState();
      this.latestLocal = this.store.getLocalSaveState();
      console.log(this.latestRemote,this.latestLocal);
      if (remote && parseFloat(this.latestRemote)>(parseFloat(this.latestLocal)||0))
        this.requestSyncPull(this.latestRemote)
      else
        this.suggestSaveStatePush = (this.latestRemote!==this.latestLocal);
    },
    async syncPush() {
      this.updateSyncIcon(true);
      await this.store.saveDatabase();
      this.updateSyncIcon();
      this.refreshSyncState(true);
    },
    async requestSyncPull(savestate) {
      this.$confirm.require({
        acceptClass:"p-button-primary",
        rejectClass:"p-button-text p-button-plain",
        acceptLabel:"Ja",
        rejectLabel:"Nein",
        message: `Ihre Datenbank wurde auf einem anderen Gerät bearbeitet, Stand ${savestate}. Importieren?`,
        header: 'Neue Daten verfügbar',
        icon: 'pi pi-question-circle',
        accept: async () => {
          this.store.restoreDatabaseFromSaveState(savestate).then(async ()=>{
            this.$toast.add({severity:'success', summary: 'Erfolgreich', detail:'Einstellungen importiert', life: 3000})
            await this.refreshSyncState(true);
          }).catch(({code,message}) => {
            this.$toast.add({severity:'error', summary: 'Error Code '+code, detail:message, life: 3000});
          });
        },
        reject: () => {

        }
      });
    }
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
  display: flex;
  flex-direction: column;
  min-height:100%;
}
html, body {
  min-height:100%;
  height:0;
}
body {
  margin:0;
  overflow-y:scroll!important;
}
/*.p-tabmenu.p-component {
  position: sticky;
  top: 0;
  z-index: 10;
}*/
.p-tabmenu.p-component {
  position: sticky;
  top: 0;
  opacity: 1;
  transition: all 0.3s ease-out;
  z-index: 1000;
}
.p-tabmenu.p-component.stick-off, .breadcrumb-container.stick-off {
  top: -3rem;
  opacity:  0;
}
.p-breadcrumb.p-component, .breadcrumb-container {
  position: sticky;
  top: 0;
  opacity: 1;
  transition: all 0.3s ease-out;
  z-index: 1010;
  border: none;
}
.breadcrumb-container {
  background-color:#ffffff;
}
.breadcrumb-container>nav {
  flex-grow:1;
}
cf-container {
  display:flex;
  flex-grow: 1;
}
cf-sidebar {
  width: 0;
  transition: all 0.3s ease-out;
  display:flex;
  flex-direction: column;
}
cf-content {
  flex-grow:1;
  transition: all 0.3s ease-out;
}
cf-sidebar.active {
  width: 200px;

}
.p-tabmenu.main-menu>ul {
  flex-direction: column;
  border-bottom: none;
}
.p-tabmenu .p-tabmenu-nav .p-tabmenuitem .p-menuitem-link:not(.p-disabled):focus {
  box-shadow: none!important;
  border: none;
}
.p-tabmenu .p-tabmenu-nav .p-tabmenuitem .p-menuitem-link:not(.p-disabled) {
  border: none;
}
.p-inplace.inplace-html>.p-inplace-display {
  display:block;
}
.p-confirm-dialog {
  min-width: 300px;
  max-width:100vw;
}
.p-accordion .p-inplace input.p-inputtext {
  background-color: transparent;
  border:none;
  padding:0 0 0 8px;
}
.text-danger {
  color: red!important;
}
.p-autocomplete input {
  width: 100%;
}
a.p-tabview-nav-link {
  overflow: visible;
}
.tabview-extended i {
  margin-left: 1rem;
}
.p-tabview .p-tabview-nav li {
  z-index:10;
}
.accordion-records .p-editor-toolbar {
  border-radius: 0!important;
}
.accordion-records  .p-editor-container .p-editor-content {
  border-radius: 0!important;
}
.accordion-records .p-accordion-content {
  padding: 0!important;
  border: none!important;
}
.accordion-records .ql-editor {
  border-radius: 0!important;
}
.accordion-records .p-inplace .p-inplace-display {
  border-radius: 0!important;
}
.accordion-records .p-accordion-header-link {
  border: none!important;
  background-color: transparent!important;;
  padding: 0.5rem 0!important;;
}
.accordion-records .p-accordion-header-link:focus {
  box-shadow: none!important;
}
.p-tabview.client-tabview .p-tabview-nav li .p-tabview-nav-link {
  padding:0.5rem!important;
}
span[contenteditable='true'] {
  cursor:text;
}
.p-datatable.payments tr>td {
  cursor:pointer;
  vertical-align: middle;
}
.p-dialog-content .p-panel .p-panel-header {
  padding:1rem!important;
  border-top: 1px solid #dee2e6!important;
}
.p-dialog-content .p-panel+.p-panel .p-panel-header {
  border-top: none!important;
}
.p-datatable-header {
  transition: all 0.3s ease-out;
  position: sticky;
  top: var(--breadcrumbsHeight,0);
  z-index: 10;
}
.p-datatable-header.stick-off {
  top: -100px;
}
.importEventsTable > .p-datatable-header {
  padding: 0 0 1rem 0!important;
  background: transparent!important;;
  border-top: none!important;
  position: static;
}
.p-datatable.importEventsTable .p-datatable-tbody > tr > td {
  vertical-align:middle
}

.p-dialog .tan-dialog-content.p-dialog-content {
  padding: 1rem;
}
.p-panel.templates-panel .p-panel-header {
  padding: 0.5rem 0.5rem 0 1rem!important;
  border-top: 1px solid #dee2e6!important;
  border-bottom: none!important;
  background-color: transparent;
}
.p-panel.templates-panel .p-panel-content {
  padding-top: 0!important;
}
.p-panel.templates-panel .p-panel-content .p-fileupload:first-child .p-button {
  margin-left: 0!important;
}
</style>
