<template>
  <div class="flex flex-row justify-content-between align-items-center breadcrumb-container" ref="breadcrumbs">
    <Breadcrumb :home="home" :model="listCrumbs">
      <template #item="{item}">
        <span @click="$router.push(item.url||'')"><i :class="item.icon"></i>{{item.label}}</span>
      </template>
    </Breadcrumb>
  </div>
  <DataTable :value="rows" responsiveLayout="scroll" stripedRows :sortField="defaultSort.field" :sortOrder="defaultSort.order" :rowClass="rowClass" class="invoice">
    <template #header>
      <div class="flex justify-content-between align-items-center">
        <span class="font-weight-600">Rechnung</span>
      </div>
    </template>
    <Column v-for="col of columns" :field="col.field" :header="col.header" :key="col.field" :hidden="col.hidden"></Column>
    <Column bodyStyle="text-align: right">
      <template #body >
        <div class="flex flex-row justify-content-end">
          <Button v-if="!rows[0].paymentId" type="button" icon="pi pi-trash" class="p-button-danger mr-2" @click="requestDeleteInvoice"></Button>
          <Button type="button" icon="pi pi-cog" class="p-button-secondary" @click="manageInvoiceSettings"></Button>
        </div>
      </template>
    </Column>
  </DataTable>
  <DataTable :value="matchingRows" responsiveLayout="scroll" stripedRows :rowClass="rowClass" class="payment" v-model:selection="assignedPayments" dataKey="id" @rowSelect="onRowSelect" @rowUnselect="onRowUnselect"
    v-model:filters="filters" filterDisplay="menu" style="margin-top: 2rem"
    stateStorage="session" stateKey="dt-state-invoice">
    <template #header>
      <div class="flex justify-content-between align-items-center">
        <span class="font-weight-600">Gutschrift zuordnen</span>
        <span class="p-input-icon-left">
            <i class="pi pi-search" />
            <InputText v-model="filters['global'].value" placeholder="Suche" />
            <i class="pi pi-times right-0 mr-2 cursor-pointer" v-if="filters['global'].value" @click="filters['global'].value=''"/>
        </span>
      </div>
    </template>
    <Column selectionMode="multiple"></Column>
    <Column v-for="col of matchingColumns" :field="col.field" :header="col.header" :key="col.field" :sortable="col.sortable" :hidden="col.hidden"></Column>
    <Column header="Verbucht">
      <template #body="slotProps">
        <div class="flex flex-row justify-content-start">
          <Button :icon="slotProps.data.hasInvoices ? 'pi pi-check' : ''" class="p-button-rounded p-button-plain p-button-text p-button-lg p-button-success"/>
        </div>
      </template>
    </Column>
    <Column bodyStyle="text-align: right">
      <template #body="slotProps">
        <div class="flex flex-row justify-content-end">
          <Button icon="pi pi-arrow-right" class="p-button-rounded p-button-plain p-button-text p-button-lg" @click="gotoPayment(slotProps.data)"/>
        </div>
      </template>
    </Column>
    <template #empty>
      Keine offenen Gutschriften verfügbar
    </template>
  </DataTable>
  <Dialog v-model:visible="displayInvoiceSettings" :modal="true" :dismissable-mask="true" :close-on-escape="true" position="top" :draggable="false" class="invoice">
    <template #header>
      <h3>{{rows[0].name}} - {{rows[0].description}}</h3>
    </template>
    <Panel header="Mahnstufe">
      <SelectButton v-model="selectedWarning" :options="listWarnings.slice(0,3)" optionLabel="name" @change="updateWarningLevel"/>
    </Panel>
    <Panel header="Eskalation">
      <SelectButton v-model="selectedWarning" :options="listWarnings.slice(3,5)" optionLabel="name" @change="updateWarningLevel"/>
    </Panel>
  </Dialog>
</template>
<script>

import {Bookeeper} from "@/bookeeper";
import {FilterMatchMode} from "primevue/api";
import {appState} from "@/reactive";
import AuthView from "@/components/AuthView.vue";
export default {
  name: 'InvoiceView',
  extends: AuthView,
  components: {
  },
  props: {
    id:String||Number
  },
  data() {
    return {
      display:true,
      columns:null,
      rows:null,
      year:2022,
      store:null,
      defaultSort:{field:"id",order:-1},
      totalPaid:0,
      home: {icon: 'pi pi-home', url: '/'},
      listCrumbs: [
        {label: 'Rechnungen',url: '/invoices'},
        {label: 'Rechnung Nr.'},
      ],
      matchingColumns:null,
      matchingRows:null,
      assignedPayments:null,
      filters:null,
      displayInvoiceSettings:false,
      listWarnings:[
        {name:"Keine",level:0},
        {name:"Stufe 1",level:1},
        {name:"Stufe 2",level:2},
        {name:"Betreibung",level:3},
        {name:"Abschreibung",level:4}
      ],
      selectedWarning:null,
      hiddenColumns:["paymentId","warningLevel","invoiceId","balance","matched"],
      mapColumns:{
        id:"Nr",
        name:"Klient",
        description:"Monat",
        billingPeriod:"Rechnungsdatum",
        value:"Betrag",
        paymentId:null,
        warningLevel:null,
        balance:null,
        matched:null,
        customText:null,
        sendText:null,
        customSubject:null,
        sendSubject:null,
        sent:null,
        messageResult:null,
        email:null,
        salutation:null,
        year:"Jahr",
        clientId:null,
        templateId:null,
        givenName:"Vorname",
        cr_name:null,
        cr_givenName:null,
        cr_salutation:null,
        cr_email:null
      },
      mapAssignedColumns:{
        id:"Nr",
        date:"Datum",
        description:"Buchungstext",
        value:"Betrag",
        paymentId:null,
        warningLevel:null,
        balance:null,
        ignoreKey:null,
        matched:null,
        hasInvoices:null,
        details:"Zahlungszweck"
      }
    }
  },
  mounted() {
    document.documentElement.style.setProperty('--breadcrumbsHeight', `${this.$refs.breadcrumbs.clientHeight}px`);
  },
  async created() {
    this.initFilters();
    this.store = await new Bookeeper({onDataChange:()=>{
      appState.resetCache("/invoices");
      appState.resetCache("/payments");
    }}).init();
    await this.displayData();
    this.initCurrentWarningLevel();
    this.displayAssignedPayments();
    this.listCrumbs[this.listCrumbs.length-1].label = "Rechnung Nr. "+this.id;
  },
  methods: {
    getColumns(data,map) {
      let columns = [];
      for (let key in data[0]) {
        columns.push({field:key,header:map[key]||key,hidden:map[key]===null,sortable:true});
      }
      return columns;
    },
    async displayData() {
      let data = await this.store.getInvoices(this.id);
      if (data.length) {
        this.columns = this.getColumns(data,this.mapColumns);
        //this.columnsPayments.splice(this.columnsPayments.length-1,1);
        this.rows = data;
      }
    },
    displayAssignedPayments: async function() {
      let data = await this.store.getMatchingPayments(this.id)
      this.matchingColumns = this.getColumns(data,this.mapAssignedColumns);
      this.matchingRows = data;
      this.assignedPayments = data.length && data[0].matched ? [data[0]] : null;
    },
    rowClass(data) {
      if (data.paymentId)
        return "has-payment";
      else if (data.warningLevel===1)
        return "bg-yellow-300"
      else if (data.warningLevel===2)
        return "bg-orange-400 text-white"
      else if (data.warningLevel===3)
        return "has-problem";
      else if (data.warningLevel>3)
        return "bg-gray-200 text-gray-500"
    },
    async getTotalPaid() {
      return await this.store.getTotalIncoming();
    },
    async updateAssignedPayment() {
      await this.store.assignPaymentToInvoice(this.assignedPayments && this.assignedPayments.length ? [this.assignedPayments[this.assignedPayments.length-1].id] : [],this.id);
      this.displayAssignedPayments();
      this.displayData();
    },
    onRowSelect() {
      this.updateAssignedPayment();
    },
    onRowUnselect() {
      this.updateAssignedPayment();
    },
    initFilters() {
      this.filters = {
        'global': {value: null, matchMode: FilterMatchMode.CONTAINS},
        /*'matched': {operator: FilterOperator.OR, constraints: [{value: 0, matchMode: FilterMatchMode.EQUALS}]}*/
      };
    },
    clearFilters() {
      this.initFilters();
    },
    manageInvoiceSettings() {
      this.displayInvoiceSettings = true;
    },
    async deleteInvoice() {
      await this.store.deleteInvoice(this.rows[0].id);
      this.$router.push("/invoices");
    },
    requestDeleteInvoice(e) {
      e.stopImmediatePropagation();
      this.$confirm.require({
        acceptClass:"p-button-danger",
        rejectClass:"p-button-text p-button-plain",
        acceptLabel:"Ja",
        rejectLabel:"Nein",
        message: 'Sicher?',
        header: 'Eintrag löschen?',
        icon: 'pi pi-exclamation-triangle',
        accept: async () => {
          this.deleteInvoice();
        },
        reject: () => {
        }
      });
    },
    async initCurrentWarningLevel() {
      if (this.rows.length) {
        this.selectedWarning = this.listWarnings[this.rows[0].warningLevel];
      }
    },
    async updateWarningLevel(e) {
        await this.store.setInvoiceWarningLevel(this.id,e.value && e.value.level ? [e.value.level] : []);
        await this.displayData();
    },
    gotoPayment({id}) {
      this.$router.push("/payments/"+id);
    }
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}
td {
  vertical-align:top
}
.p-fileupload-buttonbar {
  padding: 0!important;
  background:none!important;;
  border: none!important;
}
.p-fileupload-content {
  display:none;
}
tr.has-payment {
  background-color:lightgreen!important;
}
.top-bar {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.p-breadcrumb li:not(.p-breadcrumb-chevron) {
  cursor:pointer;
}
.invoice .p-dialog-header {
  padding:0 1.5rem!important;
}
.invoice .p-dialog-content {
  padding: 0!important;
}
.invoice .p-datatable-tbody td {
  vertical-align: middle;
}
/*.p-datatable.invoice td {
  border-bottom:none!important;
}*/
</style>