<template>
  <div class="flex flex-row mt-1">
    <FileUpload name="template[]" :customUpload="true" @uploader="uploadInvoiceTemplate($event,level)" :auto="true" :showUploadButton="false" :showCancelButton="false" chooseLabel="Upload" chooseIcon="pi pi-upload" class="p-button p-button-primary">
      <template #empty></template>
    </FileUpload>
    <Button label="Download" icon="pi pi-download" :class="'ml-2 p-button-primary'+(!hasInvoiceTemplate ? ' p-disabled' : '')" @click="downloadInvoiceTemplate"></Button>
  </div>
</template>

<script>
import axios from "axios";
import {Bookeeper} from "@/bookeeper";
import {singletons} from "@/singletons";

export default {
  inject: ['dialogRef'],
  name: "InvoiceTemplateDialog",
  data() {
    return {
      templateId:0,
      level:0,
      store:null,
      mapSettings:{},
      hasInvoiceTemplate:false
    }
  },
  async created() {
    let params = this.dialogRef.data;
    if (params) {
      this.templateId = params.templateId;
      this.level = params.level;
      this.hasInvoiceTemplate = params.hasInvoiceTemplate;
    }
    this.store = await new Bookeeper().init();
    this.mapSettings = await this.store.getSettings();
  },
  methods: {
    closeDialog(ok) {
      this.dialogRef.close(ok ? {
      } : null);
    },
    getTemplateLabel() {
      return `${this.templateId ? `${this.templateId}-` : ''}invoice${this.level ? `-${this.level}` : ''}`
    },
    async uploadInvoiceTemplate(e) {
      let formData = new FormData();
      formData.append("template", e.files[0]);
      formData.append("legacyApiToken",singletons.legacyApiToken);
      try {
        await axios.post(`${this.mapSettings.apiBaseUrl}/upload/template/${this.getTemplateLabel()}`, formData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        }).then(()=>{
          this.$toast.add({severity:'success', summary: 'Erfolgreich', detail:'Vorlage hochgeladen', life: 3000})
          this.closeDialog(true);
        }).catch((err)=>{
          this.$toast.add({severity:'error', summary: 'Fehler', detail:err, life: 3000});
        })
      } catch (err) {
        this.$toast.add({severity:'error', summary: 'Fehler', detail:err, life: 3000});
      }
    },
    async downloadInvoiceTemplate() {
      let link=document.createElement('a');
      link.href = `${this.mapSettings.apiBaseUrl}/download/template/${this.getTemplateLabel()}?legacyApiToken=${singletons.legacyApiToken}`;
      link.click();
    }
  }
}
</script>

<style scoped>

</style>