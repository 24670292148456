<template>
  <div class="flex flex-row justify-content-between align-items-center breadcrumb-container" ref="breadcrumbs">
    <Breadcrumb :home="home" :model="listCrumbs">
      <template #item="{item}">
        <span @click="$router.push(item.url||'')"><i :class="item.icon"></i>{{item.label}}</span>
      </template>
    </Breadcrumb>
    <div class="mr-2 flex flex-row">
      <Button label="Match" icon="pi pi-sync" @click="testUpdate" class="ml-1"></Button>
      <Button label="Sync" icon="pi pi-sync" @click="requestSync" class="ml-1"></Button>
      <FileUpload name="payments[]" :customUpload="true" @uploader="handleUpload" :auto="true" :showUploadButton="false" :showCancelButton="false" chooseLabel="Upload" chooseIcon="pi pi-upload" class="ml-1 p-button-secondary">
        <template #empty></template>
      </FileUpload>
    </div>
  </div>
  <DataTable :value="rows" responsiveLayout="scroll" stripedRows :sortField="defaultSort.field" :sortOrder="defaultSort.order" :rowClass="rowClass" class="payments"
    v-model:filters="filters" filterDisplay="menu" v-model:selection="selectedPayments" @row-select="onSelectionChange" @row-unselect="onSelectionChange" @row-select-all="onSelectionChange" @row-unselect-all="onSelectionChange"
    stateStorage="session" stateKey="dt-state-all"
    :paginator="true" :rows="50" :rowsPerPageOptions="[50,100,(rows||[]).length-((rows||[]).length%100)+100]" :page-link-size="5">
    <template #header>
      <div class="flex flex-column md:flex-row justify-content-between align-items-center">
        <div>
          <table>
            <tr><th style="text-align:left">Gutschriften:</th><td style="font-weight: normal!important;">CHF&nbsp;{{ Math.floor(totalPaid) }}.-</td></tr>
          </table>
        </div>
        <span class="p-input-icon-left mt-2 md:mt-0">
          <i class="pi pi-search" />
          <InputText v-model="filters['global'].value" placeholder="Suche" />
          <i class="pi pi-times right-0 mr-2 cursor-pointer" v-if="filters['global'].value" @click="filters['global'].value=''"/>
        </span>
        <div class="flex flex-column md:flex-row justify-content-center align-items-center">
          <div class="flex flex-row align-items-center justify-content-end mt-2 md:mt-0">
            <div class="p-2 text-right">Ignorierte anzeigen</div><InputSwitch v-model="displayIgnored" @change="onSwitchDisplayIgnored"/>
          </div>
        </div>
      </div>
    </template>
    <Column selectionMode="multiple"></Column>
    <Column v-for="col of columns" :field="col.field" :header="col.header" :key="col.field" :sortable="col.sortable" :hidden="col.hidden"></Column>
    <Column bodyStyle="text-align: right">
      <template #body="slotProps">
        <div class="flex flex-row justify-content-end">
          <Button icon="pi pi-arrow-right" class="p-button-rounded p-button-plain p-button-text p-button-lg" @click="gotoDataset($event,slotProps.data)"/>
        </div>
      </template>
    </Column>
    <template #empty>
      Keine Einträge gefunden
    </template>
  </DataTable>
  <Sidebar v-model:visible="sidebarVisible" position="bottom" :modal="false" :showCloseIcon="false" :class="'toolbar'">
    <div class="flex flex-row justify-content-between">
      <div class="flex flex-row justify-content-start">
        <Button type="button" class="p-button-secondary ml-2 my-2 mr-2" icon="pi pi-stop" label="Abwählen" @click="unselectPayments"></Button>
      </div>
      <div class="flex flex-row justify-content-end">
        <Button type="button" class="p-button-danger mr-2 my-2" icon="pi pi-trash" label="Löschen" v-if="(selectedPayments||[]).length" @click="deleteSelectedPayments"></Button>
        <div class="flex flex-row justify-content-end"><Button type="button" class="p-button-secondary m-2" icon="pi pi-eye-slash" label="Ignorieren" v-if="(selectedPayments||[]).length" @click="ignoreSelectedPayments"></Button></div>
      </div>
    </div>
  </Sidebar>
</template>
<script>

import {Bookeeper} from "@/bookeeper";
import * as Papa from 'papaparse';
import {FilterMatchMode} from "primevue/api";
import TanDialog from "@/components/TanDialog";
import axios from "axios";
import {appState} from "@/reactive";
import AuthView from "@/components/AuthView.vue";
import {singletons} from "@/singletons";
export default {
  name: 'PaymentsView',
  extends: AuthView,
  components: {
  },
  data() {
    return {
      display:true,
      columns:null,
      rows:null,
      year:2022,
      store:null,
      defaultSort:{field:"id",order:-1},
      totalPaid:0,
      tabs: [
        {label: 'Rechnungen', to:"/invoices"},
        {label: 'Kontoauszug', to: '/payments'},
        {label: 'Abschreibungen', to: '/writeoffs'},
        {label: 'Einstellungen', to: '/settings'}
      ],
      filters:null,
      mapColumns:{
        id:"Nr.",
        uid:"Referenz",
        date:"Datum",
        description:"Buchungstext",
        billingPeriod:"Rechnungsdatum",
        value:"Betrag",
        invoiceId:null,
        ignoreKey:null,
        details:"Zweck"
      },
      displayIgnored:false,
      selectedPayments:null,
      home: {icon: 'pi pi-home', url: '/'},
      listCrumbs: [
        {label: 'Kontoauszug',url: '/payments'}
      ],
      mapSettings:{},
      sidebarVisible:false
    }
  },
  mounted() {
    document.documentElement.style.setProperty('--breadcrumbsHeight', `${this.$refs.breadcrumbs.clientHeight}px`);
  },
  async created() {
    this.displayIgnored = this.$storage.getStorageSync("displayIgnored");
    this.initFilters();
    this.store = await new Bookeeper().init();
    window.store = this.store;
    this.mapSettings = await this.store.getSettings();
    this.displayData();
    this.totalPaid = await this.getTotalPaid();
  },
  methods: {
    async handleUpload(e) {
      await this.importCSV(e.files[0]);
    },
    async updateAssignments() {
      let assignedInvoices = await this.store.updateAssignments();
      console.log(assignedInvoices);
      this.$toast.add({severity:assignedInvoices.length ? "success" : "warn", summary: assignedInvoices.length ? "Neue Zahlungen" : "Kein Eingang", detail:(assignedInvoices.length ? `${assignedInvoices.length} neue` : "Keine neuen")+" Zahlungen zugeordnet", life: 3000})
    },
    async testUpdate() {
      await this.updateAssignments();
      this.displayData();
    },
    async importCSV(blob) {
      let data = await new Promise((resolve)=>{
        const reader = new FileReader();
        reader.onload = (e)=>{
          resolve(Papa.parse(e.target.result,{
            delimiter:";",
            header:true
          }).data);
        };
        reader.readAsText(blob);
      });
      await this.store.importPayments(data);
      await this.updateAssignments();
      await this.displayData();
    },
    getColumns(data) {
      let columns = [];
      for (let key in data[0]) {
        columns.push({field:key,header:this.mapColumns[key]||key,hidden:this.mapColumns[key]===null,sortable:true});
      }
      return columns;
    },
    onSwitchDisplayIgnored() {
      this.$storage.setStorageSync("displayIgnored",this.displayIgnored);
      this.displayData();
    },
    displayData: async function() {
      let data = await this.store.getPayments(false,this.displayIgnored);
      this.columns = data && data.length ?  this.getColumns(data) : [];
      this.rows = data || [];
    },
    rowClass(data) {
      if (data.ignoreKey)
        return "bg-gray-50 text-gray-400"
      return data.invoiceId ? "has-invoice" : ""
    },
    async getTotalPaid() {
      return await this.store.getTotalIncoming();
    },
    gotoDataset(event,data) {
      event.stopImmediatePropagation();
      this.$router.push("/payments/"+data.id);
    },
    initFilters() {
      this.filters = {
        'global': {value: null, matchMode: FilterMatchMode.CONTAINS},
        /*'matched': {operator: FilterOperator.OR, constraints: [{value: 0, matchMode: FilterMatchMode.EQUALS}]}*/
      };
    },
    clearFilters() {
      this.initFilters();
    },
    async ignoreSelectedPayments() {
      await this.store.setIgnorePaymentsByUid(this.selectedPayments.filter(item=>!item.invoiceId).map(item=>item.uid));
      this.displayData();
    },
    async requestSync() {
      appState.resetCache(`/payments`);
      this.$dialog.open(TanDialog, {
        props: {
          modal: true,
          showHeader:false,
          contentClass:"tan-dialog-content"
        },
        data:{
          bank:"zkb",
          action:"download",
          baseUrl:`${this.mapSettings.apiBaseUrl}`
        },
        onClose: async (options) => {
          const {path} = options.data;
          if (path) {
            let blob = (await axios.post(`${this.mapSettings.apiBaseUrl}/download/statement`,{path,legacyApiToken:singletons.legacyApiToken},{responseType: "blob"})).data||null;
            if (blob && blob.size)
              this.importCSV(blob);
          }
        }
      })
    },
    onSelectionChange() {
      setTimeout(()=>{this.sidebarVisible = !!(this.selectedPayments && this.selectedPayments.length)});
    },
    unselectPayments() {
      this.selectedPayments = null;
      this.onSelectionChange();
    },
    async deleteSelectedPayments() {
      this.$confirm.require({
        acceptClass:"p-button-danger",
        rejectClass:"p-button-text p-button-plain",
        acceptLabel:"Ja",
        rejectLabel:"Nein",
        message: 'Sicher?',
        header: (this.selectedPayments.length>1 ? 'Einträge' : 'Eintrag')+' löschen',
        icon: 'pi pi-exclamation-triangle',
        accept: async () => {
          let deleteCount = this.selectedPayments.length;
          try {
            const listResultSets = await this.store.deletePayments(this.selectedPayments.map(item=>item.id));
            const affectedRows = this.store.db.getAffectedRows(listResultSets);
            this.$toast.add({severity:affectedRows>0 && affectedRows===deleteCount ? "success" : "warn", summary: affectedRows>0 && affectedRows===deleteCount ? "Erfolgreich" : "Warnung", detail:(affectedRows>0 ? affectedRows : "Keine")+" "+(affectedRows===1 ? "Eintrag" : "Einträge")+" gelöscht", life: 3000})
            this.displayData();
            this.selectedPayments = null;
          } catch({code,message}) {
            this.$toast.add({severity:'error', summary: 'Error Code '+code, detail:message, life: 3000});
          }
        },
        reject: () => {
        }
      });
    },
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}
td {
  vertical-align:top
}
.p-fileupload-buttonbar {
  padding: 0!important;
  background:none!important;;
  border: none!important;
}
.p-fileupload-content {
  display:none;
}
tr.has-invoice {
  background-color:lightgreen!important;
}
.p-fileupload-buttonbar {
  margin:0!important;
}
.p-datatable.payments tr>td {
  cursor:pointer;
}
.payments .p-datatable-header {
  border-top: none!important;
}
.p-fileupload .p-fileupload-buttonbar .p-button {
  margin-right: 0!important;
}
</style>