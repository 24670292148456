import {reactive} from "vue";
export const appState = reactive({
    mapCache:{},
    getCacheId(route) {
        return this.mapCache[route]||0;
    },
    resetCache(route=null) {
        const keys = route ? [route] : Object.keys(this.mapCache);
        for (let key of keys) {
            this.mapCache[key]=this.mapCache[key]||0;
            this.mapCache[key]++;
        }
    },
})