<template>
  <div class="flex flex-row justify-content-between align-items-center breadcrumb-container" ref="breadcrumbs">
    <Breadcrumb :home="home" :model="listCrumbs">
      <template #item="{item}">
        <span @click="$router.push(item.url||'')"><i :class="item.icon"></i>{{item.label}}</span>
      </template>
    </Breadcrumb>
  </div>
  <DataTable :value="rows" responsiveLayout="scroll" stripedRows :sortField="defaultSort.field" :sortOrder="defaultSort.order" :rowClass="rowClass" class="payment">
    <template #header>
      <div class="flex justify-content-between align-items-center">
        <span class="font-weight-600">Gutschrift</span>
      </div>
    </template>
    <Column v-for="col of columns" :field="col.field" :header="col.header" :key="col.field" :hidden="col.hidden"></Column>
    <Column bodyStyle="text-align: right">
      <template #body >
        <Button type="button" icon="pi pi-cog" @click="managePaymentSettings"></Button>
      </template>
    </Column>
  </DataTable>
  <DataTable :value="matchingRows" responsiveLayout="scroll" stripedRows :rowClass="rowClass" class="payment" v-model:selection="assignedInvoices" dataKey="id" @rowSelect="onRowSelect" @rowUnselect="onRowUnselect"
    v-model:filters="filters" filterDisplay="menu" style="margin-top: 2rem"
    stateStorage="session" stateKey="dt-state-payment">
    <template #header>
      <div class="flex justify-content-between align-items-center">
        <span class="font-weight-600">Rechnungen zuordnen</span>
        <span class="p-input-icon-left">
            <i class="pi pi-search" />
            <InputText v-model="filters['global'].value" placeholder="Suche" />
            <i class="pi pi-times right-0 mr-2 cursor-pointer" v-if="filters['global'].value" @click="filters['global'].value=''"/>
        </span>
      </div>
    </template>
    <Column selectionMode="multiple"></Column>
    <Column v-for="col of matchingColumns" :field="col.field" :header="col.header" :key="col.field" :hidden="col.hidden"></Column>
    <Column bodyStyle="text-align: right">
      <template #body="slotProps">
        <div class="flex flex-row justify-content-end">
          <Button icon="pi pi-arrow-right" class="p-button-rounded p-button-plain p-button-text p-button-lg" @click="gotoInvoice(slotProps.data)"/>
        </div>
      </template>
    </Column>
  </DataTable>
  <Dialog v-model:visible="displayPaymentSettings" :modal="true" :dismissable-mask="true" :close-on-escape="true" position="top" :draggable="false">
    <template #header>
      <h3>{{rows[0].description}}, CHF {{rows[0].value}}</h3>
    </template>
    <Panel header="Ignorieren">
      <SelectButton v-model="selectedIgnoreOption" :options="listIgnoreOptions" optionLabel="name" @change="updateIgnore" :multiple="true"/>
    </Panel>
  </Dialog>
</template>
<script>

import {Bookeeper} from "@/bookeeper";
import {FilterMatchMode} from "primevue/api";
import {appState} from "@/reactive";
import AuthView from "@/components/AuthView.vue";
export default {
  name: 'PaymentView',
  extends: AuthView,
  components: {
  },
  props: {
    id:String||Number
  },
  data() {
    return {
      display:true,
      columns:null,
      rows:null,
      year:2022,
      store:null,
      defaultSort:{field:"id",order:-1},
      totalPaid:0,
      home: {icon: 'pi pi-home', url: '/'},
      listCrumbs: [
        {label: 'Kontoauszug',url: '/payments'},
        {label: 'Gutschrift Nr.'},
      ],
      matchingColumns:null,
      matchingRows:null,
      assignedInvoices:null,
      filters:null,
      hiddenColumns:["paymentId","warningLevel","invoiceId","balance","matched"],
      mapAssignedColumns:{
        id:"Nr",
        name:"Klient",
        description:"Monat",
        billingPeriod:"Rechnungsdatum",
        value:"Betrag",
        warningLevel:null,
        balance:null,
        matched:null,
        clientId:null
      },
      mapColumns:{
        id:"Nr",
        uid:"Referenz",
        date:"Datum",
        description:"Buchungstext",
        value:"Betrag",
        invoiceId:null,
        warningLevel:null,
        balance:null,
        matched:null,
        ignoreKey:null,
      },
      displayPaymentSettings:false,
      listIgnoreOptions:[
        {name:"Einmalig",key:"uid"},
        {name:"Immer",key:"description"},
      ],
      selectedIgnoreOption:null,
    }
  },
  mounted() {
    document.documentElement.style.setProperty('--breadcrumbsHeight', `${this.$refs.breadcrumbs.clientHeight}px`);
  },
  async created() {
    this.initFilters();
    this.store = await new Bookeeper({onDataChange:()=>{
      appState.resetCache("/invoices");
      appState.resetCache("/payments");
    }}).init();
    this.displayData(await this.store.getPayments(this.id));
    this.displayAssignedInvoices();
    this.listCrumbs[this.listCrumbs.length-1].label = "Gutschrift Nr. "+this.id;
  },
  methods: {
    getColumns(data,map) {
      let columns = [];
      for (let key in data[0]) {
        columns.push({field:key,header:map[key]||key,hidden:map[key]===null,sortable:true});
      }
      return columns;
    },
    displayData: function(data) {
      if (data.length) {
        this.columns = this.getColumns(data,this.mapColumns);
        //this.columnsPayments.splice(this.columnsPayments.length-1,1);
        this.rows = data;
        this.listIgnoreOptions.forEach(opt=>{
          if (data[0].ignoreKey===opt.key)
            this.selectedIgnoreOption = [opt];
        })
      }
    },
    displayAssignedInvoices: async function() {
      let data = await this.store.getMatchingInvoices(this.id)
      this.matchingColumns = this.getColumns(data,this.mapAssignedColumns);
      this.matchingRows = data;
      let i=0;
      this.assignedInvoices = [];
      while (i<data.length && data[i].matched) {
        this.assignedInvoices.push(data[i]);
        i++;
      }
    },
    rowClass(data) {
      if (data.ignoreKey)
        return "bg-gray-50 text-gray-400"
      return data.invoiceId ? "has-invoice" : ""
    },
    async getTotalPaid() {
      return await this.store.getTotalIncoming();
    },
    async updateAssignedInvoices() {
      let listInvoiceIds = this.assignedInvoices.map(item=>item.id);
      await this.store.assignInvoicesToPayment(listInvoiceIds,this.id);
      this.displayAssignedInvoices();
      this.displayData(await this.store.getPayments(this.id));
    },
    onRowSelect() {
      this.updateAssignedInvoices();
    },
    onRowUnselect() {
      this.updateAssignedInvoices();
    },
    initFilters() {
      this.filters = {
        'global': {value: null, matchMode: FilterMatchMode.CONTAINS},
        /*'matched': {operator: FilterOperator.OR, constraints: [{value: 0, matchMode: FilterMatchMode.EQUALS}]}*/
      };
    },
    clearFilters() {
      this.initFilters();
    },
    managePaymentSettings() {
      this.displayPaymentSettings = true;
    },
    async updateIgnore(e) {
      let row = this.rows[0];
      let map = {description: {val:row.description,selected:false},uid: {val:row.uid,selected:false}};
      let items = e.value;
      if (items.length)
        map[items[items.length-1].key].selected = true;
      await this.store.setIgnorePayment(map);
      await this.displayData(await this.store.getPayments(this.id));
    },
    gotoInvoice({id}) {
      this.$router.push("/invoices/"+id);
    }
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}
td {
  vertical-align:top
}
.p-fileupload-buttonbar {
  padding: 0!important;
  background:none!important;;
  border: none!important;
}
.p-fileupload-content {
  display:none;
}
tr.has-invoice {
  background-color:lightgreen!important;
}
.top-bar {
  display: flex;
  flex-direction: row;
  align-items: center;
}
/*.p-datatable.payment td {
  border-bottom:none!important;
}*/
.p-breadcrumb li:not(.p-breadcrumb-chevron) {
  cursor:pointer;
}
.payment .p-datatable-tbody td {
  vertical-align: middle;
}
</style>