<template>
  <ItemsEditor id="items" v-model:list="items" placeholder="Wählen" class="mt-1" :key="items.length" :edit-rate="false"></ItemsEditor>
  <div class="flex flex-row mt-4 justify-content-end w-full">
    <Button label="Abbrechen" icon="pi pi-times" @click="closeDialog(false)" class="p-button-text"/>
    <Button label="Speichern" icon="pi pi-save" @click="closeDialog(true)" autofocus class="ml-2"/>
  </div>
</template>

<script>
import {Bookeeper} from "@/bookeeper";
import ItemsEditor from "@/components/ItemsEditor";

export default {
  inject: ['dialogRef'],
  name: "DefaultItemsDialog",
  components: {
    ItemsEditor,
  },
  data() {
    return {
      typeId:0,
      items:[],
    }
  },
  async created() {
    let params = this.dialogRef.data;
    if (params)
      this.typeId = params.typeId;
    this.store = await new Bookeeper().init();
    this.items = await this.store.getServiceTypeDefaultItems(this.typeId);
    console.log(this.typeId,this.items);
  },
  methods: {
    closeDialog(ok) {
      this.dialogRef.close(ok ? {
        items:this.items
      } : null);
    },
  }
}
</script>

<style scoped>

</style>