import { createApp } from 'vue'
import App from './App.vue'
import PrimeVue from "primevue/config";
import Dialog from "primevue/dialog";
import "./assets/_theme.scss"; //import "primevue/resources/themes/saga-blue/theme.css"
import "primevue/resources/primevue.min.css"
import "primeicons/primeicons.css"
import "primeflex/primeflex.css"
import "./override.css"
import "./main.css"
import DataTable from "primevue/datatable";
import Column from "primevue/column";
import router from './router';
import FileUpload from "primevue/fileupload";
import TabPanel from "primevue/tabpanel";
import TabView from "primevue/tabview";
import TabMenu from "primevue/tabmenu";
import Breadcrumb from "primevue/breadcrumb";
import Panel from "primevue/panel";
import Button from "primevue/button";
import InputText from "primevue/inputtext";
import SelectButton from "primevue/selectbutton";
import InputSwitch from "primevue/inputswitch";
import Listbox from "primevue/listbox";
import Vue3Storage from "vue3-storage";
import ConfirmationService from "primevue/confirmationservice";
import ConfirmDialog from "primevue/confirmdialog";
import ToastService from "primevue/toastservice";
import Toast from "primevue/toast";
import Sidebar from "primevue/sidebar";
import Accordion from "primevue/accordion";
import AccordionTab from "primevue/accordiontab";
import Inplace from "primevue/inplace";
import Editor from "primevue/editor";
import Dropdown from "primevue/dropdown";
import Calendar from "primevue/calendar";
import AutoComplete from "primevue/autocomplete";
import OverlayPanel from "primevue/overlaypanel";
import Menu from "primevue/menu";
import ProgressBar from "primevue/progressbar";
import BadgeDirective from "primevue/badgedirective";
import Badge from "primevue/badge";
import DialogService from "primevue/dialogservice";
import DynamicDialog from "primevue/dynamicdialog";
import InputNumber from "primevue/inputnumber";
import Password from "primevue/password";
import AuthService from "@/auth-service";

const app = createApp(App);
app
.use(router).use(PrimeVue).use(Vue3Storage).use(ConfirmationService).use(ToastService).use(DialogService)
.component("Dialog",Dialog).component("DataTable",DataTable).component("Column",Column)
.component("FileUpload",FileUpload).component("TabView",TabView).component("TabPanel",TabPanel)
.component("TabMenu",TabMenu).component("Breadcrumb",Breadcrumb).component("Panel",Panel)
.component("InputText",InputText).component("InputNumber",InputNumber).component("Password",Password).component("Button",Button).component("SelectButton",SelectButton)
.component("InputSwitch",InputSwitch).component("Listbox",Listbox).component("ConfirmDialog",ConfirmDialog)
.component("Toast",Toast).component("Sidebar",Sidebar)
.component("Accordion",Accordion).component("AccordionTab",AccordionTab).component("Inplace",Inplace).component("Editor",Editor)
.component("Dropdown",Dropdown).component("Calendar",Calendar)
.component("AutoComplete",AutoComplete).component("OverlayPanel",OverlayPanel)
.component("Menu",Menu).component("ProgressBar",ProgressBar)
.component("Badge",Badge).component("DynamicDialog",DynamicDialog)
.directive('badge', BadgeDirective)
app.config.globalProperties.authService = new AuthService();
router.isReady().then(()=>{
    app.mount('#app')
});
