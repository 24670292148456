<template>
  <Breadcrumb :home="home" :model="listCrumbs">
    <template #item="{item}">
      <span @click="$router.push(item.url||'')"><i :class="item.icon"></i>{{item.label}}</span>
    </template>
  </Breadcrumb>
  <TabView v-model:activeIndex="activePanelIndex" @tab-click="onTabChange" class="client-tabview">
    <TabPanel>
      <template #header>
        <span class="p-2 p-tabview-title tabview-extended relative overflow-visible">Personalien</span>
      </template>
      <h4 class="m-2">Leistungsempfänger</h4>
      <div class="flex flex-column mt-2">
        <div v-for="(value,name) in (rows ? rows[0] : {})" v-bind:key="name" >
          <div class="flex flex-row align-items-center p-2" v-if="mapColumns[name]">
            <label :for="name" class="w-2">{{mapColumns[name]||name}}</label>
            <InputText :id="name" type="text" v-model="rows[0][name]" class="flex-grow-1" @input="onChange" />
          </div>
        </div>
      </div>
      <h4 class="m-2 mt-4">Rechnungsempfänger</h4>
      <div class="flex flex-column mt-2">
        <div v-for="(value,name) in (rows ? rows[0] : {})" v-bind:key="name" >
          <div class="flex flex-row align-items-center p-2" v-if="mapColumnsCreditor[name]">
            <label :for="name" class="w-2">{{mapColumnsCreditor[name]||name}}</label>
            <InputText :id="name" type="text" v-model="rows[0][name]" class="flex-grow-1" @input="onChange" />
          </div>
        </div>
      </div>
    </TabPanel>
    <TabPanel>
      <template #header>
        <span class="p-2 p-tabview-title tabview-extended relative overflow-visible">Leistungen <i class="pi pi-plus absolute p-0 left-100 pl-2" @click="plusPanelClick"></i></span>
      </template>
      <DataTable :value="serviceRows" responsiveLayout="scroll" stripedRows :rowClass="rowClass" class="payment" v-model:selection="selectedServices" dataKey="id"
                 v-model:filters="filters" filterDisplay="menu"
                 stateStorage="session" stateKey="dt-state-serivces"
                 @row-select="onSelectionChange" @row-unselect="onSelectionChange" @row-select-all="onSelectionChange" @row-unselect-all="onSelectionChange">
        <template #header>
          <div class="flex flex-column md:flex-row justify-content-between align-items-center">
            <span class="p-input-icon-left mt-2 md:mt-0">
              <i class="pi pi-search" />
              <InputText v-model="filters['global'].value" placeholder="Suche" />
              <i class="pi pi-times right-0 mr-2 cursor-pointer" v-if="filters['global'].value" @click="filters['global'].value=''"/>
            </span>
            <Button label="Leistung" icon="pi pi-plus" @click="requestAddService()"></Button>
          </div>
        </template>
        <Column v-if="serviceRows && serviceRows.length" selectionMode="multiple"></Column>
        <Column v-for="col of serviceColumns" :field="col.field" :header="col.header" :key="col.field" :sortable="col.sortable" :hidden="col.hidden">
          <template #editor="{ data, field }" v-if="col.editable">
            <Dropdown v-if="mapServiceColumnEnum[field]" v-model="data[field]" :options="mapServiceColumnEnum[field].options" optionLabel="name" placeholder="Wählen" />
            <Calendar v-else-if="field.match(/^date/)" v-model="data[field]" dateFormat="yy-mm-dd" />
            <InputText v-else v-model="data[field]" autofocus />
          </template>
        </Column>
        <Column bodyStyle="text-align: right">
          <template #body="slotProps">
            <div class="flex flex-row justify-content-end">
              <Button icon="pi pi-pencil" :class="'p-button-rounded p-button-plain p-button-text p-button-lg'" @click="requestEditService(true,slotProps.data)"/>
            </div>
          </template>
        </Column>
        <template #empty>
          Keine Leistungen gefunden
        </template>
      </DataTable>
      <Dialog v-model:visible="displayEditService" :modal="true" :close-on-escape="true" :draggable="false" :breakpoints="{'960px': '75vw', '640px': '95vw'}" :style="{width: '50vw', 'max-width':'1000px'}">
        <template #header>
          <h3 v-if="editServiceId!==false" class="m-0">Leistung bearbeiten</h3>
          <h3 v-else class="m-0">Neue Leistung</h3>
        </template>
        <div class="flex flex-column mt-2">
          <div class="flex flex-row align-items-center p-2">
            <label for="items" class="w-3">{{mapNewServiceColumns['typeId']}}</label>
            <Dropdown v-model="newService['typeId']" :options="mapServiceColumnEnum['typeId'].options" optionLabel="name" placeholder="Wählen" />
          </div>
          <div class="flex flex-row align-items-start p-2" v-if="mapServiceColumnEnum['items'].defaults">
            <label for="items" class="w-3 mt-2 flex-shrink-0">{{mapNewServiceColumns['items']}}</label>
            <ItemsEditor id="items" v-model:list="newService['items']" :serviceType="newService['typeId']" placeholder="Wählen" :edit-rate="true"></ItemsEditor>
          </div>
          <div class="flex flex-row align-items-center p-2">
            <label for="items" class="w-3">{{mapNewServiceColumns['billingPeriod']}}</label>
            <BillingPeriods id="billingPeriod" v-model:selected="newService['billingPeriod']" v-model:list="mapServiceColumnEnum['billingPeriod'].options" placeholder="Wählen"></BillingPeriods>
          </div>
          <div class="flex flex-row align-items-center p-2">
            <label for="dateformat" class="w-3">{{mapNewServiceColumns['date']}}</label>
            <Calendar id="dateformat" v-model="newService['date']" dateFormat="yy-mm-dd" />
          </div>
          <div class="flex flex-row align-items-center p-2" v-if="!newService['items'] || !newService['items'].length">
            <label class="w-3">{{mapNewServiceColumns['description']}}</label>
            <InputText v-model="newService['description']"  class="flex-grow-1"/>
          </div>
          <div class="flex flex-row align-items-center p-2" v-if="!newService['items'] || !newService['items'].length">
            <label class="w-3">{{mapNewServiceColumns['rate']}}</label>
            <InputText v-model="newService['rate']" class="flex-grow-1"/>
          </div>
          <div class="border-top-1 border-black-alpha-30 mx-2 my-2"></div>
          <div class="flex flex-row align-items-center p-2">
            <label class="w-3">{{mapNewServiceColumns['case_nr']}}</label>
            <InputText v-model="newService['case_nr']"  class="flex-grow-1"/>
          </div>
          <div class="flex flex-row align-items-center p-2">
            <label for="dateformat" class="w-3">{{mapNewServiceColumns['case_date']}}</label>
            <Calendar id="dateformat" v-model="newService['case_date']" dateFormat="dd.mm.yy" />
          </div>
          <div class="flex flex-row align-items-center p-2">
            <label class="w-3">{{mapNewServiceColumns['law']}}</label>
            <InputText v-model="newService['law']"  class="flex-grow-1"/>
          </div>
          <div class="flex flex-row align-items-center p-2">
            <label class="w-3">{{mapNewServiceColumns['treatment_type']}}</label>
            <InputText v-model="newService['treatment_type']"  class="flex-grow-1"/>
          </div>
          <div class="flex flex-row align-items-center p-2">
            <label class="w-3">{{mapNewServiceColumns['treatment_cause']}}</label>
            <InputText v-model="newService['treatment_cause']"  class="flex-grow-1"/>
          </div>
          <div class="flex flex-row align-items-center p-2">
            <label class="w-3">{{mapNewServiceColumns['referrer']}}</label>
            <InputText v-model="newService['referrer']"  class="flex-grow-1"/>
          </div>
          <div class="flex flex-row align-items-center p-2">
            <label class="w-3">{{mapNewServiceColumns['diagnose']}}</label>
            <InputText v-model="newService['diagnose']"  class="flex-grow-1"/>
          </div>
          <div class="flex flex-row align-items-center p-2">
            <label class="w-3">{{mapNewServiceColumns['remarks']}}</label>
            <InputText v-model="newService['remarks']"  class="flex-grow-1"/>
          </div>
        </div>
        <template #footer>
          <div class="flex flex-row mt-4 justify-content-end">
            <Button label="Abbrechen" icon="pi pi-times" @click="requestEditService(false)" class="p-button-text"/>
            <Button label="Ok" icon="pi pi-check" @click="saveService()" autofocus :disabled="!(newService['typeId'] && newService['billingPeriod'] && newService['date'])" />
          </div>
        </template>
      </Dialog>
    </TabPanel>
    <TabPanel v-for="(group,index) of listRecords" :key="group.entries[0].id">
      <template #header>
        <span class="p-2 p-tabview-title tabview-extended relative overflow-visible"><span :contenteditable="index+2===activePanelIndex" @input="onPanelHeaderEdit($event,group)" class="p-2">{{group.entries[0].category}}</span> <i class="pi pi-plus absolute p-0 left-100 pl-2" @click="plusPanelClick" style="z-index:1"></i></span>
      </template>
      <Accordion :active-index="group.activeIndex" class="accordion-records">
        <AccordionTab v-for="record of group.entries" :key="record.id">
          <template #header>
            <div class="flex-grow-1 flex flex-row justify-content-between align-items-center font-normal">
              <div>{{record.date}}</div>
              <Button :icon="'pi pi-trash'" :class="'p-button-text text-danger'" @click="requestDeleteRecords([record.id],$event,group)"/>
            </div>
          </template>
          <Inplace class="inplace-html relative" :active="record.inplaceActive" :key="record.renderKey">
            <template #display>
              <div v-html="record.content||'BEARBEITEN'"></div>
            </template>
            <template #content>
              <Editor ref="editor" v-model="record.content" editorStyle="height: 320px" @text-change="function(e){onEditRecord(record.id,{content:e.htmlValue})}" />
              <Button icon="pi pi-check" class="absolute right-0 bottom-0 m-2 text-success p-button-text" @click="onInplaceRecordCommit(record)"></Button>
            </template>
            {{record.inplaceActive ? 'offen' : 'geschlossen'}}
          </Inplace>
        </AccordionTab>
      </Accordion>
      <div class="flex flex-row-reverse pt-2">
        <Button label="Eintrag" icon="pi pi-plus" @click="function(){addRecord({group})}"></Button>
      </div>
    </TabPanel>
  </TabView>
  <Dialog v-model:visible="displayAddCategory" :modal="true" :close-on-escape="true" :draggable="false" :breakpoints="{'960px': '75vw', '640px': '95vw'}" :style="{width: '50vw', 'max-width':'500px'}">
    <template #header>
      <h3>Neue Kategorie</h3>
    </template>
    <div class="flex flex-column mt-2">
      <AutoComplete v-model="newCategory" :suggestions="filteredCategories" @complete="searchCategory($event)" field="name" class="flex-grow-1" autofocus :complete-on-focus="true" />
    </div>
    <template #footer>
      <div class="flex flex-row mt-4 justify-content-end">
        <Button label="Abbrechen" icon="pi pi-times" @click="requestAddCategory(false)" class="p-button-text"/>
        <Button label="Ok" icon="pi pi-check" @click="addCategory()" autofocus />
      </div>
    </template>
  </Dialog>
  <Dialog v-model:visible="displayInvoiceSettings" :modal="true" :dismissable-mask="true" :close-on-escape="true" position="top" :draggable="false" class="invoice">
    <template #header>
      <h3>{{rows[0].name}} - {{rows[0].description}}</h3>
    </template>
    <Panel header="Mahnstufe">
      <SelectButton v-model="selectedWarning" :options="listWarnings.slice(0,3)" optionLabel="name" @change="updateWarningLevel"/>
    </Panel>
    <Panel header="Eskalation">
      <SelectButton v-model="selectedWarning" :options="listWarnings.slice(3,5)" optionLabel="name" @change="updateWarningLevel"/>
    </Panel>
  </Dialog>
  <Sidebar v-model:visible="sidebarVisible" position="bottom" :modal="false" :showCloseIcon="false" :class="'toolbar'">
    <div class="flex flex-row justify-content-end"><Button type="button" class="p-button-danger m-2" icon="pi pi-trash" label="Löschen" v-if="(selectedServices||[]).length" @click="deleteSelectedServices"></Button></div>
  </Sidebar>
  <Menu ref="op" :model="remainingCategories" :popup="true">
    <template #item="{item}">
      <li v-if="item.name" class="p-menuitem" role="none">
        <div class="p-menuitem-link" role="menuitem" tabindex="0" @click="addCategory(item.name)">
          <span class="p-menuitem-icon"></span><span class="p-menuitem-text">{{item.name}}</span>
        </div>
      </li>
      <li v-else class="p-menuitem" role="none">
        <div class="p-menuitem-link" role="menuitem" tabindex="0" @click="requestAddCategory()">
          <span class="p-menuitem-icon"></span><span class="p-menuitem-text">Neu..</span>
        </div>
      </li>
    </template>
  </Menu>
</template>
<script>

import {Bookeeper} from "@/bookeeper";
import {FilterMatchMode} from "primevue/api";
import BillingPeriods from "@/components/BillingPeriods";
import ItemsEditor from "@/components/ItemsEditor";
import {appState} from "@/reactive";
import AuthView from "@/components/AuthView.vue";
export default {
  name: 'ClientView',
  extends: AuthView,
  components: {
    ItemsEditor,
    BillingPeriods
  },
  props: {
    id:String||Number
  },
  data() {
    return {
      display:true,
      columns:null,
      rows:null,
      year:2022,
      store:null,
      defaultSort:{field:"id",order:-1},
      totalPaid:0,
      home: {icon: 'pi pi-home', url: '/'},
      listCrumbs: [
        {label: 'Klienten',url: '/clients'},
        {label: ''},
      ],
      serviceColumns:null,
      serviceRows:null,
      selectedServices:null,
      filters:null,
      displayInvoiceSettings:false,
      listWarnings:[
        {name:"Keine",level:0},
        {name:"Stufe 1",level:1},
        {name:"Stufe 2",level:2},
        {name:"Betreibung",level:3},
        {name:"Abschreibung",level:4}
      ],
      selectedWarning:null,
      hiddenColumns:["paymentId","warningLevel","invoiceId","balance","matched"],
      mapColumns:{
        id:null,
        name:"Name",
        givenName:"Vorname",
        email:"E-Mail",
        gender:"Geschlecht",
        alias:"Alias",
        address:"Adresszeile 1",
        address1:"Adresszeile 2",
        salutation:"Anrede",
        invoiceTotal:null,
        maxRate:null,
        minRate:null,
        rate:null, /* removed as of 2022/11 */
        birthdate:"Geburtsdatum",
        ahv:"AHV-Nr.",
        veka_nr:"VEKA-Nr.",
        versi_nr:"Versichterten-Nr.",
        kanton:"Kanton",
        comp_mode:"Vergütungsart",
      },
      mapColumnsCreditor:{
        cr_name:"Name",
        cr_givenName:"Vorname",
        cr_email:"E-Mail",
        cr_address:"Adresszeile 1",
        cr_address1:"Adresszeile 2",
        cr_salutation:"Anrede",
      },
      mapServicesColumns:{
        id:"Nr",
        clientId:null,
        typeId:null,
        date:"Datum",
        billingPeriod:"Rechnungsperiode",
        rate:"Rate",
        quantity:"Anzahl",
        type:"Leistungsart",
        value:"Betrag",
        description:null,
        case_nr:null,
        case_date:null,
        law:null,
        treatment_date:null,
        treatment_type:null,
        treatment_cause:null,
        referrer:null,
        diagnose:null,
        remarks:null
      },
      mapNewServiceColumns:{
        id:"Nr",
        clientId:null,
        typeId:"Leistungsart",
        date:"Datum",
        billingPeriod:"Rechnungsperiode",
        rate:"Rate",
        quantity:"Anzahl",
        items:"Bestandteile",
        description:"Beschreibung",
        case_nr:"Fall-Nr.",
        case_date:"Falldatum",
        law:"Gesetz",
        treatment_date:"Behandlungsdatum",
        treatment_type:"Behandlungsart",
        treatment_cause:"Behandlungsgrund",
        referrer:"Zuweiser GLN/ZSR",
        diagnose:"Diagnose",
        remarks:"Bemerkung"
      },
      mapServiceColumnEnum:{
        type:{
          target:"typeId",
          options:[{
            code:1,name:"Psychotherapie"
          },{
            code:2,name:"Bericht"
          }]
        },
        billingPeriod:{
          options:[]
        },
        typeId: {
          options:[]
        },
        items: {
          defaults:[]
        }
      },
      handleDebounce:null,
      activePanelIndex:0,
      listRecords:[],
      sidebarVisible:false,
      displayEditService:false,
      newService:{},
      newServiceFields:["typeId","items","billingPeriod","date","rate"],
      displayAddCategory:false,
      newCategory:"",
      listCategories:[],
      filteredCategories:[],
      remainingCategories:[],
      editServiceId:false
    }
  },
  async created() {
    this.initFilters();
    this.store = await new Bookeeper({onDataChange:()=>{
      appState.resetCache("/clients");
      appState.resetCache("/invoices");
    }}).init();
    await this.displayData();
    this.initCurrentWarningLevel();
    await this.displayServices();
    await this.displayRecords();
    this.listCrumbs[this.listCrumbs.length-1].label = this.rows.length && this.rows[0].name || "";
    this.loadEnums();
    this.listCategories = await this.store.getRecordCategories();
    this.updateRemainingCategories();
    this.onSelectionChange();
    this.activePanelIndex = this.$storage.getStorageSync("activeClientPanel"+this.id);
  },
  methods: {
    onCategoryClick(e) {
      e.stopImmediatePropagation();
    },
    onCategoryTabOpen(e) {
      this.listRecords[e.index].inplaceActive = false;
    },
    onTabChange(e) {
      this.$storage.setStorageSync("activeClientPanel"+this.id,e.index);
    },
    plusPanelClick(e) {
      this.$refs.op.toggle(e);
      e.stopImmediatePropagation();
    },
    async addRecord(args) {
      let group = args.group;
      let category = args.category;
      let inserted = await this.store.addRecord(this.id,group ? group.entries[0].category : category);
      if (inserted && inserted.length) {
        let {insertId} = inserted[0];
        let records = await this.store.getRecord(insertId);
        if (group && group.entries && records.length) {
          records[0].inplaceActive = true;
          records[0].renderKey = 1;
          group.entries.unshift(records[0]);
          group.activeIndex = 0;
        }
        else {
          await this.displayRecords();
          let i=0;
          while (i<this.listRecords.length && this.listRecords[i].entries[0].category!==category) {
            i++;
          }
          if (i<this.listRecords.length) {
            let index = 2+i;
            this.onTabChange({index})
            this.activePanelIndex = index;
            this.listRecords[i].activeIndex = 0;
            this.listRecords[i].entries[0].inplaceActive = true;
            //this.$refs.editor[i][0].focus();
          }
        }
      }
    },
    async displayRecords() {
      let records = await this.store.getRecords(this.id);
      let grouped = [];
      let lastCategory = null;
      let entries = null;
      records.forEach(record=>{
        record.inplaceActive=false;
        record.renderKey = 1;
        if (lastCategory!==record.category) {
          if (entries)
            grouped.push({entries});
          entries = [];
        }
        entries.push(record);
        lastCategory = record.category;
      });
      if (entries)
        grouped.push({entries});
      this.listRecords = grouped;
    },
    async onEditRecord(id,data) {
      await this.store.editRecord(id,data);
    },
    async onEditRecords(listIds,data) {
      await this.store.editRecords(listIds,data)
    },
    async deleteRecords(listIds) {
      let result = await this.store.deleteRecords(listIds);
      let affectedRows = this.store.db.getAffectedRows(result);
      if (affectedRows===listIds.length) {
        let i=0;
        let removeIds = [...listIds];
        while (i<this.listRecords.length && removeIds.length) {
          let k=0;
          let group = this.listRecords[i].entries;
          while (k<group.length && removeIds.length) {
            let ix=removeIds.indexOf(group[k].id);
            if (ix>-1) {
              group.splice(k,1);
              removeIds.splice(ix,1);
            }
            else
              k++;
          }
          if (!group.length)
            this.listRecords.splice(i,1);
          else
            i++;
        }
        this.updateRemainingCategories();
      }
    },
    getColumns(data,map,mapEditable = {}) {
      let columns = [];
      for (let key in data[0]) {
        columns.push({field:key,header:map[key]||key,hidden:map[key]===null,sortable:true,editable:mapEditable[key]});
      }
      return columns;
    },
    async displayData() {
      let data = await this.store.getClients(this.id);
      if (data.length) {
        this.columns = this.getColumns(data,this.mapColumns);
        //this.columnsPayments.splice(this.columnsPayments.length-1,1);
        this.rows = data;
      }
    },
    displayServices: async function() {
      let data = await this.store.getServicesForClient(this.id)
      this.serviceColumns = this.getColumns(data,this.mapServicesColumns,{rate:true,quantity:true,type:true,date:true});
      let ix = this.serviceColumns.map(item=>item.field).indexOf("importedId")
      if (ix>-1)
        this.serviceColumns.splice(ix,1);
      this.serviceRows = data;
    },
    requestAddService(display=true) {
      this.requestEditService(display);
    },
    async requestEditService(display=true,editService=false) {
      this.editServiceId = editService!==false ? editService["id"] : false;
      if (editService!==false) {
        let service = {...editService};
        if (service.typeId) {
          let types = this.mapServiceColumnEnum.typeId.options.filter(item=>item.code===service.typeId);
          if (types.length)
            service.typeId = types[0];
        }
        service.items = await this.store.getServiceItems(service.id);
        this.newService = service;
      }
      else
        this.newService = {};
      this.displayEditService=display;
    },
    async saveService(_data=this.newService) {
      let i=0;
      let list = this.mapServiceColumnEnum.billingPeriod.options;
      while (i<list.length && list[i].code!==_data.billingPeriod)
        i++;
      if (i<list.length)
        _data.billingPeriod = list[i];
      this.displayEditService=false;
      let data = {..._data};
      for (let key in data) {
        if (this.mapServiceColumnEnum[key])
          data[key] = data[key].code||data[key];
        else if (key.match(/^date/) || key.match(/_date$/)) {
          if (data[key]){
            let date = new Date(data[key]);
            data[key] = date.getFullYear()+"-"+("0"+(date.getMonth()+1)).slice(-2)+"-"+("0"+date.getDate()).slice(-2);
          }
          else
            data[key] = "";
        }
      }
      if (data.billingPeriod)
        await this.store.addInvoice(this.id,_data.billingPeriod)
      let items = data.items;
      delete data.items;
      delete data.type;
      delete data.value;
      if (this.editServiceId!==false)
          await this.store.saveService(_data.id,data,items.map(item=>{return {...item,label:(item.label||{}).label||item.label}}))
      else
          await this.store.addService(this.id,data,items.map(item=>{return {...item,label:(item.label||{}).label||item.label}}));
      this.displayServices();
    },
    async deleteSelectedServices() {
      this.$confirm.require({
        acceptClass:"p-button-danger",
        rejectClass:"p-button-text p-button-plain",
        acceptLabel:"Ja",
        rejectLabel:"Nein",
        message: 'Sicher?',
        header: 'Leistung'+(this.selectedServices.length>1 ? 'en' : '')+' löschen',
        icon: 'pi pi-exclamation-triangle',
        accept: async () => {
          let deleteCount = this.selectedServices.length;
          this.store.deleteServices(this.selectedServices.map(item=>item.id)).then((listResultSets)=>{
            let affectedRows = this.store.db.getAffectedRows(listResultSets);
            this.$toast.add({severity:affectedRows>0 && affectedRows===deleteCount ? "success" : "warn", summary: affectedRows>0 && affectedRows===deleteCount ? "Erfolgreich" : "Warnung", detail:(affectedRows>0 ? affectedRows : "Keine")+" "+(affectedRows==1 ? "Eintrag" : "Einträge")+" gelöscht", life: 3000})
            this.displayServices();
            this.selectedServices = null;
          }).catch(({code,message}) => {
            this.$toast.add({severity:'error', summary: 'Error Code '+code, detail:message, life: 3000});
          });
        },
        reject: () => {

        }
      });
    },
    onSelectionChange() {
      setTimeout(()=>{this.sidebarVisible = !!(this.selectedServices && this.selectedServices.length)});
    },
    rowClass(data) {
      if (data.paymentId)
        return "has-payment";
      else if (data.warningLevel===1)
        return "bg-yellow-300"
      else if (data.warningLevel===2)
        return "bg-orange-400 text-white"
      else if (data.warningLevel===3)
        return "has-problem";
      else if (data.warningLevel>3)
        return "bg-gray-200 text-gray-500"
    },
    async getTotalPaid() {
      return await this.store.getTotalIncoming();
    },
    initFilters() {
      this.filters = {
        'global': {value: null, matchMode: FilterMatchMode.CONTAINS},
        /*'matched': {operator: FilterOperator.OR, constraints: [{value: 0, matchMode: FilterMatchMode.EQUALS}]}*/
      };
    },
    clearFilters() {
      this.initFilters();
    },
    manageInvoiceSettings() {
      this.displayInvoiceSettings = true;
    },
    async deleteInvoice() {
      await this.store.deleteInvoice(this.rows[0].id);
      this.$router.push("/invoices");
    },
    async initCurrentWarningLevel() {
      if (this.rows.length) {
        this.selectedWarning = this.listWarnings[this.rows[0].warningLevel];
      }
    },
    async updateWarningLevel(e) {
        await this.store.setInvoiceWarningLevel(this.id,e.value && e.value.level ? [e.value.level] : []);
        await this.displayData();
    },
    gotoPayment({id}) {
      this.$router.push("/payments/"+id);
    },
    onChange() {
      clearTimeout(this.handleDebounce);
      this.handleDebounce = setTimeout(async  ()=>{
        let row = this.rows && this.rows[0] || {};
        let data = {};
        for (let key in this.mapColumns) {
          if (this.mapColumns[key]!==null)
            data[key] = row[key];
        }
        for (let key in this.mapColumnsCreditor) {
          if (this.mapColumnsCreditor[key]!==null)
            data[key] = row[key];
        }
        let resultSets = await this.store.saveClient(row.id||0,data);
        if (this.store.db.getAffectedRows(resultSets)!==1)
          this.$toast.add({severity:'error', summary: 'Speichern fehlgeschlagen', detail:'Letzte Änderung nicht gespeichert', life: 3000});
      },500)
    },
    async loadEnums() {
      this.mapServiceColumnEnum.billingPeriod.options = (await this.store.getBillingPeriods()).map(item=>{return {...item,code:item.billingPeriod,name:item.description+" "+item.year}})
      this.mapServiceColumnEnum.typeId.options = (await this.store.getServiceTypes()).map(item=>{return {code:item.id,name:item.internalName}})
    },
    onInplaceRecordCommit(record) {
      record.inplaceActive = false;
      record.renderKey++;
    },
    requestDeleteRecords(listIds,e,group) {
      e.stopImmediatePropagation();
      this.$confirm.require({
        acceptClass:"p-button-danger",
        rejectClass:"p-button-text p-button-plain",
        acceptLabel:"Ja",
        rejectLabel:"Nein",
        message: 'Sicher?',
        header: 'Eintrag löschen?',
        icon: 'pi pi-exclamation-triangle',
        accept: async () => {
          this.deleteRecords(listIds);
          if (e)
            e.stopImmediatePropagation();
          if (group)
            group.activeIndex = -1;
        },
        reject: () => {
        }
      });
    },
    requestAddCategory(display=true) {
      this.displayAddCategory=display;
    },
    async addCategory(category=this.newCategory) {
      await this.addRecord({category:category.name||category});
      this.requestAddCategory(false);
      this.$refs.op.hide();
      this.updateRemainingCategories();
    },
    searchCategory(e) {
      if (e.query) {
        let regex = new RegExp("^"+e.query,"i");
        this.filteredCategories = this.listCategories.filter(entry=>(entry.name||"").match(regex))
      }
    },
    updateRemainingCategories() {
      let categories = [...this.listCategories];
      let categoryNames = categories.map(item=>item.name);
      this.listRecords.forEach(group=>{
        let ix = categoryNames.indexOf(group.entries[0].category);
        if (ix>-1) {
          categoryNames.splice(ix,1);
          categories.splice(ix,1);
        }
      });
      categories.push({name:null});
      this.remainingCategories = categories;
    },
    async onPanelHeaderEdit(e,group) {
      let category = e.target.textContent.replace(/\s+$/g,"").replace(/^\s+/g,"");
      if (category && group.entries.length) {
        let oldCategory = group.entries[0].category;
        let allEntries = await this.store.getAllRecords([{category:oldCategory}]);
        await this.store.editRecords(allEntries.map(item=>item.id),{category});
        group.entries.forEach(entry=>entry.category = category);
      }
    }
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}
td {
  vertical-align:top
}
.p-fileupload-buttonbar {
  padding: 0!important;
  background:none!important;;
  border: none!important;
}
.p-fileupload-content {
  display:none;
}
tr.has-payment {
  background-color:lightgreen!important;
}
.top-bar {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.p-breadcrumb li:not(.p-breadcrumb-chevron) {
  cursor:pointer;
}
.invoice .p-dialog-header {
  padding:0 1.5rem!important;
}
.invoice .p-dialog-content {
  padding: 0!important;
}
.invoice .p-datatable-tbody td {
  vertical-align: middle;
}
/*.p-datatable.invoice td {
  border-bottom:none!important;
}*/
</style>