<template>
  <div class="flex flex-row justify-content-between align-items-center breadcrumb-container" ref="breadcrumbs">
    <Breadcrumb :home="home" :model="listCrumbs">
      <template #item="{item}">
        <span @click="$router.push(item.url||'')"><i :class="item.icon"></i>{{item.label}}</span>
      </template>
    </Breadcrumb>
    <div class="mx-2 flex flex-row">
      <Button icon="pi pi-download" class="p-button p-button-secondary mr-2" @click="requestDownload()" label="Export"/>
      <Button :icon="googleImportIcon" class="p-button mr-2" @click="requestImportGoogleCalendar()" label="Leistungen"/>
      <FileUpload name="dates[]" :customUpload="true" @uploader="handleDatesUpload" :auto="true" :showUploadButton="false" :showCancelButton="false" chooseLabel="Leistungen" :chooseIcon="datesUploadIcon">
        <template #empty></template>
      </FileUpload>
    </div>
  </div>
  <DataTable :value="rows" responsiveLayout="scroll" stripedRows :sortField="defaultSort.field" :sortOrder="defaultSort.order" :rowClass="rowClass" class="invoices"
             v-model:filters="filters" filterDisplay="menu" v-model:selection="selectedInvoices" @row-select="onSelectionChange" @row-unselect="onSelectionChange" @row-select-all="onSelectionChange" @row-unselect-all="onSelectionChange"
             stateStorage="session" stateKey="dt-state-invoices"
             v-model:expandedRows="expandedRows" @rowExpand="onRowExpand" @filter="onInvoicesFilter"
             :paginator="true" :rows="50" :rowsPerPageOptions="[50,100,(rows||[]).length-((rows||[]).length%100)+100]" :page-link-size="5">
    <template #header>
      <div class="flex flex-column md:flex-row justify-content-between align-items-center">
        <div>
          <table>
            <tr><th style="text-align:left">Faktura:</th><td style="font-weight: normal">CHF {{ Math.floor(totalDue||0) }}.-</td></tr>
            <tr><th style="text-align:left">Verbucht:</th><td style="font-weight: normal">CHF {{ Math.floor(totalPaid||0) }}.-</td></tr>
          </table>
        </div>
        <span class="p-input-icon-left mt-2 md:mt-0">
          <i class="pi pi-search" />
          <InputText v-model="filters['global'].value" placeholder="Suche" />
          <i class="pi pi-times right-0 mr-2 cursor-pointer" v-if="filters['global'].value" @click="filters['global'].value=''"/>
        </span>
        <div class="flex flex-column md:flex-row justify-content-center align-items-center">
          <!--<Button type="button" icon="pi pi-google" label="Import"></Button>-->
          <div class="flex flex-row align-items-center justify-content-end mt-2 md:mt-0">
            <SelectButton v-model="selectedFilters" :options="listFilters" optionLabel="name" @change="saveFilters" :multiple="true"/>
          </div>
        </div>
      </div>
    </template>
    <Column selectionMode="multiple"></Column>
    <Column :expander="true" headerStyle="" />
    <Column v-for="col of columns" :field="col.field" :header="col.header" :key="col.field" :sortable="col.sortable" :hidden="col.hidden">
      <template #body="{data,field}" v-if="col.field==='templateId'">
        <div class="flex flex-row justify-content-between align-items-center">
          <Dropdown v-model="data[field]" :options="listTemplates" optionLabel="name" optionValue="id" class="flex-grow-1" @change="onChangeTemplate(data)"></Dropdown>
        </div>
      </template>
    </Column>
    <Column bodyStyle="text-align: right">
      <template #body="slotProps">
        <div class="flex flex-row justify-content-end">
          <Button :icon="slotProps.data.pdfIcon||'pi pi-file-pdf'" :class="slotProps.data.buttonClass||'p-button-rounded p-button-plain p-button-text p-button-lg'" @click="generatePDF(slotProps.data)"/>
          <Button icon="pi pi-pencil" :class="(slotProps.data.customText ? 'text-orange-600 ' : '')+'p-button-rounded p-button-plain p-button-text p-button-lg'" @click="editCustomText(slotProps.data)"/>
          <div class="relative">
            <Button :icon="slotProps.data.envelopeIcon||'pi pi-envelope'" :class="slotProps.data.buttonClass||'p-button-rounded p-button-plain p-button-text p-button-lg'" @click="requestSendInvoice(slotProps.data)" :title="slotProps.data.messageResult">
            </Button>
            <Badge v-if="slotProps.data.sent" style="border-radius:unset;padding: 1px 2px;right: 2px;bottom: 2px;" class="absolute bg-green-600 text-white min-w-0 min-h-0 h-auto line-height-1">✓</Badge>
          </div>
          <Button icon="pi pi-arrow-right" class="p-button-rounded p-button-plain p-button-text p-button-lg" @click="gotoDataset($event,slotProps.data)"/>
        </div>
      </template>
    </Column>
    <template #empty>
      Keine Einträge gefunden
    </template>
    <template #loading>
    </template>
    <template #expansion="slotProps">
      <div class="services-subtable">
        <h4 class="mt-0 font-normal">
          <span v-if="slotProps.data.services&& slotProps.data.services.length">In Rechnung der Periode {{slotProps.data.billingPeriod}} enthaltene Leistungen:</span>
          <span v-else>Keine Leistungen in Periode {{slotProps.data.billingPeriod}} gefunden.</span>
        </h4>
        <DataTable v-if="slotProps.data.services&& slotProps.data.services.length" :value="slotProps.data.services" responsiveLayout="scroll">
          <Column v-for="col of servicesColumns" :field="col.field" :header="col.header" :key="col.field" :hidden="col.hidden" headerClass="bg-primary text-white"></Column>
        </DataTable>
      </div>
    </template>
  </DataTable>
  <Sidebar v-model:visible="sidebarVisible" position="bottom" :modal="false" :showCloseIcon="false" :class="'toolbar'">
    <div class="flex flex-row justify-content-between">
      <div class="flex flex-row justify-content-start">
        <Button type="button" class="p-button-secondary ml-2 my-2 mr-2" icon="pi pi-stop" label="Abwählen" @click="unselectInvoices()"></Button>
        <Button type="button" class="p-button-success mr-2 my-2 bg-green-500 border-green-500" icon="pi pi-check" :label="selectedInvoices && selectedInvoices.length && selectedInvoices[0].sent ? 'Nicht Versendet' : 'Versendet'" v-if="(selectedInvoices||[]).length" @click="requestSetSelectedInvoicesSent(!(selectedInvoices && selectedInvoices.length && selectedInvoices[0].sent))"></Button>
      </div>
      <div class="flex flex-row justify-content-end">
        <Button type="button" class="p-button-danger mr-2 my-2" icon="pi pi-trash" label="Löschen" v-if="(selectedInvoices||[]).length" @click="deleteSelectedInvoices"></Button>
        <Button type="button" class="p-button-secondary mr-2 my-2" icon="pi pi-eye" label="Vorschau" v-if="(selectedInvoices||[]).length" @click="previewSelectedInvoices"></Button>
        <Button type="button" class="p-button-primary mr-2 my-2" icon="pi pi-envelope" label="Senden" v-if="(selectedInvoices||[]).length" @click="requestSendSelectedInvoices"></Button>
      </div>
    </div>
  </Sidebar>
  <Dialog v-model:visible="displaySelectDateSheets" :modal="true" :close-on-escape="true" :draggable="false" :breakpoints="{'960px': '75vw', '640px': '95vw'}" :style="{width: '50vw', 'max-width':'1000px'}">
    <template #header>
      <h3>Leistungen aus Datei importieren</h3>
    </template>
    <Listbox v-model="selectedDateSheets" :options="listDateSheets" optionLabel="name" :multiple="true"/>
    <template #footer>
      <h4 class="text-left mt-4 mb-0" v-if="(selectedDateSheets||[]).length">Importieren als:</h4>
      <div class="flex flex-column">
        <div v-for="sheet of selectedDateSheets" :key="sheet.name" class="flex flex-row mt-2">
          <InputText type="text" v-model="sheet.importName" class="flex-grow-1"></InputText>
          <InputText type="text" v-model="sheet.importYear" class="flex-grow-1"></InputText>
        </div>
      </div>
      <div class="flex flex-row mt-4 justify-content-end">
        <Button label="Abbrechen" icon="pi pi-times" @click="hideSelectDateSheets" class="p-button-text"/>
        <Button label="Import" icon="pi pi-check" @click="importSelectedDateSheets" autofocus />
      </div>
    </template>
  </Dialog>
  <Dialog v-model:visible="displaySelectCalendar" :modal="true" :close-on-escape="true" :draggable="false" :breakpoints="{'960px': '75vw', '640px': '95vw'}" :style="{width: '50vw', 'max-width':'1000px'}">
    <template #header>
      <h3 class="m-0">Kalender wählen</h3>
    </template>
    <Listbox v-model="selectedCalendar" :options="listCalendars" optionLabel="summary"/>
    <template #footer>
      <div class="flex flex-row mt-4 justify-content-end">
        <Button label="Abbrechen" icon="pi pi-times" @click="requestDisplaySelectCalendar(false)" class="p-button-text"/>
        <Button :disabled="!selectedCalendar" label="Ok" :icon="committingSelectCalendar ? 'pi pi-spin pi-spinner': 'pi pi-check'" @click="requestDisplaySelectEvents()" autofocus />
      </div>
    </template>
  </Dialog>
  <Dialog v-model:visible="displaySelectEvents" :modal="true" :close-on-escape="true" :draggable="false" :breakpoints="{'960px': '75vw', '640px': '95vw'}" :style="{width: '50vw', 'max-width':'1000px'}">
    <template #header>
      <h3 class="m-0">Einträge wählen</h3>
    </template>
    <div class="p-fluid grid formgrid">
      <div class="field col-6">
        <label for="fromDate">Von</label>
        <Calendar id="fromDate" v-model="eventsFromDate" dateFormat="yy-mm-dd" @date-select="this.requestDisplaySelectEvents()"/>
      </div>
      <div class="field col-6">
        <label for="untilDate">Bis</label>
        <Calendar id="untilDate" v-model="eventsUntilDate" dateFormat="yy-mm-dd" @date-select="this.requestDisplaySelectEvents()"/>
      </div>
    </div>
    <DataTable :value="listEvents" responsiveLayout="scroll" stripedRows filterDisplay="menu" v-model:selection="selectedEvents" class="importEventsTable" v-model:filters="eventsFilters" edit-mode="cell" @cell-edit-complete="onSelectEventsCellEditComplete">
      <template #header>
        <div class="p-fluid grid formgrid">
          <div class="col-6">
          <span class="p-input-icon-left mt-2 md:mt-0">
            <i class="pi pi-search" />
            <InputText v-model="eventsFilters['global'].value" placeholder="Suche" />
            <i class="pi pi-times right-0 mr-2 cursor-pointer" v-if="eventsFilters['global'].value" @click="eventsFilters['global'].value=''"/>
          </span>
          </div>
          <div class="col-6">
          <div class="flex flex-row justify-content-end align-items-center">
            <!--<Button type="button" icon="pi pi-google" label="Import"></Button>-->
            <div class="flex flex-row align-items-center justify-content-end mt-2 md:mt-0">
              <div class="p-2 text-right">Nur unverarbeitete</div><InputSwitch v-model="hideImportedEvents" @change="onSwitchHideImportedEvents"/>
            </div>
          </div>
          </div>
        </div>
      </template>
      <Column selectionMode="multiple"></Column>
      <Column v-for="col of eventColumns" :field="col.field" :header="col.header" :key="col.field" :sortable="col.sortable"></Column>
      <Column header="Zuordnung">
        <template #body="slotProps">
          <span v-if="slotProps.data.matchedClientName">{{slotProps.data.matchedClientName}}</span>
          <Button v-else label="Neu" icon="pi pi-plus" title="Neuer Klient" @click="showNewClientDialog(slotProps.data)"></Button>
        </template>
      </Column>
      <Column header="Leistung" key="serviceTypeId" field="serviceTypeId">
        <template #editor="{ data, field }">
          <Dropdown v-model="data[field]" :options="listServicesTypes" optionLabel="internalName" optionValue="id" placeholder="Wählen" />
        </template>
        <template #body="slotProps">
          {{listServicesTypes.filter(item=>item.id===slotProps.data.serviceTypeId)[0].internalName}}
        </template>
      </Column>
    </DataTable>
    <template #footer>
      <div class="flex row pt-4">
        <BillingPeriods v-model:list="listBillingPeriods" v-model:selected="selectedBillingPeriod" @updateData="displayData()"></BillingPeriods>
        <div class="flex flex-row justify-content-end w-full">
          <Button label="Abbrechen" icon="pi pi-times" @click="requestDisplaySelectEvents(false)" class="p-button-text"/>
          <Button :disabled="!selectedEvents || !selectedEvents.length || !this.selectedBillingPeriod" label="Ok" icon="pi pi-check" @click="requestDisplaySelectEvents(false) && importEvents()" autofocus class="m-0"/>
        </div>
      </div>
    </template>
  </Dialog>
  <Dialog v-model:visible="displaySendInvoicesProgress" :modal="true" :breakpoints="{'960px': '75vw', '640px': '95vw'}" :style="{width: '50vw', 'max-width':'1000px'}">
    <template #header>
      <h3>{{sendingInvoicesLabel}}</h3>
    </template>
    <div class="py-2"><i class="pi pi-spin pi-spinner" v-if="sendingInvoicesProgress<100"></i><i class="pi pi-check" v-else></i> {{sendingInvoicesStatus}}</div>
    <ProgressBar :value="sendingInvoicesProgress">
      {{sendingInvoicesSent}}/{{sendingInvoicesSend}}
    </ProgressBar>
    <template #footer>
      <div class="flex flex-column align-items-start pt-4">
        <div class="flex flex-row mt-4 justify-content-end w-full">
          <Button v-if="sendingInvoicesSent<sendingInvoicesSend" label="Abbrechen" icon="pi pi-times" @click="abortSendingInvoices(false)" class="p-button-text"/>
          <Button v-if="sendingInvoicesSent===sendingInvoicesSend" label="Ok" icon="pi pi-check" @click="displaySendInvoicesProgress=false" class="p-button-text"/>
        </div>
      </div>
    </template>
  </Dialog>
</template>

<script>
import * as xlsx from "xlsx";
import {Bookeeper} from "@/bookeeper";
import {FilterMatchMode} from "primevue/api";
import axios from "axios";
import NewClientDialog from "@/components/NewClientDialog";
import EditorDialog from "@/components/EditorDialog";
import InvoicesPreviewDialog from "@/components/InvoicesPreviewDialog";
import BillingPeriods from "@/components/BillingPeriods";
import AuthView from "@/components/AuthView.vue";
import {singletons} from "@/singletons";
export default {
  name: 'InvoicesView',
  extends: AuthView,
  components: {
    BillingPeriods
  },
  data() {
    return {
      display:true,
      columns:null,
      rows:null,
      year:2022,
      store:null,
      defaultSort:{field:"id",order:1},
      totalDue:0,
      totalPaid:0,
      tabs: [
        {label: 'Rechnungen', to:"/invoices"},
        {label: 'Kontoauszug', to: '/payments'},
        {label: 'Abschreibungen', to: '/writeoffs'},
        {label: 'Einstellungen', to: '/settings'}
      ],
      filters:null,
      eventsFilters:null,
      mapColumns:{
        id:"Nr",
        name:"Name",
        description:"Monat",
        billingPeriod:"Datum",
        value:"Betrag",
        paymentId:null,
        warningLevel:"Mahnstufe",
        year:"Jahr",
        clientId:null,
        sent:null,
        customText:null,
        sendText:null,
        customSubject:null,
        sendSubject:null,
        messageResult:null,
        email:null,
        salutation:null,
        templateId:"Vorlage",
        givenName:"Vorname",
        cr_name:null,
        cr_givenName:null,
        cr_salutation:null,
        cr_email:null
      },
      loading:false,
      displaySelectDateSheets:false,
      selectedDateSheets:null,
      listDateSheets:[],
      datesUploadIcon:"",
      datesWorkbook:null,
      selectedInvoices:null,
      expandedRows:null,
      servicesColumns:[],
      mapServicesColumns:{
        id:"Nr",
        clientId:null,
        typeId:null,
        date:"Datum",
        billingPeriod:null,
        rate:null,
        quantity:null,
        type:"Leistungsart",
        importedId:null,
        value:"Betrag",
        description:null,
        case_nr:null,
        case_date:null,
        law:null,
        treatment_type:null,
        treatment_cause:null,
        referrer:null,
        diagnose:null,
        remarks:null
      },
      tableKey:0,
      home: {icon: 'pi pi-home', url: '/'},
      listCrumbs: [
        {label: 'Rechnungen',url: '/invoices'}
      ],
      sidebarVisible:false,
      displaySelectCalendar:false,
      listCalendars:[],
      selectedCalendar:null,
      displaySelectEvents:false,
      listEvents:[],
      selectedEvents:[],
      eventColumns: [{
        field:"summary",
        header:"Beschreibung"
      },{
        field:"dateHuman",
        header:"Datum"
      }],
      eventsFromDate:"",
      eventsUntilDate:new Date().toISOString(),
      displaySendInvoicesProgress:false,
      sendingInvoicesSend:0,
      sendingInvoicesSent:0,
      sendingInvoicesProgress:0,
      sendingInvoicesStatus:0,
      sendingInvoicesLabel:"Rechnungen werden versandt",
      abortedSendingInvoices:false,
      committingSelectCalendar:false,
      hideImportedEvents:false,
      mapSettings:{},
      listBillingPeriods:[],
      selectedBillingPeriod:null,
      filteredInvoices:[],
      listServicesTypes:[],
      listFilters:[
        {name:"Offen",key:"hideMatched"},
        {name:"Ungesendet",key:"hideSent"},
      ],
      selectedFilters:[],
      listTemplates:[],
      googleImportIcon:"pi pi-cloud"
    }
  },
  /*setup() {
    const listBillingPeriods=ref();
    const selectedBillingPeriod=ref();
    return {listBillingPeriods,selectedBillingPeriod};
  },*/
  mounted() {
    document.documentElement.style.setProperty('--breadcrumbsHeight', `${this.$refs.breadcrumbs.clientHeight}px`);
  },
  async created() {
    this.loadFilters();
    this.hideImportedEvents = this.$storage.getStorageSync("hideImportedEvents");
    this.updateDatesUploadIcon();
    this.loading = true;
    this.initFilters();
    this.store = await new Bookeeper().init();
    await this.loadTemplates();
    await this.displayData();
    this.onSelectionChange();
    let now = new Date();
    this.eventsUntilDate = new Date(now.getTime()+24*3600).toISOString().substr(0,10);
    this.eventsFromDate = now.getFullYear()+"-"+("0"+(now.getMonth()+1)).slice(-2)+"-01";
    this.mapSettings = await this.store.getSettings();
    if (this.$route.query.r)
      this.requestImportGoogleCalendar();
  },
  methods: {
    async loadTemplates() {
      this.listTemplates = await this.store.getTemplates();
    },
    async onChangeTemplate(invoice) {
      await this.store.setInvoiceTemplate(invoice.id,invoice.templateId);
      let rows = await this.store.getInvoices([invoice.id],3,this.hasFilter("hideMatched"),this.hasFilter("hideSent"));
      if (rows && rows.length===1) {
        let i=0;
        while (i<this.rows.length && this.rows[i].id!==invoice.id)
          i++;
        if (i<this.rows.length)
          this.rows[i] = rows[0];
      }
    },
    /** @deprecated: assumes client names are unique **/
    async handleUpload(e) {
      const file = e.files[0];
      const buffer = await file.arrayBuffer();
      const workbook = xlsx.read(buffer);
      let worksheet = workbook.Sheets[this.year]
      worksheet['!ref'] = "A1:P1000";
      let data = xlsx.utils.sheet_to_json(worksheet);
      await this.store.importInvoices(data);
      this.displayData();
    },
    /** @deprecated: assumes client names are unique **/
    async handleDatesUpload(e) {
      this.selectedDateSheets = [];
      this.updateDatesUploadIcon(true);
      const file = e.files[0];
      const buffer = await file.arrayBuffer();
      const workbook = xlsx.read(buffer);
      this.datesWorkbook = workbook;
      this.listDateSheets = workbook.SheetNames.map(name=>{return {name,importName:name,importYear:new Date().getFullYear()}});
      this.displaySelectDateSheets = true;
      this.updateDatesUploadIcon(false);
    },
    /** @deprecated: assumes client names are unique **/
    async importSelectedDateSheets() {
      this.hideSelectDateSheets();
      let map = {};
      this.selectedDateSheets.forEach(sheet=>{
        let worksheet = this.datesWorkbook.Sheets[sheet.name]
        let data = xlsx.utils.sheet_to_json(worksheet);
        data.forEach(set=>{
          let name = set["Name"]
          let i=0, c=0;
          let services = [];
          while (set["Termin_"+(++i)]) {
            let date = this.getDateFromExcelDigits(set["Termin_"+i]);
            services.push({date:date.getFullYear()+"-"+("0"+(date.getMonth()+1)).slice(-2)+"-"+("0"+date.getDate()).slice(-2),rate:set["CHF Ansatz"]})
            c++;
          }
          if (c && set["Name"] && set["CHF Ansatz"]) {
            map[name] = map[name] || {};
            map[name].ext = set;
            map[name].services = (map[name].services || []).concat(services);
          }
        });
      });
      let invoices = [];
      for (let name in map) {
        if (Object.prototype.hasOwnProperty.call(map,name)) {
          invoices.push({Name:name,...map[name]});
        }
      }
      this.store.importServices(invoices).then((listResultSets)=>{
        this.displayData();
        let affectedRows = this.store.db.getAffectedRows(listResultSets);
        this.$toast.add({severity:affectedRows ? "success" : "warn", summary: affectedRows ? "Erfolgreich" : "Warnung", detail:(affectedRows ? affectedRows : "Keine Neuen")+" Einträge importiert", life: 3000})
      }).catch((err)=>{
        this.$toast.add({severity:'error', summary: 'Fehler', detail:err, life: 3000})
        this.displayData();
      })
    },
    updateDatesUploadIcon(uploading=false) {
      this.datesUploadIcon = uploading ? "pi pi-sync pi-spin" : "pi pi-upload";
    },
    hideSelectDateSheets() {
      this.displaySelectDateSheets = false;
    },
    getColumns(data,mapColumns=this.mapColumns) {
      let columns = [];
      for (let key in data[0]) {
        columns.push({field:key,header:mapColumns[key]||key,hidden:mapColumns[key]===null,sortable:true});
      }
      return columns;
    },
    hasFilter(key) {
      return this.selectedFilters.filter(item=>item.key===key).length>0;
    },
    loadFilters() {
      this.selectedFilters.splice(0,this.selectedFilters.length);
      this.listFilters.forEach(filter=>{
        if (this.$storage.getStorageSync(filter.key))
          this.selectedFilters.push(filter);
      });
    },
    saveFilters() {
      this.listFilters.forEach(filter=>{
        this.$storage.setStorageSync(filter.key,this.hasFilter(filter.key));
      });
      this.displayData();
    },
    displayData: async function() {
      this.loading=true;
      let data = await this.store.getInvoices(false,3,this.hasFilter("hideMatched"),this.hasFilter("hideSent"));
      this.columns = (data.length) ? this.getColumns(data) : [];
      this.rows = data;
      this.loading = false;
      await this.refreshTotalDue();
      await this.refreshTotalPaid();
    },
    rowClass(data) {
      if (data.paymentId)
        return "has-payment";
      else if (data.warningLevel===1)
        return "bg-yellow-300"
      else if (data.warningLevel===2)
        return "bg-orange-400 text-white"
      else if (data.warningLevel===3)
        return "has-problem";
      else if (data.warningLevel>3)
        return "bg-gray-200 text-gray-500"
    },
    async refreshTotalDue() {
      this.totalDue = await this.store.getTotalDue(this.filteredInvoices.map(i=>i.id));
    },
    async refreshTotalPaid() {
      this.totalPaid = await this.store.getTotalAssigned(this.filteredInvoices.map(i=>i.id));
    },
    gotoDataset(event,data) {
      event.stopImmediatePropagation();
      this.$router.push("/invoices/"+data.id);
    },
    initFilters() {
      this.filters = {
        'global': {value: null, matchMode: FilterMatchMode.CONTAINS},
        /*'matched': {operator: FilterOperator.OR, constraints: [{value: 0, matchMode: FilterMatchMode.EQUALS}]}*/
      };
      this.eventsFilters = {
        'global': {value: null, matchMode: FilterMatchMode.CONTAINS},
      };
    },
    clearFilters() {
      this.initFilters();
    },
    getDateFromExcelDigits(days) {
      return new Date((new Date("1.1.1900")).getTime()+24*3600*1000*(days-2));
    },
    async deleteSelectedInvoices() {
      this.$confirm.require({
        acceptClass:"p-button-danger",
        rejectClass:"p-button-text p-button-plain",
        acceptLabel:"Ja",
        rejectLabel:"Nein",
        message: 'Sicher?',
        header: 'Rechnung'+(this.selectedInvoices.length>1 ? 'en' : '')+' löschen',
        icon: 'pi pi-exclamation-triangle',
        accept: async () => {
          let deleteCount = this.selectedInvoices.length;
          this.store.deleteInvoices(this.selectedInvoices.filter(item=>!item.paymentId).map(item=>item.id)).then((listResultSets)=>{
            let affectedRows = this.store.db.getAffectedRows(listResultSets);
            this.$toast.add({severity:affectedRows>0 && affectedRows===deleteCount ? "success" : "warn", summary: affectedRows>0 && affectedRows===deleteCount ? "Erfolgreich" : "Warnung", detail:(affectedRows>0 ? affectedRows : "Keine")+" "+(affectedRows==1 ? "Eintrag" : "Einträge")+" gelöscht", life: 3000})
            this.displayData();
            this.selectedInvoices = null;
          }).catch(({code,message}) => {
            this.$toast.add({severity:'error', summary: 'Error Code '+code, detail:message, life: 3000});
          });
        },
        reject: () => {
        }
      });
    },
    onSelectionChange() {
      setTimeout(()=>{this.sidebarVisible = !!(this.selectedInvoices && this.selectedInvoices.length)});
    },
    async onRowExpand(e) {
      let targetId = e.data.id;
      let i=0;
      while (i<this.rows.length && this.rows[i].id!==targetId)
        i++;
      if (i<this.rows.length) {
        let services = await this.store.getServicesForInvoice(targetId);
        this.rows[i].services = services;
        if (services.length) {
          this.servicesColumns = this.getColumns(services,this.mapServicesColumns);
        }
      }
    },
    updateButtonStyle(args,downloading=false) {
      let i=0;
      while (i<this.rows.length && this.rows[i].id!==args.id)
        i++;
      if (i<this.rows.length) {
        this.rows[i][`${args.which||'pdf'}Icon`]=downloading ? "pi pi-spin pi-spinner" : null;
        this.rows[i].buttonClass=downloading ? "p-button-rounded p-button-success p-button-text p-button-lg" : null;
      }
    },
    async generatePDF(args,download=true) {
      let serviceTypes = await this.store.getServiceTypes();
      let services = {}
      await Promise.all(serviceTypes.map(async type=>{
        let list = (await this.store.getServicesForInvoice(args.id,type.id)).sort((a,b)=>{return a.date>b.date ? 1 : -1});
        list.forEach(async service=>{
          service.items = await this.store.getServiceItems(service.id);
        })
        services[type.name] = (services[type.name] || []).concat(list);
      }));
      let rows = await this.store.getClients(args.clientId);
      let data = rows.length>0 ? rows[0] : {};
      this.updateButtonStyle(args,true);
      const warningLevel = args.warningLevel || 0;
      let response = null;
      try {
        response = await axios.post(`${this.mapSettings.apiBaseUrl}/transform/invoice${warningLevel ? `-${warningLevel}` : ''}`,{
          ...args,
          services,
          data,
          payment:{
            creditor:{
              address:[this.mapSettings["creditor-name"],
                this.mapSettings["creditor-street"],
                this.mapSettings["creditor-city"],
                this.mapSettings["creditor-countrycode"]
              ],
              iban:this.mapSettings["creditor-iban"]
            },
            amount:args.value,
            details:this.store.getInvoiceDetailsFromId(args.id)
          },
          legacyApiToken:singletons.legacyApiToken
        });
      } catch (err) {
        this.updateButtonStyle(args);
        this.$toast.add({severity:'error', summary: 'Fehler', detail:err.response && err.response.data && err.response.data.error || "Server error", life: 3000})
        return false;
      }
      if (download)
        window.open(`${this.mapSettings.apiBaseUrl}/download/invoice/${response.data.uuid}?legacyApiToken=${singletons.legacyApiToken}`);
      this.updateButtonStyle(args);
      return response.data.uuid ? response.data.uuid : false;
      /*let {data} = await axios({method:"get",url:"http://localhost:3435/download/invoice/"+response.data.uuid,responseType: "blob"});
      saveAs(data,args.id+".pdf");*/
    },
    evalString(str,context) {
      function evalInContext() {
        return eval("`"+str.replace(/\$\{([^}]+)\}/g,"${this.$1}")+"`");
      }
      return evalInContext.call(context);
    },
    evalInvoiceText(invoice) {
      return this.evalString(invoice.sendText,invoice);
    },
    evalInvoiceSubject(invoice) {
      return this.evalString(invoice.sendSubject,invoice);
    },
    getInvoiceMailData(invoice,attachmentUuid,attachmentName="Rechnung") {
      return {
          from:this.mapSettings["mail-user"],
          ...this.mapSettings["mail-name"] ? {fromName:this.mapSettings["mail-name"]} : {},
          to:[invoice.cr_email || invoice.email],
          subject:this.evalInvoiceSubject(invoice),
          html:this.evalInvoiceText(invoice),
          invoice:{
            uuid:attachmentUuid,
            name:`${attachmentName}`
          }
      }
    },
    isValidEmail(str) {
      return str.match(/.+@.+/);
    },
    async sendInvoiceData(mailData) {
      let error = false;
      if (mailData.to.length && this.isValidEmail(mailData.to[0])) {
        this.setSendingInvoicesStatus(`Sende: ${mailData.name} <${mailData.to.length ? mailData.to[0] : ""}>`);
        this.updateButtonStyle({...mailData,which:"envelope"},true);
        try {
          await axios.post(`${this.mapSettings.apiBaseUrl}/send-invoice/`,{...mailData,legacyApiToken:singletons.legacyApiToken}).then(async (res)=>{
            this.$toast.add({severity:'success', summary: 'Erfolgreich', detail:res.data, life: 3000});
            await this.setInvoiceSent(mailData.id,mailData.warningLevel,1,res.data||"");
          }).catch((err)=>{
            this.$toast.add({severity:'error', summary: err, detail:err.response.data.response, life: 3000});
            error = true;
          })
        } catch (err) {
          this.$toast.add({severity:'error', summary: 'Fehler', detail:err, life: 3000});
          error = true;
        }
        this.updateButtonStyle({...mailData,which:"envelope"});
        if (error)
          return false;
      }
      else
        this.$toast.add({severity:'error', summary: 'Nicht gesendet', detail:`Adresse '${mailData.to.length ? mailData.to[0] : ""}' ungültig`, life: 3000});
    },
    async sendInvoice(args,dryrun=false) {
      this.setSendingInvoicesStatus(`Erstelle Rechnung: ${args.name}`);
      let uuid = await this.generatePDF(args,false);
      let success = null;
      if (uuid!==false) {
        let mailData = {
          ...args,
          ...this.getInvoiceMailData(args,uuid)
        }
        if (!dryrun)
          success = await this.sendInvoiceData(mailData);
        else
          return mailData;
      }
      else
        success=false;
      return success;
    },
    requestSendInvoice(args) {
      this.$confirm.require({
        acceptClass:"p-button-danger",
        rejectClass:"p-button-text p-button-plain",
        acceptLabel:"Ja",
        rejectLabel:"Nein",
        message: 'Sicher?',
        header: 'Rechnung senden',
        icon: 'pi pi-exclamation-triangle',
        accept: () => this.sendInvoice(args),
        reject: () => {}
      });
    },
    applyThrottling() {
      let delaySeconds = this.mapSettings["send-mail-delay"]||0;
      return new Promise(resolve=>{
        setTimeout(()=>resolve(),delaySeconds*1000);
      })
    },
    async previewSelectedInvoices() {
      this.sendingInvoicesSend = this.selectedInvoices.length;
      this.sendingInvoicesSent = 0;
      this.abortedSendingInvoices = false;
      this.sendingInvoicesProgress = 0;
      this.sendingInvoicesLabel="Vorschau wird erzeugt";
      this.displaySendInvoicesProgress = true;
      let i=0;
      let listPreviews = [];
      while (i<this.sendingInvoicesSend && !this.abortedSendingInvoices) {
        let invoice = this.selectedInvoices[i];
        this.setSendingInvoicesStatus(`${invoice.name} <${invoice.email}> (Vorschau)`);
        if (!this.abortedSendingInvoices) {
          let data = await this.sendInvoice(invoice,true);
          if (data===false)
            this.abortSendingInvoices();
          else
            listPreviews.push(data);
          this.sendingInvoicesSent++;
          this.sendingInvoicesProgress = Math.floor(100*this.sendingInvoicesSent/this.sendingInvoicesSend);
          i++;
        }
      }
      setTimeout(()=>{
        this.displaySendInvoicesProgress = false;
        this.$dialog.open(InvoicesPreviewDialog, {
          props: {
            header: 'Vorschau',
            style: {
              width: '50vw',
            },
            breakpoints:{
              '960px': '75vw',
              '640px': '90vw'
            },
            modal: false
          },
          data: {
            listPreviews
          },
          onClose: (options) => {
            const data = options.data;
            if (data && data.listPreviews) {
              this.requestSendInvoices(data.listPreviews)
            }
          }
        });
      },500);
    },
    setSendingInvoicesStatus(state) {
      this.sendingInvoicesStatus = state;
    },
    async sendSelectedInvoices() {
      this.sendingInvoicesSend = this.selectedInvoices.length;
      this.sendingInvoicesSent = 0;
      this.abortedSendingInvoices = false;
      this.sendingInvoicesProgress = 0;
      this.sendingInvoicesLabel="Rechnungen werden versandt";
      this.displaySendInvoicesProgress = true;
      let i=0;
      while (i<this.sendingInvoicesSend && !this.abortedSendingInvoices) {
        let delaySeconds = this.mapSettings["send-mail-delay"]||0;
        let invoice = this.selectedInvoices[i];
        this.setSendingInvoicesStatus(`${invoice.name} <${invoice.email}> (Sende-Verzögerung ${delaySeconds}s)`);
        await this.applyThrottling();
        if (!this.abortedSendingInvoices) {
          let success = await this.sendInvoice(invoice);
          if (success===false)
            this.abortSendingInvoices();
          this.sendingInvoicesSent++;
          this.sendingInvoicesProgress = Math.floor(100*this.sendingInvoicesSent/this.sendingInvoicesSend);
          i++;
        }
      }
      if (!this.abortedSendingInvoices)
        this.unselectInvoices();
    },
    requestSendSelectedInvoices() {
      this.$confirm.require({
        acceptClass:"p-button-danger",
        rejectClass:"p-button-text p-button-plain",
        acceptLabel:"Ja",
        rejectLabel:"Nein",
        message: `Rechnungen werden an ${this.selectedInvoices.length} Empfänger versandt. Fortfahren?`,
        header: 'Rechnungen senden',
        icon: 'pi pi-exclamation-triangle',
        accept: () => this.sendSelectedInvoices(),
        reject: () => {}
      });
    },
    async sendInvoices(listPreviews) {
      this.sendingInvoicesSend = listPreviews.length;
      this.sendingInvoicesSent = 0;
      this.abortedSendingInvoices = false;
      this.sendingInvoicesProgress = 0;
      this.sendingInvoicesLabel="Rechnungen werden versandt";
      this.displaySendInvoicesProgress = true;
      let i=0;
      while (i<this.sendingInvoicesSend && !this.abortedSendingInvoices) {
        let delaySeconds = this.mapSettings["send-mail-delay"]||0;
        let mailData = listPreviews[i];
        this.setSendingInvoicesStatus(`${mailData.name} <${mailData.to}> (Sende-Verzögerung ${delaySeconds}s)`);
        await this.applyThrottling();
        if (!this.abortedSendingInvoices) {
          let success = await this.sendInvoiceData(mailData);
          if (success===false)
            this.abortSendingInvoices();
          this.sendingInvoicesSent++;
          this.sendingInvoicesProgress = Math.floor(100*this.sendingInvoicesSent/this.sendingInvoicesSend);
          i++;
        }
      }
      if (!this.abortedSendingInvoices)
        this.unselectInvoices();
    },
    requestSendInvoices(listPreviews) {
      this.$confirm.require({
        acceptClass:"p-button-danger",
        rejectClass:"p-button-text p-button-plain",
        acceptLabel:"Ja",
        rejectLabel:"Nein",
        message: `Rechnungen werden an ${listPreviews.length} Empfänger versandt. Fortfahren?`,
        header: 'Rechnungen senden',
        icon: 'pi pi-exclamation-triangle',
        accept: () => this.sendInvoices(listPreviews),
        reject: () => {}
      });
    },
    abortSendingInvoices() {
      this.abortedSendingInvoices = true;
      this.displaySendInvoicesProgress = false;
    },
    async setInvoiceSent(invoiceId,warningLevel=0,sent=true,messageResult="") {
      await this.store.setInvoiceSent(invoiceId,warningLevel,sent,messageResult);
      let i=0;
      while (i<this.rows.length && this.rows[i].id!==invoiceId)
        i++;
      if (i<this.rows.length) {
        this.rows[i].sent=sent;
        this.rows[i].messageResult = messageResult;
      }
    },
    async requestSetSelectedInvoicesSent(sent=true) {
      this.$confirm.require({
        acceptClass:"p-button-danger",
        rejectClass:"p-button-text p-button-plain",
        acceptLabel:"Ja",
        rejectLabel:"Nein",
        message: `Rechnungen werden an als ${sent ? "" : "nicht "}versendet markiert. Fortfahren?`,
        header: `Rechnungen als ${sent ? "" : "nicht "}versendet markieren`,
        icon: 'pi pi-exclamation-triangle',
        accept: async () => {
          await Promise.all(this.selectedInvoices.map(async invoice=>{await this.setInvoiceSent(invoice.id,invoice.warningLevel,sent ? 1 : 0)}));
          this.unselectInvoices();
          this.displayData();
        },
        reject: () => {}
      });
    },
    setGoogleImportIcon(importing=false) {
      this.googleImportIcon = importing ? "pi pi-sync pi-spin" : "pi pi-cloud";
    },
    async requestImportGoogleCalendar(reset=false,retry=0) {
      this.setGoogleImportIcon(true);
      let auth = await axios.post(`${this.mapSettings.apiBaseUrl}/authorize/`, {
        legacyApiToken:singletons.legacyApiToken,
        domain:location.origin,
        target:location.href,
        ...reset ? {
          reset:1
        } : {}
      });
      if (auth.data.url)
        location.href = auth.data.url
      else {
        this.listCalendars = (await axios.get(`${this.mapSettings.apiBaseUrl}/calendar/list/`, {params:{legacyApiToken:singletons.legacyApiToken}})).data;
        if (!this.listCalendars.length && !retry)
          return this.requestImportGoogleCalendar(true,retry+1);
        this.requestDisplaySelectCalendar();
      }
      this.setGoogleImportIcon();
    },
    requestDisplaySelectCalendar(display=true) {
      this.displaySelectCalendar = display;
    },
    async displayEvents() {
      this.committingSelectCalendar = true;
      let url = `${this.mapSettings.apiBaseUrl}/calendar/events/?calendarId=${this.selectedCalendar.id}`;
      if (this.eventsFromDate)
        url+=`&fromDate=${new Date(this.eventsFromDate).toISOString()}`;
      if (this.eventsUntilDate)
        url+=`&untilDate=${new Date(this.eventsUntilDate).toISOString()}`;
      url+=`&legacyApiToken=${singletons.legacyApiToken}`
      let data = (await axios.get(url, {})).data||[];
      let mapClients = {};
      let list = await this.store.getClients();
      list.forEach(client=>{
        if (client.alias)
          mapClients[client.alias.toLowerCase()]=client;
      });
      let mapLastServiceTypeId = await this.store.getClientsLastServiceTypeMap();
      let events = data.filter(item=>item.summary);
      if (this.hideImportedEvents) {
        let importedIds = await this.store.getServicesImportedIds();
        events = events.filter(item=>importedIds.indexOf(item.id)===-1);
      }
      this.listServicesTypes = await this.store.getServiceTypes();
      events = events.filter(item=>item.start && item.start.dateTime).map(item=>{
        let alias  = this.getAliasFromEvent(item);
        let client = mapClients[alias.toLowerCase()]||{};
        return {
          summary:item.summary,
          date:new Date(item.start.dateTime).toISOString().substr(0,10),
          dateHuman:new Date(item.start.dateTime).toLocaleDateString("de-CH", { year: 'numeric', month: 'long', day: 'numeric', weekday:'long'}),
          start:item.start.dateTime,
          end:item.end.dateTime,
          matchedClientName:client.name||"",
          id:item.id,
          htmlLink:item.htmlLink,
          ...this.listServicesTypes.length ? {serviceTypeId:mapLastServiceTypeId[client.id]||1} : {}
        }
      });
      this.listEvents = events;
    },
    async requestDisplaySelectEvents(display=true) {
      if (display) {
        await this.displayEvents();
      }
      this.requestDisplaySelectCalendar(false);
      this.displaySelectEvents = display;
      this.committingSelectCalendar = false;
    },
    async importEvents() {
      let billingPeriod = null;
      let i=0;
      while (i<this.listBillingPeriods.length && this.listBillingPeriods[i].code!=this.selectedBillingPeriod)
        i++;
      if (i<this.listBillingPeriods.length)
        billingPeriod = this.listBillingPeriods[i];
      let affectedRows = 0;
      if (this.selectedEvents && billingPeriod) {
        let mapAliasToClient = await this.store.getClientsLowerCaseAliasMap();
        await Promise.all(this.selectedEvents.map(async event=>{
          let alias = event.summary.replace(/\s.*$/g,"");
          let client = mapAliasToClient[alias.toLowerCase()] || false;
          if (client!==false) {
            let id = client.id;
            await this.store.addInvoice(id,billingPeriod)
            let defaultItems = await this.store.getServiceTypeDefaultItems(event.serviceTypeId);
            let resultSet = await this.store.addService(id,{
              date:event.date,
              billingPeriod:billingPeriod.code,
              importedId:event.id,
              typeId:event.serviceTypeId,
              rate:client.rate
            },
            defaultItems);
            affectedRows+=this.store.db.getAffectedRows(resultSet);
          }
        }));
        this.$toast.add({severity:affectedRows ? "success" : "warn", summary: affectedRows ? "Erfolgreich" : "Warnung", detail:(affectedRows ? affectedRows : "Keine Neuen")+" Einträge importiert", life: 3000})
        this.displayData();
      }
    },
    onSelectEventsCellEditComplete(e) {
      let {data, newValue, field} = e;
      data[field] = newValue;
    },
    onSwitchHideImportedEvents() {
      this.$storage.setStorageSync("hideImportedEvents",this.hideImportedEvents);
      this.displayEvents();
    },
    getAliasFromEvent(item) {
      return item.summary.split(/\s+/)[0];
    },
    unselectInvoices() {
      this.selectedInvoices = null;
      this.onSelectionChange();
    },
    async newClient({alias,name,rate}) {
      let inserted = await this.store.addClient();
      if (inserted.length && inserted[0].insertId) {
        let clientId = inserted[0].insertId;
        let listResultSets = await this.store.saveClient(clientId,{alias,name,rate})
        let affectedRows = this.store.db.getAffectedRows(listResultSets);
        this.$toast.add({severity:affectedRows ? "success" : "warn", summary: affectedRows ? "Erfolgreich" : "Warnung", detail:affectedRows ? "Klient angelegt" : "Klient konnte nicht angelegt werden", life: 3000})
        this.displayEvents();
      }
    },
    showNewClientDialog(eventItem) {
      let alias = this.getAliasFromEvent(eventItem);
      this.$dialog.open(NewClientDialog, {
        props: {
          header: 'Neuer Klient',
          modal: true
        },
        data: {
          alias
        },
        onClose: (options) => {
          const data = options.data;
          if (data) {
            this.newClient({eventItem,...data})
          }
        }
      });
    },
    async editCustomText(invoiceItem) {
      let val = this.evalInvoiceText(invoiceItem);
      let title = this.evalInvoiceSubject(invoiceItem);
      this.$dialog.open(EditorDialog, {
        props: {
          header: 'Vorlage bearbeiten',
          modal: true
        },
        data: {
          val,
          title,
          titleLabel:"Betreff"
        },
        onClose: async (options) => {
          const data = options.data;
          if (data) {
            await this.store.saveCustomText(invoiceItem.id,invoiceItem.warningLevel,data.val,data.title);
            let invoice = await this.store.getInvoices([invoiceItem.id]);
            if (invoice.length) {
              invoiceItem.customText = invoice[0].customText;
              invoiceItem.sendText = invoice[0].sendText;
              invoiceItem.customSubject = invoice[0].customSubject;
              invoiceItem.sendSubject = invoice[0].sendSubject;
            }
          }
        }
      });
    },
    onInvoicesFilter(e) {
      this.filteredInvoices=e.filteredValue;
      this.refreshTotalDue();
      this.refreshTotalPaid();
    },
    async requestDownload() {
      let year = 2022;
      let list = this.filteredInvoices.filter(item=>item.year===year);
      let clients = await this.store.getClients();
      let billingPeriods = (await this.store.getBillingPeriods()).filter(item=>item.year===year).map(item=>item.billingPeriod).reverse();
      let mapBillingPeriodsIx = {};
      let i=0;
      for (let item of billingPeriods) {
        mapBillingPeriodsIx[item] = i++;
      }
      let mapClients = {};
      for (let c of clients) {
        mapClients[c.id] = c.alias;
      }
      let mapData = {};
      for (let item of list) {
        let clientAlias = mapClients[item.clientId];
        let ix = mapBillingPeriodsIx[item.billingPeriod]
        if (clientAlias && typeof ix!=="undefined") {
          mapData[clientAlias] = mapData[clientAlias] || [];
          mapData[clientAlias][ix] = mapData[clientAlias][ix] || 0;
          if (item.paymentId)
            mapData[clientAlias][ix] += item.value;
        }
      }

      let lines = [["alias",...billingPeriods].join(",")];
      for (let key in mapData) {
        lines.push(`${key.toUpperCase()},${(mapData[key]||[]).join(",")}`);
      }
      const blob = new Blob([lines.join("\n")], { type: 'text/csv' });
      const link = document.createElement('a');
      link.href = URL.createObjectURL(blob);
      link.download = "export.csv";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}
td {
  vertical-align:top
}
.p-fileupload-buttonbar {
  padding: 0!important;
  background:none!important;
  border: none!important;
  margin:1rem 0;
}
.p-fileupload-content {
  display:none;
}
tr.has-payment {
  background-color:lightgreen!important;
}
tr.has-problem {
  background-color:red!important;
  color:white!important;
}
.top-bar {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.invoices .p-datatable-header {
  border-top: none!important;
}
.p-fileupload .p-fileupload-buttonbar .p-button {
  margin-right: 0!important;
}
.p-datatable.invoices tr>td {
  cursor:pointer;
  vertical-align: middle;
}
</style>