<template>
  <div class="p-datatable">
    <div class="p-datatable-header border-top-none  flex flex-row justify-content-between align-items-center py-2 pr-0">
      <div class=" flex-grow-1">Dokumente und E-Mail</div>
      <Button icon="pi pi-plus" label="Mehr" class="mr-1 p-button-success bg-green-500 border-green-500" @click="addAdditionalTemplate"></Button>
    </div>
  </div>
  <TemplatesComponent></TemplatesComponent>
  <TemplatesComponent v-for="template of listAdditionalTemplates" v-bind:key="template.id" :name="template.name" :id="template.id"
    @onRequestDelete="requestDelete(template)"></TemplatesComponent>
  <DataTable :value="listCatalogs" responsiveLayout="scroll" stripedRows class="payment mt-3" dataKey="id" edit-mode="cell" @cell-edit-complete="onCatalogsCellEditComplete">
    <Column v-for="col of catalogColumns" :field="col.field" :header="col.header" :key="col.field" :sortable="col.sortable" :hidden="col.hidden">
      <template #editor="{ data, field }" v-if="col.editable">
        <InputText v-model="data[field]" autofocus />
      </template>
    </Column>
    <Column bodyStyle="text-align: right">
      <template #body="slotProps">
        <div class="flex flex-row justify-content-end">
          <Button icon="pi pi-pencil" :class="'p-button-rounded p-button-plain p-button-text p-button-lg'" @click="requestEditCatalogItems(slotProps.data)"/>
          <Button icon="pi pi-trash" :class="'p-button-rounded p-button-plain p-button-text p-button-lg'" @click="requestDeleteCatalog(slotProps.data)"/>
        </div>
      </template>
    </Column>
    <template #empty>
      Keine Kataloge gefunden
    </template>
    <div class="flex flex-row justify-content-end mt-2 absolute right-0">
      <Button icon="pi pi-plus" label="" class="mr-1 p-button-success bg-green-500 border-green-500" @click="addCatalog"></Button>
    </div>
  </DataTable>
  <DataTable :value="listServiceTypes" responsiveLayout="scroll" stripedRows class="payment mt-3" dataKey="id" edit-mode="cell" @cell-edit-complete="onServiceTypesCellEditComplete">
    <Column v-for="col of serviceTypeColumns" :field="col.field" :header="col.header" :key="col.field" :sortable="col.sortable" :hidden="col.hidden">
      <template #editor="{ data, field }" v-if="col.editable">
        <InputText v-model="data[field]" autofocus />
      </template>
    </Column>
    <Column bodyStyle="text-align: right">
      <template #body="slotProps">
        <div class="flex flex-row justify-content-end">
          <Button icon="pi pi-pencil" :class="'p-button-rounded p-button-plain p-button-text p-button-lg'" @click="requestEditServiceTypesDefaultItems(slotProps.data)"/>
          <Button icon="pi pi-trash" :class="'p-button-rounded p-button-plain p-button-text p-button-lg'" @click="requestDeleteServiceType(slotProps.data)"/>
        </div>
      </template>
    </Column>
    <template #empty>
      Keine Einträge gefunden
    </template>
    <div class="flex flex-row justify-content-end mt-2 absolute right-0">
      <Button icon="pi pi-plus" label="" class="mr-1 p-button-success bg-green-500 border-green-500" @click="addServiceType"></Button>
    </div>
  </DataTable>
</template>

<script>
import {Bookeeper} from "@/bookeeper";
import Gapi from "@/gapi";
import CatalogItemsDialog from "@/components/CatalogItemsDialog";
import DefaultItemsDialog from "@/components/DefaultItemsDialog";
import TemplatesComponent from "@/components/TemplatesComponent";
import AuthView from "@/components/AuthView.vue";
export default {
  name: 'TemplatesView',
  extends: AuthView,
  components: {
    TemplatesComponent
  },
  data() {
    return {
      display:true,
      columns:null,
      rows:null,
      year:2022,
      store:null,
      defaultSort:{field:"id",order:1},
      totalWriteoffs:0,
      tabs: [
        {label: 'Rechnungen', to:"/invoices"},
        {label: 'Kontoauszug', to: '/payments'},
        {label: 'Abschreibungen', to: '/writeoffs'},
        {label: 'Einstellungen', to: '/settings'}
      ],
      filters:null,
      mapColumns:{
        id:"Nr",
        name:"Klient",
        description:"Monat",
        billingPeriod:"Rechnungsdatum",
        value:"Betrag",
        paymentId:null,
        warningLevel:null
      },
      gapi:null,
      mapSettings:{},
      listCatalogs:[],
      catalogColumns: [
        {field:"id",header:'Nr',editable:true},
        {field:"name",header:'Leistungskatalog',editable:true},
        {field:"taxUnit",header:'Tax-Einheit',editable:true},
        {field:"taxRate",header:'Tax-Rate',editable:true}
      ],
      serviceTypeColumns: [
        {field:"id",header:'Nr',editable:true},
        {field:"name",header:'Leistungen',editable:true},
        {field:"internalName",header:'Interne Bezeichnung',editable:true}
      ],
      listServiceTypes:[],
      listAdditionalTemplates:[]
    }
  },
  async created() {
    this.store = await new Bookeeper().init();
    this.mapSettings = await this.store.getSettings();
    await this.loadAdditionalTemplates();
    await this.displayCatalogs();
    await this.displayServiceTypes();
    this.gapi = new Gapi();
  },
  methods: {
    requestDelete({id}) {
      this.$confirm.require({
        acceptClass:"p-button-primary",
        rejectClass:"p-button-text p-button-plain",
        acceptLabel:"Ja",
        rejectLabel:"Nein",
        message: 'Sicher?',
        header: 'Vorlagen löschen?',
        icon: 'pi pi-exclamation-triangle',
        acceptIcon:'pi pi-exclamation-triangle',
        accept: async () => {
          await this.store.deleteAdditionalTemplate(id);
          await this.loadAdditionalTemplates();
        }
      });
    },
    requestDeleteCatalog({id}) {
      this.$confirm.require({
        acceptClass:"p-button-primary",
        rejectClass:"p-button-text p-button-plain",
        acceptLabel:"Ja",
        rejectLabel:"Nein",
        message: 'Sicher?',
        header: 'Katalog löschen?',
        icon: 'pi pi-exclamation-triangle',
        acceptIcon:'pi pi-exclamation-triangle',
        accept: async () => {
          await this.store.deleteCatalog(id);
          this.listCatalogs = await this.store.getCatalogs();
        }
      });
    },
    requestDeleteServiceType({id}) {
      this.$confirm.require({
        acceptClass:"p-button-primary",
        rejectClass:"p-button-text p-button-plain",
        acceptLabel:"Ja",
        rejectLabel:"Nein",
        message: 'Sicher?',
        header: 'Leistungskategorie löschen?',
        icon: 'pi pi-exclamation-triangle',
        acceptIcon:'pi pi-exclamation-triangle',
        accept: async () => {
          await this.store.deleteServiceType(id);
          this.listServiceTypes = await this.store.getServiceTypes();
        }
      });
    },
    async addCatalog() {
      await this.store.addCatalog();
      this.listCatalogs = await this.store.getCatalogs();
    },
    async addServiceType() {
      await this.store.addServiceType();
      this.listServiceTypes = await this.store.getServiceTypes();
    },
    async addAdditionalTemplate() {
      await this.store.addAdditionalTemplate();
      await this.loadAdditionalTemplates();
    },
    async loadAdditionalTemplates() {
      this.listAdditionalTemplates = await this.store.getAdditionalTemplates();
    },
    async displayServiceTypes() {
      this.listServiceTypes = await this.store.getServiceTypes();
    },
    async displayCatalogs() {
      this.listCatalogs = await this.store.getCatalogs();
    },
    requestEditCatalogItems(catalog) {
      this.$dialog.open(CatalogItemsDialog, {
        props: {
          header: `Katalog '${catalog.name}' bearbeiten`,
          style: {
            width: '50vw',
          },
          breakpoints:{
            '960px': '75vw',
            '640px': '90vw'
          },
          modal: true
        },
        data: {
          catalogId:catalog.id
        },
        onClose: async (options) => {
          const data = options.data;
          if (data && data.items) {
            await this.store.deleteCatalogItems(catalog.id);
            if (data.items.length) {
              this.store.importCatalogItems(data.items).then((listResultSets)=>{
                let affectedRows = this.store.db.getAffectedRows(listResultSets);
                this.$toast.add({severity:affectedRows ? "success" : "warn", summary: affectedRows ? "Erfolgreich" : "Warnung", detail:(affectedRows ? affectedRows : "Keine Neuen")+" Einträge gespeichert", life: 3000})
              }).catch((err)=>{
                this.$toast.add({severity:'error', summary: 'Fehler', detail:err, life: 3000})
              })
            }
          }
        }
      });
    },
    requestEditServiceTypesDefaultItems(serviceType) {
      this.$dialog.open(DefaultItemsDialog, {
        props: {
          header: `Standard-Bestandteile von '${serviceType.name}' bearbeiten`,
          style: {
            width: '50vw',
          },
          breakpoints:{
            '960px': '75vw',
            '640px': '90vw'
          },
          modal: true
        },
        data: {
          typeId:serviceType.id
        },
        onClose: (options) => {
          const data = options.data;
          if (data && data.items) {
            this.store.setServiceTypeDefaultItems(serviceType.id,data.items.map(item=>{return {itemLabel:(item.label||{}).label||item.label,quantity:item.quantity}})).then((listResultSets)=>{
              let affectedRows = this.store.db.getAffectedRows(listResultSets);
              this.$toast.add({severity:affectedRows ? "success" : "warn", summary: affectedRows ? "Erfolgreich" : "Warnung", detail:(affectedRows ? affectedRows : "Keine Neuen")+" Einträge gespeichert", life: 3000})
            }).catch((err)=>{
              this.$toast.add({severity:'error', summary: 'Fehler', detail:err, life: 3000})
            })
          }
        }
      });
    },
    async onCatalogsCellEditComplete(e) {
      let {data, newValue, field} = e;
      await this.store.saveCatalog(data.id,{[field]:newValue.code||newValue})
      this.displayCatalogs();
    },
    async onServiceTypesCellEditComplete(e) {
      let {data, newValue, field} = e;
      await this.store.saveServiceType(data.id,{[field]:newValue.code||newValue})
      this.displayServiceTypes();
    }
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}
td {
  vertical-align:top
}
.p-fileupload-buttonbar {
  padding: 0!important;
  background:none!important;
  border: none!important;
  margin:1rem 0;
}
.p-fileupload-content {
  display:none;
}
tr.has-payment {
  background-color:lightgreen!important;
}
tr.has-problem {
  background-color:red!important;
  color:white!important;
}
.top-bar {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.invoices .p-datatable-header {
  border-top: none!important;
}
.p-fileupload .p-fileupload-buttonbar .p-button {
  margin-right: 0!important;
}
.p-panel-header {
  padding: 0!important;
  border-top: none!important;
}
</style>