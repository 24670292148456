<template>
  <div class="flex flex-row justify-content-between align-items-center breadcrumb-container" ref="breadcrumbs">
    <Breadcrumb :home="home" :model="listCrumbs">
      <template #item="{item}">
        <span @click="$router.push(item.url||'')"><i :class="item.icon"></i>{{item.label}}</span>
      </template>
    </Breadcrumb>
  </div>
  <DataTable :value="rows" responsiveLayout="scroll" stripedRows :sortField="defaultSort.field" :sortOrder="defaultSort.order" :rowClass="rowClass" @rowClick="gotoDataset" class="invoices"
             v-model:filters="filters" filterDisplay="menu">
    <template #header>
      <div class="flex flex-column md:flex-row justify-content-between align-items-center">
        <div>
          <table>
            <tr><th style="text-align:left">Total:</th><td style="font-weight: normal">CHF {{ totalWriteoffs||0 }}.-</td></tr>
          </table>
        </div>
        <span class="p-input-icon-left mt-2 md:mt-0">
          <i class="pi pi-search" />
          <InputText v-model="filters['global'].value" placeholder="Suche" />
          <i class="pi pi-times right-0 mr-2 cursor-pointer" v-if="filters['global'].value" @click="filters['global'].value=''"/>
        </span>
      </div>
    </template>
    <Column v-for="col of columns" :field="col.field" :header="col.header" :key="col.field" :sortable="col.sortable" :hidden="col.hidden"></Column>
    <template #empty>
      Keine Einträge gefunden
    </template>
  </DataTable>
</template>

<script>
import {Bookeeper} from "@/bookeeper";
import {FilterMatchMode} from "primevue/api";
export default {
  name: 'WriteoffsView',
  components: {
  },
  data() {
    return {
      display:true,
      columns:null,
      rows:null,
      year:2022,
      store:null,
      defaultSort:{field:"id",order:1},
      totalWriteoffs:0,
      tabs: [
        {label: 'Rechnungen', to:"/invoices"},
        {label: 'Kontoauszug', to: '/payments'},
        {label: 'Abschreibungen', to: '/writeoffs'},
        {label: 'Einstellungen', to: '/settings'}
      ],
      filters:null,
      mapColumns:{
        id:"Nr",
        name:"Klient",
        description:"Monat",
        billingPeriod:"Rechnungsdatum",
        value:"Betrag",
        paymentId:null,
        warningLevel:null
      },
      home: {icon: 'pi pi-home', url: '/'},
      listCrumbs: [
        {label: 'Abschreibungen',url: '/invoices'}
      ],
    }
  },
  mounted() {
    document.documentElement.style.setProperty('--breadcrumbsHeight', `${this.$refs.breadcrumbs.clientHeight}px`);
  },
  async created() {
    this.initFilters();
    this.store = await new Bookeeper().init();
    this.displayData(await this.store.getWriteoffs());
    this.totalWriteoffs = await this.getTotalWriteoffs();
  },
  methods: {
    getColumns(data) {
      let columns = [];
      for (let key in data[0]) {
        columns.push({field:key,header:this.mapColumns[key]||key,hidden:this.mapColumns[key]===null,sortable:true});
      }
      return columns;
    },
    displayData: function(data) {
      if (data.length) {
        this.columns = this.getColumns(data);
        this.rows = data;
      }
    },
    rowClass(data) {
      if (data.paymentId)
        return "has-payment";
      else if (data.warningLevel===1)
        return "bg-yellow-300"
      else if (data.warningLevel===2)
        return "bg-orange-400 text-white"
      else if (data.warningLevel===3)
        return "has-problem";
      else if (data.warningLevel>3)
        return "bg-gray-200 text-gray-500"
    },
    async getTotalWriteoffs() {
      return await this.store.getTotalWriteoffs();
    },
    gotoDataset(event) {
      this.$router.push("/invoices/"+event.data.id);
    },
    initFilters() {
      this.filters = {
        'global': {value: null, matchMode: FilterMatchMode.CONTAINS},
        /*'matched': {operator: FilterOperator.OR, constraints: [{value: 0, matchMode: FilterMatchMode.EQUALS}]}*/
      };
    },
    clearFilters() {
      this.initFilters();
    },
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}
td {
  vertical-align:top
}
.p-fileupload-buttonbar {
  padding: 0!important;
  background:none!important;
  border: none!important;
  margin:1rem 0;
}
.p-fileupload-content {
  display:none;
}
tr.has-payment {
  background-color:lightgreen!important;
}
tr.has-problem {
  background-color:red!important;
  color:white!important;
}
.top-bar {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.invoices .p-datatable-header {
  border-top: none!important;
}
.p-fileupload .p-fileupload-buttonbar .p-button {
  margin-right: 0!important;
}
</style>