<template>
  <div v-if="loading" class="flex flex-row justify-content-center overflow-hidden"><i class="pi pi-spinner pi-spin text-8xl p-6 text-primary"></i></div>
  <div class="p-fluid grid formgrid">
    <div class="field col-12 flex flex-column justify-content-center mb-0">
      <img :src="src" alt='qr' v-if="!loading" >
      <div class="mt-3">
      <label class="mb-0">TAN</label>
      <InputText type="text" v-model="tan" class="flex-grow-1" autofocus v-on:keyup.enter="closeDialog" :disabled="sending"></InputText>
      </div>
    </div>
  </div>
  <div  v-if="!loading" class="flex flex-row mt-4 justify-content-end w-full">
    <Button label="Abbrechen" icon="pi pi-times" @click="closeDialog(false)" class="p-button-text"/>
    <Button :disabled="!tan || sending" label="Ok" :icon="icon" @click="closeDialog()" />
  </div>
</template>

<script>
import axios from "axios";
import {singletons} from "@/singletons";
export default {
  inject: ['dialogRef'],
  name: "TanDialog",
  data() {
    return {
      bank:"zkb",
      action:"download",
      loading:true,
      baseUrl:"",
      src:"",
      tan:"",
      icon:"",
      sending:false
    }
  },
  async created() {
    this.updateIcon();
    let params = this.dialogRef.data;
    if (params) {
      this.bank = params.bank || this.bank;
      this.action = params.action || this.action;
      this.baseUrl = (params.baseUrl || this.baseUrl).replace(/\/$/,"");
    }
    await this.loadQRImage();
    this.loading = false;
  },
  methods: {
    updateIcon(sending=false) {
      this.icon = sending ? 'pi pi-sync pi-spin' : 'pi pi-check';
    },
    async loadQRImage() {
      let {src} = (await axios.get(`${this.baseUrl}/banking/${this.bank}/tan`,{params:{legacyApiToken:singletons.legacyApiToken}})).data||{};
      this.src = src;
    },
    async sendTan(tan=this.tan) {
      return (await axios.post(`${this.baseUrl}/banking/${this.bank}/${this.action}`,{tan,legacyApiToken:singletons.legacyApiToken})).data||{};
    },
    async closeDialog(send=true) {
      this.updateIcon(true);
      this.sending = true;
      const data = send ? await this.sendTan() : null;
      this.updateIcon();
      this.dialogRef.close(data);
    }
  }
}
</script>

<style scoped>

</style>