<template>
  <div class="p-fluid grid formgrid">
    <div class="field col-12">
      <InputText v-model="title" :placeholder="titleLabel" v-if="titleLabel!==false" class="my-1"></InputText>
      <Editor ref="editor" v-model="val" editorStyle="height: 320px" />
    </div>
  </div>
  <div class="flex flex-row mt-4 justify-content-end w-full">
    <Button label="Abbrechen" icon="pi pi-times" @click="closeDialog(false)" class="p-button-text mr-2"/>
    <Button label="Speichern" icon="pi pi-save" @click="closeDialog(true)" autofocus />
  </div>
</template>

<script>

export default {
  inject: ['dialogRef'],
  name: "EditorDialog",
  data() {
    return {
      val:"",
      title:"",
      titleLabel:"Titel"
    }
  },
  async created() {
    let params = this.dialogRef.data;
    if (params) {
      this.val = params.val;
      this.title = params.title;
      if (params.titleLabel)
        this.titleLabel = params.titleLabel;
    }
  },
  methods: {
    closeDialog(ok) {
      this.dialogRef.close(ok ? {
        val:this.val,
        title:this.title
      } : null);
    },
  }
}
</script>

<style scoped>

</style>