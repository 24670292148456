<template>
  <div class="flex flex-row">
    <Dropdown id="selectedBillingPeriod" v-model="selectedBillingPeriod" :options="listBillingPeriods" optionLabel="name" optionValue="code" :placeholder="placeholder" @change="onChange"/>
    <Button v-if="selectedBillingPeriod" icon="pi pi-pencil" class="p-2 ml-2 mr-0 p-button-secondary" title="Rechungsperiode bearbeiten" @click="showEditBillingPeriodDialog(selectedBillingPeriod)"></Button>
    <Button icon="pi pi-plus" class="p-2 ml-2" title="Neue Rechungsperiode" @click="showAddBillingPeriodDialog"></Button>
  </div>
</template>

<script>
import BillingPeriodDialog from "@/components/BillingPeriodDialog";
import {Bookeeper} from "@/bookeeper";
export default {
  name: "BillingPeriods",
  props: {
    list:Array,
    selected:String,
    placeholder: {
      type:String,
      default: "Rechnungsperiode wählen"
    }
  },
  data() {
    return {
      listBillingPeriods:[],
      selectedBillingPeriod:null
    }
  },
  setup(props) {
    return {props}
  },
  async created() {
    this.selectedBillingPeriod = this.props.selected;
    this.listBillingPeriods = this.props.list;
    this.store = await new Bookeeper().init();
    this.refreshBillingPeriods();
  },
  methods: {
    onChange() {
      this.$emit('update:selected',this.selectedBillingPeriod)
    },
    getBillingPeriodItem(item) {
      return {...item,code:item.billingPeriod,name:item.description+" "+item.year};
    },
    async refreshBillingPeriods() {
      this.listBillingPeriods = (await this.store.getBillingPeriods()).map(item=>this.getBillingPeriodItem(item));
      this.$emit("update:list",this.listBillingPeriods);
    },
    addBillingPeriod(data) {
      let item = {
        ...data,
        code:data.billingPeriod,
        name:data.description+" "+data.year
      };
      if (this.listBillingPeriods.map(item=>item.code).indexOf(item.code)===-1) {
        this.listBillingPeriods.unshift(item);
        this.listBillingPeriods = this.listBillingPeriods.sort((a,b)=>{return a.code>b.code ? -1 : 1;})
      }
      this.selectedBillingPeriod = item.code;
      this.$emit("update:list",this.listBillingPeriods);
      this.$emit("update:selected",this.selectedBillingPeriod);
    },
    showAddBillingPeriodDialog() {
      this.$dialog.open(BillingPeriodDialog, {
        props: {
          header: 'Neue Rechnungsperiode',
          modal: true
        },
        onClose: (options) => {
          const data = options.data;
          if (data) {
            this.addBillingPeriod(data)
          }
        }
      });
    },
    showEditBillingPeriodDialog(billingPeriod) {
      let selected = this.listBillingPeriods.filter(item=>item.code===billingPeriod).map(item=>{item.billingPeriod=new Date(item.billingPeriod);return item});
      this.$dialog.open(BillingPeriodDialog, {
        props: {
          header: 'Rechnungsperiode bearbeiten',
          modal: true,
        },
        ...selected.length ? {data:selected[0]} : {},
        onClose: (options) => {
          const change = options.data;
          if (change) {
            this.store.updateBillingPeriod(billingPeriod,change).then(async (listResultSets)=>{
              this.$emit("updateData",true);
              this.selectedBillingPeriod = change.billingPeriod;
              let affectedRows = this.store.db.getAffectedRows(listResultSets);
              this.$toast.add({severity:affectedRows ? "success" : "warn", summary: affectedRows ? "Erfolgreich" : "Warnung", detail:(affectedRows ? affectedRows : "Keine ")+" Einträge geändert", life: 3000})
              if (affectedRows>0)
                await this.refreshBillingPeriods();
              else {
                let i=0;
                while (i<this.listBillingPeriods.length && this.listBillingPeriods[i].code!==billingPeriod)
                  i++;
                let item = this.getBillingPeriodItem(change);
                this.listBillingPeriods.splice(i,1,item);
                this.selectedBillingPeriod = change.billingPeriod;
              }
            }).catch((err)=>{
              this.$toast.add({severity:'error', summary: 'Fehler', detail:err, life: 3000})
            })
          }
        }
      });
    },
  }
}
</script>

<style scoped>

</style>