<template>
  <div class="p-datatable"><div class="p-datatable-header border-top-none">Lokale Datenbank</div></div>
  <div class="flex flex-row justify-content-between">
    <div class="flex flex-row align-items-start">
      <Button label="Backup" icon="pi pi-download" class="p-button-primary mt-2 ml-2 mb-2 flex-shrink-0" @click="backupDatabase"></Button>
      <FileUpload name="database[]" :customUpload="true" @uploader="restoreDatabase" :auto="true" :showUploadButton="false" :showCancelButton="false" chooseLabel="Restore" :chooseIcon="restoreIcon" class="m-2 p-button p-button-secondary">
        <template #empty></template>
      </FileUpload>
      <FileUpload name="database[]" :customUpload="true" @uploader="restoreDatabase($event,true)" :auto="true" :showUploadButton="false" :showCancelButton="false" chooseLabel="Restore (Legacy)" chooseIcon="pi pi-upload" class="m-2 p-button p-button-secondary white-space-nowrap">
        <template #empty></template>
      </FileUpload>
      <Button label="Migrate" icon="pi pi-refresh" class="p-button-warning mt-2 ml-2 mb-2 flex-shrink-0" @click="migrateDatabase"></Button>
    </div>
    <div class="flex flex-row align-items-start">
      <Button label="Settings" icon="pi pi-download" class="p-button-primary mt-2 ml-2 mb-2 flex-shrink-0" @click="backupSettings"></Button>
      <FileUpload name="database[]" :customUpload="true" @uploader="restoreSettings($event)" :auto="true" :showUploadButton="false" :showCancelButton="false" chooseLabel="Settings" chooseIcon="pi pi-upload" class="m-2 p-button p-button-secondary">
        <template #empty></template>
      </FileUpload>
      <Button label="Reset" :icon="resetIcon" class="p-button-danger m-2 flex-shrink-0" @click="resetDatabase"></Button>
    </div>
  </div>
  <div class="p-datatable"><div class="p-datatable-header border-top-none">Cloud-Datenbank</div></div>
  <div class="flex flex-row justify-content-between">
    <div class="flex flex-row align-items-start">
      <Button label="Pull" icon="pi pi-cloud-download" class="p-button-primary mt-2 ml-2 mb-2 flex-shrink-0" @click="syncPull"></Button>
      <Button label="Push" icon="pi pi-cloud-upload" class="p-button-warning mt-2 ml-2 mb-2 flex-shrink-0" @click="syncPush"></Button>
    </div>
    <div class="flex flex-row align-items-start">
      <Button label="Settings" icon="pi pi-download" class="p-button-primary mt-2 ml-2 mb-2 flex-shrink-0" @click="backupSettings"></Button>
      <FileUpload name="database[]" :customUpload="true" @uploader="restoreSettings($event)" :auto="true" :showUploadButton="false" :showCancelButton="false" chooseLabel="Settings" chooseIcon="pi pi-upload" class="m-2 p-button p-button-secondary">
        <template #empty></template>
      </FileUpload>
      <Button label="Reset" :icon="resetIcon" class="p-button-danger m-2 flex-shrink-0" @click="resetDatabase"></Button>
    </div>
  </div>
  <div class="p-datatable"><div class="p-datatable-header border-top-none mt-3">E-Mail</div></div>
  <div class="flex flex-row flex-wrap pr-2">
    <div class="field w-3 mb-1 flex-grow-1 flex flex-column mt-3 ml-2">
      <label>Host</label>
      <InputText v-model="mapSettings['mail-host']" @change="onChangeBackendSettings('mail-host')"></InputText>
    </div>
    <div class="field w-3 mb-1 flex-grow-1 flex flex-column mt-3 ml-2">
      <label>Port</label>
      <InputNumber v-model="mapSettings['mail-port']" @input="onChangeBackendSettings('mail-port',parseInt($event.value))" :use-grouping="false" ></InputNumber>
    </div>
    <div class="field w-3 mb-1 flex-grow-1 flex flex-column mt-3 ml-2">
      <label>SSL</label>
      <Dropdown v-model="mapSettings['mail-ssl']" :options="booleanSelection" optionLabel="name" placeholder="Wählen"  @change="onChangeBackendSettings('mail-ssl',$event.value.code)"/>
    </div>
  </div>
  <div class="flex flex-row flex-wrap pr-2">
    <div class="field w-3 mb-1 flex-grow-1 flex flex-column mt-3 ml-2">
      <label>Benutzer</label>
      <InputText v-model="mapSettings['mail-user']" @change="onChangeBackendSettings('mail-user')"></InputText>
    </div>
    <div class="field w-3 mb-1 flex-grow-1 flex flex-column mt-3 ml-2">
      <label>Passwort</label>
      <Password :feedback="false" v-model="mapSettings['mail-pass']" @input="onChangeBackendSettings('mail-pass',null,true)" class="flex flex-column"></Password>
    </div>
    <div class="field w-3 mb-1 flex-grow-1 flex flex-column mt-3 ml-2">
      <label>Anzeigename</label>
      <InputText v-model="mapSettings['mail-name']" @change="onChangeBackendSettings('mail-name')"></InputText>
    </div>
  </div>
  <div class="field mb-1 flex-grow-1 flex flex-column mt-3 ml-2 pr-2">
    <label for="sendMailDelay" >Sende-Verzögerung (Sekunden)</label>
    <InputNumber id="sendMailDelay" v-model="mapSettings['send-mail-delay']" @input="onChangeSettings('send-mail-delay',parseInt($event.value))" :use-grouping="false" class="mb-2"></InputNumber>
  </div>
  <div class="p-datatable"><div class="p-datatable-header border-top-none mt-3">Zahlungsinformationen</div></div>
  <div class="flex flex-row flex-wrap pr-2">
    <div class="field mb-1 flex-grow-1 flex flex-column mt-3 ml-2">
      <label>Adresse</label>
      <InputText v-model="mapSettings['creditor-name']" @change="onChangeSettings('creditor-name')" placeholder="Peter Muster"></InputText>
      <InputText v-model="mapSettings['creditor-street']" @change="onChangeSettings('creditor-street')" placeholder="Musterstrasse 1" class="mt-2"></InputText>
      <InputText v-model="mapSettings['creditor-city']" @change="onChangeSettings('creditor-city')" placeholder="8000 Musterlingen" class="mt-2"></InputText>
      <InputText v-model="mapSettings['creditor-countrycode']" @change="onChangeSettings('creditor-countrycode')" placeholder="CH" class="mt-2 mb-2"></InputText>
    </div>
    <div class="field mb-1 flex-grow-1 flex flex-column mt-3 ml-2">
      <label>IBAN</label>
      <InputText v-model="mapSettings['creditor-iban']" @change="onChangeSettings('creditor-iban')" placeholder="CH9200700114802013831" class="mb-2"></InputText>
    </div>
  </div>
  <div class="p-datatable"><div class="p-datatable-header border-top-none mt-3">E-Banking</div></div>
  <div class="flex flex-row flex-wrap pr-2">
    <div class="field w-3 mb-1 flex-grow-1 flex flex-column mt-3 ml-2">
      <label>Gateway</label>
      <Dropdown v-model="mapSettings['ebanking-gateway']" :options="gatewaySelection" optionLabel="name" placeholder="Wählen"  @change="onChangeBackendSettings('ebanking-gateway',$event.value.code)"/>
    </div>
    <div class="field w-3 mb-1 flex-grow-1 flex flex-column mt-3 ml-2">
      <label>Benutzer</label>
      <InputText v-model="mapSettings['ebanking-user']" @change="onChangeBackendSettings('ebanking-user')"></InputText>
    </div>
    <div class="field w-3 mb-1 flex-grow-1 flex flex-column mt-3 ml-2">
      <label>Passwort</label>
      <Password :feedback="false" v-model="mapSettings['ebanking-pass']" @input="onChangeBackendSettings('ebanking-pass',null,true)" class="flex flex-column"></Password>
    </div>
  </div>
  <div class="flex flex-row flex-wrap pr-2">
    <div class="field mb-1 flex-grow-1 flex flex-column mt-3 ml-2">
      <label>Start-URL</label>
      <InputText v-model="mapSettings['ebanking-home']" @change="onChangeBackendSettings('ebanking-home')"></InputText>
    </div>
  </div>
  <div class="flex flex-row flex-wrap pr-2">
    <div class="field mb-1 flex-grow-1 flex flex-column mt-3 ml-2">
      <label>Account IBAN</label>
      <InputText v-model="mapSettings['ebanking-account']" @change="onChangeBackendSettings('ebanking-account')"></InputText>
    </div>
  </div>
  <div class="p-datatable"><div class="p-datatable-header border-top-none mt-3">Kalender-Integration</div></div>
  <Button label="Verbinden" icon="pi pi-calendar" class="p-button-primary mt-2 ml-2 mb-2" @click="login()"></Button>
  <div class="p-datatable"><div class="p-datatable-header border-top-none mt-3">Erweiterte Einstellungen</div></div>
  <div class="flex flex-row flex-wrap pr-2">
    <div class="field mb-1 flex-grow-1 flex flex-column mt-3 ml-2">
      <label>Interner Server Host</label>
      <InputText v-model="mapSettings['backend-host']" @change="onChangeBackendSettings('backend-host',null,false,'base')"></InputText>
    </div>
    <div class="field mb-1 flex-grow-1 flex flex-column mt-3 ml-2">
      <label>Interner Server Port</label>
      <InputNumber v-model="mapSettings['backend-port']" @input="onChangeBackendSettings('backend-port',parseInt($event.value),false,'base')" :use-grouping="false" ></InputNumber>
    </div>
  </div>
</template>

<script>
import {Buffer} from "buffer";
import {Bookeeper} from "@/bookeeper";
import  {saveAs} from "file-saver";
import axios from "axios";
import Gapi from "@/gapi";
import AuthView from "@/components/AuthView.vue";
import {singletons} from "@/singletons";
export default {
  name: 'SettingsView',
  extends: AuthView,
  components: {
  },
  data() {
    return {
      display:true,
      columns:null,
      rows:null,
      year:2022,
      store:null,
      defaultSort:{field:"id",order:1},
      totalWriteoffs:0,
      tabs: [
        {label: 'Rechnungen', to:"/invoices"},
        {label: 'Kontoauszug', to: '/payments'},
        {label: 'Abschreibungen', to: '/writeoffs'},
        {label: 'Einstellungen', to: '/settings'}
      ],
      filters:null,
      mapColumns:{
        id:"Nr",
        name:"Klient",
        description:"Monat",
        billingPeriod:"Rechnungsdatum",
        value:"Betrag",
        paymentId:null,
        warningLevel:null
      },
      gapi:null,
      mapSettings:{},
      backendHost:"localhost",
      backendPort:3435,
      booleanSelection:[{name:'nein', code:'false'},{name:'ja', code:'true'}],
      gatewaySelection:[{name:'ZKB', code:'zkb'}, {name:'Raiffeisen', code:'raiffeisen'}],
      restoreIcon:"pi pi-upload",
      resetIcon:"pi pi-trash",
      keyId:0
    }
  },
  async created() {
    this.store = await new Bookeeper().init();
    await this.loadSettings();
    this.gapi = new Gapi();
  },
  methods: {
    async loadSettings() {
      this.mapSettings = await this.store.getSettings();
      let ssl = this.mapSettings['mail-ssl'];
      if (ssl)
        this.mapSettings['mail-ssl'] = (ssl==='true' ? this.booleanSelection[1] : this.booleanSelection[0]);
      if (this.mapSettings["backend-host"])
        this.backendHost = this.mapSettings["backend-host"];
      if (this.mapSettings["backend-port"])
        this.backendPort = this.mapSettings["backend-port"];
      let gateway = this.mapSettings['ebanking-gateway'];
      if (gateway) {
        const sel = this.gatewaySelection.filter(item=>item.code===gateway);
        this.mapSettings['ebanking-gateway'] = sel.length ? sel[0] : null;
      }
    },
    onChangeSettings(key,val=null) {
      val = val!==null ? val : this.mapSettings[key];
      this.store.saveSettings({[key]:val+""});
    },
    async onChangeBackendSettings(key,val=null,protect=false,store="store") {
      val = val!==null ? val : this.mapSettings[key];
      this.onChangeSettings(key,protect ? val.split("").map(()=>"*").join("") : val);
      try {
        await axios.post(`${this.mapSettings.apiBaseUrl}/settings/save/${store}`,{
          [key]:val,
          legacyApiToken:singletons.legacyApiToken
        }).catch((err)=>{
          this.$toast.add({severity:'error', summary: err, detail:err.response.data.response, life: 3000});
        })
      } catch (err) {
        this.$toast.add({severity:'error', summary: 'Fehler', detail:err, life: 3000});
      }
    },
    async restoreBackendSettings() {
      let settings = await this.store.getSettings();
      let requests = [{
        url:`${this.mapSettings.apiBaseUrl}/settings/save/store`,
        data:settings
      },{
        url:`${this.mapSettings.apiBaseUrl}/settings/save/base`,
        data: {
          "backend-host":settings["backend-host"],
          "backend-port":settings["backend-port"]
        }
      }]
      await Promise.all(requests.map(async r=>{
        try {
          await axios.post(r.url,{...r.data,legacyApiToken:singletons.legacyApiToken}).catch((err)=>{
            this.$toast.add({severity:'error', summary: err, detail:err.response.data.response, life: 3000});
          })
        } catch (err) {
          this.$toast.add({severity:'error', summary: 'Fehler', detail:err, life: 3000});
        }
      }));
    },
    async resetBackendSettings() {
      await axios.post(`${this.mapSettings.apiBaseUrl}/settings/reset`,{legacyApiToken:singletons.legacyApiToken})
    },
    resetDatabase() {
      this.$confirm.require({
        acceptClass:"p-button-danger",
        rejectClass:"p-button-text p-button-plain",
        acceptLabel:"Ja",
        rejectLabel:"Nein",
        message: 'Sicher? Alle Einträge werden gelöscht.',
        header: 'Datenbank zurücksetzen',
        icon: 'pi pi-exclamation-triangle',
        accept: async () => {
            this.setResetDatabaseIcon(true);
            this.store.reset().then(async ()=>{
              this.$toast.add({severity:'success', summary: 'Erfolgreich', detail:'Datenbank zurückgesetzt', life: 3000})
              this.mapSettings = {};
              this.store = await new Bookeeper().init();
              await this.loadSettings();
              await this.resetBackendSettings();
              this.setResetDatabaseIcon();
            }).catch(({code,message}) => {
              this.$toast.add({severity:'error', summary: 'Error Code '+code, detail:message, life: 3000});
            });
        },
        reject: () => {

        }
      });
    },
    async backupDatabase() {
      let dump = await this.store.dumpDatabase();
      dump.tables = dump.tables.filter(table=>table.name!=="settings");
      let now = new Date();
      let label = "cfbk"+now.getFullYear()+("0"+(now.getMonth()+1)).slice(-2)+("0"+now.getDate()).slice(-2)+("0"+now.getHours()).slice(-2)+("0"+now.getMinutes()).slice(-2)+("0"+now.getSeconds()).slice(-2)+".bk";
      saveAs(new Blob([Buffer.from(JSON.stringify(dump),"utf-8").toString("base64")],{type:"text/plain;charset=utf-8"}),label);
    },
    setRestoreDatabaseIcon(restoring=false) {
      this.restoreIcon = restoring ? "pi pi-sync pi-spin" : "pi pi-upload";
    },
    setResetDatabaseIcon(restoring=false) {
      this.resetIcon = restoring ? "pi pi-sync pi-spin" : "pi pi-trash";
    },
    async restoreDatabase(e,legacy=false) {
      const file = e.files[0];
      let dumpBase64Json = await new Promise((resolve)=>{
        const reader = new FileReader();
        reader.onload = (e)=>{resolve(e.target.result);};
        reader.readAsText(file);
      });
      let dump = {};
      try {
        dump = JSON.parse(legacy ? atob(dumpBase64Json) : Buffer.from(dumpBase64Json,"base64").toString("utf-8"));
        this.$confirm.require({
          acceptClass:"p-button-danger",
          rejectClass:"p-button-text p-button-plain",
          acceptLabel:"Ja",
          rejectLabel:"Nein",
          message: "Sicher? Datenbank wird auf "+(dump.timestamp ? "Stand von "+(new Date(dump.timestamp)) : "früheren Stand")+" zurückgesetzt.",
          header: 'Datenbank zurücksetzen',
          icon: 'pi pi-exclamation-triangle',
          accept: async () => {
            this.setRestoreDatabaseIcon(true);
            this.store.restoreDatabase(dump).then(async ()=>{
              this.$toast.add({severity:'success', summary: 'Erfolgreich', detail:'Datenbank importiert', life: 3000})
              await this.loadSettings();
              this.restoreBackendSettings();
              this.setRestoreDatabaseIcon();
            }).catch(({code,message}) => {
              this.$toast.add({severity:'error', summary: 'Error Code '+code, detail:message, life: 3000});
            });
          },
          reject: () => {

          }
        });
      } catch (err) {
        this.$toast.add({severity:'error', summary: 'File encoding error', detail:err, life: 3000});
      }
    },
    async migrateDatabase() {
      this.$confirm.require({
        acceptClass:"p-button-danger",
        rejectClass:"p-button-text p-button-plain",
        acceptLabel:"Ja",
        rejectLabel:"Nein",
        message: "Sicher?",
        header: 'Datenbank migrieren',
        icon: 'pi pi-exclamation-triangle',
        accept: async () => {
          this.store.migrateDatabase().then(async ()=>{
            this.$toast.add({severity:'success', summary: 'Erfolgreich', detail:'Datenbank migriert', life: 3000})
            await this.loadSettings();
            this.restoreBackendSettings();
          }).catch(({code,message}) => {
            this.$toast.add({severity:'error', summary: 'Error Code '+code, detail:message, life: 3000});
          });
        },
        reject: () => {
        }
      });
    },
    async backupSettings() {
      let dump = await this.store.dumpDatabase(["settings","catalogs","catalogitems","servicetypes","servicetypes_defaultitems"]);
      let now = new Date();
      let label = "settings-"+now.getFullYear()+("0"+(now.getMonth()+1)).slice(-2)+("0"+now.getDate()).slice(-2)+("0"+now.getHours()).slice(-2)+("0"+now.getMinutes()).slice(-2)+("0"+now.getSeconds()).slice(-2)+".json";
      saveAs(new Blob([JSON.stringify(dump)],{type:"text/plain;charset=utf-8"}),label);
    },
    async restoreSettings(e) {
      const file = e.files[0];
      let json = await new Promise((resolve)=>{
        const reader = new FileReader();
        reader.onload = (e)=>{resolve(e.target.result);};
        reader.readAsText(file);
      });
      try {
        let dump = JSON.parse(json);
        this.$confirm.require({
          acceptClass:"p-button-danger",
          rejectClass:"p-button-text p-button-plain",
          acceptLabel:"Ja",
          rejectLabel:"Nein",
          message: "Sicher? Einstellungen werden überschrieben. Kennwörter müssen neu eingegeben werden.",
          header: 'Einstellungen importieren',
          icon: 'pi pi-exclamation-triangle',
          accept: async () => {
            this.store.restoreDatabase(dump).then(async ()=>{
              this.$toast.add({severity:'success', summary: 'Erfolgreich', detail:'Einstellungen importiert', life: 3000})
              await this.loadSettings();
              this.restoreBackendSettings();
            }).catch(({code,message}) => {
              this.$toast.add({severity:'error', summary: 'Error Code '+code, detail:message, life: 3000});
            });
          },
          reject: () => {

          }
        });
      } catch (err) {
        this.$toast.add({severity:'error', summary: 'File encoding error', detail:err, life: 3000});
      }
    },
    async sendTestMail() {
      try {
        await axios.post(`${this.mapSettings.apiBaseUrl}/sendmail/`,{
          from:"mail@codefury.ch",
          to:["mail@staehlil.ch"],
          subject:"Test 1",
          html:"Eine <b>Testnachricht!</b>",
          legacyApiToken:singletons.legacyApiToken
        }).then((res)=>{
          console.log(res);
          this.$toast.add({severity:'success', summary: 'Erfolgreich', detail:res.data, life: 3000})
        }).catch((err)=>{
          console.log(err);
          this.$toast.add({severity:'error', summary: err, detail:err.response.data.response, life: 3000});
        })
      } catch (err) {
        console.log("catch",err);
        this.$toast.add({severity:'error', summary: 'Fehler', detail:err, life: 3000});
      }
    },
    async login() {
      let {gapi,tokenClient} = await this.gapi.init();

      tokenClient.callback = async (resp) => {
        if (resp.error !== undefined) {
          throw (resp);
        }
        await this.listCalendars();
      };
      console.log(JSON.stringify({tokenClient}))
      let token = gapi.client.getToken();
      console.log(token);
      if (token === null) {
        // Prompt the user to select a Google Account and ask for consent to share their data
        // when establishing a new session.
        tokenClient.requestAccessToken({prompt: 'consent'})
      } else if (!(token.expires_in>0)) {
        // Skip display of account chooser and consent dialog for an existing session.
        tokenClient.requestAccessToken({prompt: ''});
      }
      else {
        await this.listCalendars();
      }
    },
    async listCalendars() {
      let response;
      try {
        response = await window.gapi.client.calendar.calendarList.list();
      } catch (err) {
        document.getElementById('content').innerText = err.message;
        return;
      }
      const calendars = response.result.items;
      if (!calendars || calendars.length == 0) {
        document.getElementById('content').innerText = 'No calendars found.';
        return null;
      }
      console.log(JSON.stringify(calendars.map(item=>{return {summary:item.summary, id:item.id}})));
      return calendars;
    },
    async syncPull() {
      this.$confirm.require({
        acceptClass:"p-button-danger",
        rejectClass:"p-button-text p-button-plain",
        acceptLabel:"Ja",
        rejectLabel:"Nein",
        message: "Sicher?",
        header: 'Externe Datenbank importieren',
        icon: 'pi pi-exclamation-triangle',
        accept: async () => {
          let savestate = await this.store.getRemoteSaveState();
          this.store.restoreDatabaseFromSaveState(savestate).then(async ()=>{
            this.$toast.add({severity:'success', summary: 'Erfolgreich', detail:'Datenbank importiert', life: 3000});
            dispatchEvent(new CustomEvent("login",{}));
          }).catch(({code,message}) => {
            this.$toast.add({severity:'error', summary: 'Error Code '+code, detail:message, life: 3000});
          });
        },
        reject: () => {
        }
      });
    },
    async syncPush() {
      this.$confirm.require({
        acceptClass:"p-button-danger",
        rejectClass:"p-button-text p-button-plain",
        acceptLabel:"Ja",
        rejectLabel:"Nein",
        message: "Sicher?",
        header: 'Lokale Datenbank exportieren',
        icon: 'pi pi-exclamation-triangle',
        accept: async () => {
          this.store.saveDatabase().then(async ()=>{
            this.$toast.add({severity:'success', summary: 'Erfolgreich', detail:'Datenbank exportiert', life: 3000});
            dispatchEvent(new CustomEvent("login",{}));
          }).catch(({code,message}) => {
            this.$toast.add({severity:'error', summary: 'Error Code '+code, detail:message, life: 3000});
          });
        },
        reject: () => {
        }
      });
    }
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}
td {
  vertical-align:top
}
.p-fileupload-buttonbar {
  padding: 0!important;
  background:none!important;
  border: none!important;
  margin:1rem 0;
}
.p-fileupload-content {
  display:none;
}
tr.has-payment {
  background-color:lightgreen!important;
}
tr.has-problem {
  background-color:red!important;
  color:white!important;
}
.top-bar {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.invoices .p-datatable-header {
  border-top: none!important;
}
.p-fileupload .p-fileupload-buttonbar .p-button {
  margin-right: 0!important;
}
.p-panel-header {
  padding: 0!important;
  border-top: none!important;
}
</style>