<template>
  <div class="flex flex-column">
    <div class="flex flex-row my-1" v-for="(item,ix) in items" v-bind:key="ix">
      <InputText v-model="item.label" placeholder="Bezeichnung"></InputText>
      <InputText v-model="item.description" class="ml-1 flex-grow-1" placeholder="Beschrieb"></InputText>
      <Button icon="pi pi-trash" class="p-2 ml-1 p-button-secondary" title="Löschen" @click="removeItem(ix)"></Button>
    </div>
    <div class="flex flex-row mt-1">
      <Button icon="pi pi-plus" class="p-2" title="Neu" @click="addItem"></Button>
      <FileUpload name="dates[]" :customUpload="true" @uploader="handleUpload" :auto="true" :showUploadButton="false" :showCancelButton="false" chooseLabel="Import" :chooseIcon="uploadIcon" class="ml-1 p-button-secondary">
        <template #empty></template>
      </FileUpload>
    </div>
  </div>
  <div class="flex flex-row mt-4 justify-content-end w-full">
    <Button label="Abbrechen" icon="pi pi-times" @click="closeDialog(false)" class="p-button-text"/>
    <Button label="Speichern" icon="pi pi-save" @click="closeDialog(true)" autofocus class="ml-2" />
  </div>
  <Dialog v-model:visible="displaySelectSheets" :modal="true" :close-on-escape="true" :draggable="false" :breakpoints="{'960px': '75vw', '640px': '95vw'}" :style="{width: '50vw', 'max-width':'1000px'}">
    <template #header>
      <h3>Einträge aus Datei importieren</h3>
    </template>
    <Listbox v-model="selectedSheets" :options="listSheets" optionLabel="name" :multiple="true"/>
    <template #footer>
      <div class="flex flex-row mt-4 justify-content-end">
        <Button label="Abbrechen" icon="pi pi-times" @click="hideSelectSheets" class="p-button-text"/>
        <Button label="Import" icon="pi pi-check" @click="importSelectedSheets" autofocus />
      </div>
    </template>
  </Dialog>
</template>

<script>
import {Bookeeper} from "@/bookeeper";
import * as xlsx from "xlsx";

export default {
  inject: ['dialogRef'],
  name: "CatalogItemsDialog",
  data() {
    return {
      catalogId:0,
      items:[],
      uploadIcon:"pi pi-upload",
      displaySelectSheets:false,
      selectedSheets:[],
      workbook:null,
      listSheets:[]
    }
  },
  async created() {
    let params = this.dialogRef.data;
    if (params) {
      this.catalogId = params.catalogId;
    }
    this.store = await new Bookeeper().init();
    this.items = await this.store.getCatalogItems(this.catalogId);
  },
  methods: {
    addItem() {
      this.items.push({label:"",description:"",catalogId:this.catalogId});
    },
    removeItem(ix) {
      this.items.splice(ix,1);
    },
    closeDialog(ok) {
      this.dialogRef.close(ok ? {
        items:this.items
      } : null);
    },
    async handleUpload(e) {
      this.selectedSheets = [];
      this.updateUploadIcon(true);
      const file = e.files[0];
      const buffer = await file.arrayBuffer();
      const workbook = xlsx.read(buffer);
      this.workbook = workbook;
      this.listSheets = workbook.SheetNames.map(name=>{return {name}});
      this.displaySelectSheets = true;
      this.updateUploadIcon(false);
    },
    async importSelectedSheets() {
      this.hideSelectSheets();
      let map = {};
      this.items.forEach(item=>{
        map[item.label] = item;
      });
      this.selectedSheets.forEach(sheet=>{
        let worksheet = this.workbook.Sheets[sheet.name]
        let data = xlsx.utils.sheet_to_json(worksheet);
        data.forEach(set=>{
          if (set.label)
            map[set.label] = {catalogId:this.catalogId,label:set.label,description:set.description||""};
        });
      });
      let items = [];
      for (let name in map) {
        if (Object.prototype.hasOwnProperty.call(map,name))
          items.push(map[name]);
      }
      this.items = items;
    },
    updateUploadIcon(uploading=false) {
      this.uploadIcon = uploading ? "pi pi-sync pi-spin" : "pi pi-upload";
    },
    hideSelectSheets() {
      this.displaySelectSheets = false;
    },
  }
}
</script>

<style scoped>

</style>