<template>
  <div class="flex flex-row justify-content-between align-items-center breadcrumb-container" ref="breadcrumbs">
    <Breadcrumb :home="home" :model="listCrumbs">
      <template #item="{item}">
        <span @click="$router.push(item.url||'')"><i :class="item.icon"></i>{{item.label}}</span>
      </template>
    </Breadcrumb>
    <div class="mx-2 flex flex-row">
      <Button label="Klient" icon="pi pi-plus" @click="addClient()" class="mr-2"></Button>
      <FileUpload name="dates[]" :customUpload="true" @uploader="handleClientsUpload" @select="updateClientsUploadIcon(true)" :auto="true" :showUploadButton="false" :showCancelButton="false" chooseLabel="Klienten" :chooseIcon="clientsUploadIcon">
        <template #empty></template>
      </FileUpload>
    </div>
  </div>
  <DataTable :value="rows" responsiveLayout="scroll" stripedRows :sortField="defaultSort.field" :sortOrder="defaultSort.order" :rowClass="rowClass" class="invoices"
             v-model:filters="filters" filterDisplay="menu" v-model:selection="selectedClients" @row-select="onSelectionChange" @row-unselect="onSelectionChange" @row-select-all="onSelectionChange" @row-unselect-all="onSelectionChange"
             stateStorage="session" stateKey="dt-state-all"
             v-model:expandedRows="expandedRows" @rowExpand="onRowExpand"
             :paginator="true" :rows="50" :rowsPerPageOptions="[50,100,(rows||[]).length-((rows||[]).length%100)+100]" :page-link-size="5">
    <template #header>
      <div class="flex flex-column md:flex-row justify-content-between align-items-center">
        <div>
          <table>
            <tr><th style="text-align:left">Anzahl Einträge:</th><td style="font-weight: normal">{{ (rows||[]).length }}</td></tr>
            <tr><th style="text-align:left">&nbsp;</th><td>&nbsp;</td></tr>
          </table>
        </div>
        <span class="p-input-icon-left mt-2 md:mt-0">
          <i class="pi pi-search" />
          <InputText v-model="filters['global'].value" placeholder="Suche" />
          <i class="pi pi-times right-0 mr-2 cursor-pointer" v-if="filters['global'].value" @click="filters['global'].value=''"/>
        </span>
        <div class="flex flex-column md:flex-row justify-content-center align-items-center">
          <!--<Button type="button" icon="pi pi-google" label="Import"></Button>-->
          <div class="flex flex-row align-items-center justify-content-end mt-2 md:mt-0">
            <SelectButton v-model="selectedFilters" :options="listFilters" optionLabel="name" @change="saveFilters" :multiple="true"/>
          </div>
        </div>
      </div>
    </template>
    <Column selectionMode="multiple"></Column>
    <Column :expander="true" headerStyle="" />
    <Column v-for="col of columns" :field="col.field" :header="col.header" :key="col.field" :sortable="col.sortable" :hidden="col.hidden"></Column>
    <Column bodyStyle="text-align: right">
      <template #body="slotProps">
        <div class="flex flex-row justify-content-end">
          <Button icon="pi pi-arrow-right" class="p-button-rounded p-button-plain p-button-text p-button-lg" @click="gotoDataset(slotProps.data)"/>
        </div>
      </template>
    </Column>
    <template #empty>
      Keine Einträge gefunden
    </template>
    <template #loading>
    </template>
    <template #expansion="slotProps">
      <div class="services-subtable">
        <DataTable :value="slotProps.data.services" responsiveLayout="scroll">
          <Column v-for="col of servicesColumns" :field="col.field" :header="col.header" :key="col.field" :hidden="col.hidden" headerClass="bg-primary text-white"></Column>
        </DataTable>
      </div>
    </template>
  </DataTable>
  <Dialog v-model:visible="displaySelectDateSheets" :modal="true" :close-on-escape="true" :draggable="false" :breakpoints="{'960px': '75vw', '640px': '95vw'}" :style="{width: '50vw', 'max-width':'1000px'}">
    <template #header>
      <h3>Klienten aus Datei importieren</h3>
    </template>
    <Listbox v-model="selectedDateSheets" :options="listDateSheets" optionLabel="name" :multiple="true"/>
    <template #footer>
      <div class="flex flex-row mt-4 justify-content-end">
        <Button label="Abbrechen" icon="pi pi-times" @click="hideSelectClientSheets" class="p-button-text"/>
        <Button label="Import" icon="pi pi-check" @click="importSelectedClientSheets" autofocus />
      </div>
    </template>
  </Dialog>
  <Sidebar v-model:visible="sidebarVisible" position="bottom" :modal="false" :showCloseIcon="false" :class="'toolbar'">
    <div class="flex flex-row justify-content-end"><Button type="button" class="p-button-danger m-2" icon="pi pi-trash" label="Löschen" v-if="(selectedClients||[]).length" @click="deleteSelectedClients"></Button></div>
  </Sidebar>
</template>

<script>
import {Bookeeper} from "@/bookeeper";
import {FilterMatchMode} from "primevue/api";
import * as xlsx from "xlsx";
import {appState} from "@/reactive";
import AuthView from "@/components/AuthView.vue";
export default {
  name: 'ClientsView',
  extends: AuthView,
  components: {
  },
  data() {
    return {
      display:true,
      columns:null,
      rows:null,
      year:2022,
      store:null,
      defaultSort:{field:"id",order:1},
      totalDue:0,
      totalPaid:0,
      filters:null,
      mapColumns:{
        id:"Nr",
        name:"Name",
        givenName:"Vorname",
        description:"Monat",
        billingPeriod:"Rechnungsdatum",
        value:"Betrag",
        paymentId:null,
        warningLevel:"Mahnstufe",
        year:"Jahr",
        gender:null,
        alias:null,
        address:null,
        address1:"Ort",
        email:"E-Mail",
        salutation:"Anrede",
        invoiceTotal:"Rechnungstotal",
        maxRate:null,
        minRate:null,
        birthdate:null,
        rate:null,
        ahv:null,
        cr_name:null,
        cr_givenName:null,
        cr_email:null,
        cr_address:null,
        cr_address1:null,
        cr_salutation:null,
        veka_nr:null,
        versi_nr:null,
        kanton:null,
        comp_mode:null,
        countServices:"Sitzungen" /* "Sitzungen" / null todo: make settings to toggle display */
      },
      hideMatched:false,
      loading:false,
      displaySelectDateSheets:false,
      selectedDateSheets:null,
      listDateSheets:[],
      clientsUploadIcon:"",
      datesWorkbook:null,
      selectedClients:null,
      expandedRows:null,
      servicesColumns:[],
      mapServicesColumns:{
        id:"Nr",
        clientId:null,
        typeId:null,
        date:"Datum",
        billingPeriod:null,
        rate:null,
        quantity:"Anzahl",
        type:"Leistungsart",
        importedId:null,
        description:null,
        case_nr:null,
        case_date:null,
        law:null,
        treatment_type:null,
        treatment_cause:null,
        referrer:null,
        diagnose:null,
        remarks:null,
        value:"Betrag"
      },
      tableKey:0,
      home: {icon: 'pi pi-home', url: '/'},
      listCrumbs: [
        {label: 'Klienten',url: '/clients'}
      ],
      sidebarVisible:false,
      listFilters:[
        {name:"Aktiv",key:"hideInactive"},
        {name:"Neu",key:"hideOld"}
      ],
      selectedFilters:[],
    }
  },
  mounted() {
    document.documentElement.style.setProperty('--breadcrumbsHeight', `${this.$refs.breadcrumbs.clientHeight}px`);
  },
  async created() {
    this.hideMatched = this.$storage.getStorageSync("hideMatched");
    this.updateClientsUploadIcon();
    this.loading = true;
    this.sidebarVisible = false;
    this.initFilters();
    this.store = await new Bookeeper({onDataChange:()=>{}}).init();
    await this.displayData();
    this.onSelectionChange();
  },
  methods: {
    hasFilter(key) {
      return this.selectedFilters.filter(item=>item.key===key).length>0;
    },
    loadFilters() {
      this.selectedFilters.splice(0,this.selectedFilters.length);
      this.listFilters.forEach(filter=>{
        if (this.$storage.getStorageSync(filter.key))
          this.selectedFilters.push(filter);
      });
    },
    saveFilters() {
      this.listFilters.forEach(filter=>{
        this.$storage.setStorageSync(filter.key,this.hasFilter(filter.key));
      });
      this.displayData();
    },
    async importSelectedClientSheets() {
      this.hideSelectClientSheets();
      let map = {};
      this.selectedDateSheets.forEach(sheet=>{
        let worksheet = this.datesWorkbook.Sheets[sheet.name]
        let data = xlsx.utils.sheet_to_json(worksheet);
        data.forEach(set=>{
          let name = set["Name"]
          if (name) {
            map[name] = map[name] || {};
            map[name].ext = set;
          }
        });
      });
      let all = [];
      for (let name in map) {
        if (Object.prototype.hasOwnProperty.call(map,name)) {
          all.push({Name:name,...map[name]});
        }
      }
      this.store.importClients(all).then(({listResultSets})=>{
        this.displayData();
        let affectedRows = this.store.db.getAffectedRows(listResultSets);
        this.$toast.add({severity:affectedRows ? "success" : "warn", summary: affectedRows ? "Erfolgreich" : "Warnung", detail:(affectedRows ? affectedRows : "Keine Neuen")+" Einträge importiert", life: 3000})
      }).catch((err)=>{
        this.$toast.add({severity:'error', summary: 'Fehler', detail:err, life: 3000})
        this.displayData();
      })
    },
    hideSelectClientSheets() {
      this.displaySelectDateSheets = false;
    },
    updateClientsUploadIcon(uploading=false) {
      this.clientsUploadIcon = uploading ? "pi pi-sync pi-spin" : "pi pi-upload";
    },
    async handleClientsUpload(e) {
      this.selectedDateSheets = [];
      const file = e.files[0];
      const buffer = await file.arrayBuffer();
      const workbook = xlsx.read(buffer);
      this.datesWorkbook = workbook;
      this.listDateSheets = workbook.SheetNames.map(name=>{return {name,importName:name,importYear:new Date().getFullYear()}});
      this.displaySelectDateSheets = true;
      this.updateClientsUploadIcon(false);
    },
    getColumns(data,mapColumns=this.mapColumns) {
      let columns = [];
      for (let key in data[0]) {
        columns.push({field:key,header:mapColumns[key]||key,hidden:mapColumns[key]===null,sortable:true});
      }
      return columns;
    },
    displayData: async function() {
      this.loading=true;
      let data = await this.store.getClients(false,this.hasFilter("hideInactive"),this.hasFilter("hideOld"));
      if (data.length) {
        this.columns = this.getColumns(data);
        this.rows = data;
      }
      else
        this.rows = [];
      this.loading = false;
    },
    rowClass(data) {
      if (data.paymentId)
        return "has-payment";
      else if (data.warningLevel===1)
        return "bg-yellow-300"
      else if (data.warningLevel===2)
        return "bg-orange-400 text-white"
      else if (data.warningLevel===3)
        return "has-problem";
      else if (data.warningLevel>3)
        return "bg-gray-200 text-gray-500"
    },
    gotoDataset({id}) {
      appState.resetCache(`/clients/${id}`);
      this.$router.push(`/clients/${id}`);
    },
    initFilters() {
      this.filters = {
        'global': {value: null, matchMode: FilterMatchMode.CONTAINS},
        /*'matched': {operator: FilterOperator.OR, constraints: [{value: 0, matchMode: FilterMatchMode.EQUALS}]}*/
      };
    },
    clearFilters() {
      this.initFilters();
    },
    async deleteSelectedInvoices() {
      await this.store.deleteInvoices(this.selectedInvoices.filter(item=>!item.paymentId).map(item=>item.id));
      this.displayData();
    },
    async onRowExpand(e) {
      let targetId = e.data.id;
      let i=0;
      while (i<this.rows.length && this.rows[i].id!==targetId)
        i++;
      if (i<this.rows.length) {
        let services = await this.store.getServicesForClient(targetId);
        this.rows[i].services = services;
        if (services.length) {
          this.servicesColumns = this.getColumns(services,this.mapServicesColumns);
        }
      }
    },
    async addClient() {
      let inserted = await this.store.addClient();
      if (inserted.length && inserted[0].insertId) {
        this.gotoDataset({id:inserted[0].insertId})
        appState.resetCache("/clients");
      }
    },
    async deleteSelectedClients() {
      this.$confirm.require({
        acceptClass:"p-button-danger",
        rejectClass:"p-button-text p-button-plain",
        acceptLabel:"Ja",
        rejectLabel:"Nein",
        message: 'Sicher?',
        header: 'Klient'+(this.selectedClients.length>1 ? 'en' : '')+' löschen',
        icon: 'pi pi-exclamation-triangle',
        accept: async () => {
          let deleteCount = this.selectedClients.length;
          this.store.deleteClients(this.selectedClients.map(item=>item.id)).then((listResultSets)=>{
            let affectedRows = this.store.db.getAffectedRows(listResultSets);
            this.$toast.add({severity:affectedRows>0 && affectedRows===deleteCount ? "success" : "warn", summary: affectedRows>0 && affectedRows===deleteCount ? "Erfolgreich" : "Warnung", detail:(affectedRows>0 ? affectedRows : "Keine")+" "+(affectedRows==1 ? "Eintrag" : "Einträge")+" gelöscht", life: 3000})
            this.displayData();
            this.selectedClients = null;
          }).catch(({code,message}) => {
            this.$toast.add({severity:'error', summary: 'Error Code '+code, detail:message, life: 3000});
          });
        },
        reject: () => {

        }
      });
    },
    onSelectionChange() {
      setTimeout(()=>{this.sidebarVisible = !!(this.selectedClients && this.selectedClients.length)});
    }
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}
td {
  vertical-align:top
}
.p-fileupload-buttonbar {
  padding: 0!important;
  background:none!important;
  border: none!important;
  margin:1rem 0;
}
.p-fileupload-content {
  display:none;
}
tr.has-payment {
  background-color:lightgreen!important;
}
tr.has-problem {
  background-color:red!important;
  color:white!important;
}
.top-bar {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.invoices .p-datatable-header {
  border-top: none!important;
}
.p-fileupload .p-fileupload-buttonbar .p-button {
  margin-right: 0!important;
}
.p-datatable.invoices tr>td {
  cursor:pointer;
  vertical-align: middle;
}
.p-speeddial-direction-down {
  right: 0;
  top: 0;
  z-index: 1011!important;
}
.p-sidebar.toolbar .p-sidebar-header {
  display: none;
}
.p-sidebar-bottom.toolbar {
  height: auto;
}
.p-sidebar.toolbar .p-sidebar-content {
  padding: 0;
}
</style>