<template>
  <div class="flex flex-column">
    <div class="flex flex-row mb-1" v-for="(item,ix) in items" v-bind:key="ix">
      <AutoComplete v-model="item.label" :suggestions="filteredCatalogItems" @complete="searchCatalogItems($event)" class="flex-grow-1" field="label" @item-select="item.taxRate=item.label.taxRate">
        <template #item="slotProps">
          <div>{{slotProps.item.label}}<br><small>{{slotProps.item.description}}</small></div>
        </template>
      </AutoComplete>
      <InputNumber :use-grouping="false" v-model="item.quantity" class="ml-1 flex-grow-1"></InputNumber>
      <InputNumber :use-grouping="false" v-model="item.taxRate" class="ml-1 flex-grow-1" mode="currency" currency="CHF" locale="de-CH" :disabled="!editRate"></InputNumber>
      <Button icon="pi pi-trash" class="p-2 ml-1 p-button-secondary" title="Löschen" @click="removeItem(ix)"></Button>
    </div>
    <Button icon="pi pi-plus" class="p-2" title="Neu" @click="addItem"></Button>
  </div>
</template>

<script>
import {Bookeeper} from "@/bookeeper";

export default {
  name: "ItemsEditor",
  props: {
    list:Array,
    serviceType:Object,
    editRate:{
      type:Boolean,
      default:true
    }
  },
  watch: {
    serviceType:function(newVal) {
      this.initList(newVal);
    }
  },
  data() {
    return {
      items:[],
      filteredCatalogItems:[],
    }
  },
  setup(props) {
    return {props}
  },
  async created() {
    this.store = await new Bookeeper().init();
    this.items = this.props.list||[];
    this.$emit("update:list",this.items);
  },
  methods: {
    addItem() {
      let template = {...this.items.length ? this.items[0] : {},label:"",quantity:0};
      delete template.id;
      this.items.push(template);
      this.$emit("update:list",this.items);
    },
    removeItem(ix) {
      this.items.splice(ix,1);
      this.$emit("update:list",this.items);
    },
    async initList(serviceType) {
      let typeId = serviceType.code || 0;
      this.items = await this.store.getServiceTypeDefaultItems(typeId)
      this.$emit("update:list",this.items);
    },
    async searchCatalogItems({query}) {
      this.filteredCatalogItems = (await this.store.getCatalogItems(false,query));
    }
  }
}
</script>

<style scoped>

</style>