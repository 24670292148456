<template>
  <div class="p-fluid grid formgrid">
    <div class="field col-12">
      <label class="mb-0">Alias</label>
      <InputText disabled type="text" v-model="alias" class="flex-grow-1"></InputText>
      <label class="mb-0 mt-3">Name</label>
      <InputText type="text" v-model="name" class="flex-grow-1"></InputText>
      <label class="mb-0 mt-3">Satz</label>
      <InputText type="text" v-model="rate" class="flex-grow-1"></InputText>
    </div>
  </div>
  <div class="flex flex-row mt-4 justify-content-end w-full">
    <Button label="Abbrechen" icon="pi pi-times" @click="closeDialog(false)" class="p-button-text"/>
    <Button :disabled="!(alias && name) || exists(name)" label="Ok" icon="pi pi-check" @click="closeDialog(true)" autofocus />
  </div>
</template>

<script>
import {Bookeeper} from "@/bookeeper";

export default {
  inject: ['dialogRef'],
  name: "NewClientDialog",
  data() {
    return {
      alias:"",
      name:"",
      rate:0,
      listClientNames:[]
    }
  },
  async created() {
    let params = this.dialogRef.data;
    if (params)
      this.alias = params.alias;
    this.store = await new Bookeeper().init();
    this.listClientNames = (await this.store.getClients()).map(client=>client.name);
  },
  methods: {
    closeDialog(ok) {
      this.dialogRef.close(ok ? {
        alias:this.alias,
        name:this.name,
        rate:this.rate
      } : null);
    },
    exists(name) {
      return this.listClientNames.indexOf(name)>-1;
    }
  }
}
</script>

<style scoped>

</style>