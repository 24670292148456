import {createRouter, createWebHashHistory, createWebHistory} from "vue-router";
import InvoicesView from "@/components/InvoicesView";
import PaymentsView from "@/components/PaymentsView";
import PaymentView from "@/components/PaymentView";
import InvoiceView from "@/components/InvoiceView";
import WriteoffsView from "@/components/WriteoffsView";
import SettingsView from "@/components/SettingsView";
import ClientsView from "@/components/ClientsView";
import ClientView from "@/components/ClientView";
import TemplatesView from "@/components/TemplatesView";
import LoginView from "@/components/LoginView.vue";
import LoginExtView from "@/components/LoginExtView.vue";

const routes = [
    {
        path: '/',
        redirect: '/invoices'
    },
    {
        path: '/invoices',
        component: InvoicesView,
        props:true
    },
    {
        path: '/invoices/:id',
        component: InvoiceView,
        props: true
    },
    {
        path: '/clients',
        component: ClientsView
    },
    {
        path: '/clients/:id',
        component: ClientView,
        props: true
    },
    {
        path: '/payments',
        component: PaymentsView,
    },
    {
        path: '/payments/:id',
        component: PaymentView,
        props: true
    },
    {
        path: '/writeoffs',
        component: WriteoffsView,
    },
    {
        path: '/templates',
        component: TemplatesView,
    },
    {
        path: '/settings',
        component: SettingsView,
    },
    {
        path: '/login',
        component: LoginView
    },
    {
        path: '/auth',
        component: LoginExtView,
        props: true
    },
    /*  {
        path: '/games/:category',
        component: Games
      },
      {
        path: '/games/:id/:session',
        component: Games
      },
      {
        path: '/friends',
        name: 'Freunde',
        component: Friends
      },
      {
        path: '/friends/:userId',
        component: Friend,
        props:true
      }*/
]

const router = createRouter({
    history: process.env.IS_ELECTRON ? createWebHashHistory() : createWebHistory(process.env.BASE_URL),
    routes
})

export default router
