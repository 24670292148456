<template>
  <div class="p-fluid grid formgrid">
    <div class="field col-12">
      <label class="mb-0">Datum</label>
      <Calendar v-model="billingPeriod" dateFormat="yy-mm-dd" />
      <label class="mb-0 mt-3">Bezeichnung/Monat</label>
      <InputText type="text" v-model="description" class="flex-grow-1"></InputText>
      <label class="mb-0 mt-3">Jahr</label>
      <InputText type="text" v-model="year" class="flex-grow-1"></InputText>
    </div>
  </div>
  <div class="flex flex-row mt-4 justify-content-end w-full">
    <Button label="Abbrechen" icon="pi pi-times" @click="closeDialog(false)" class="p-button-text"/>
    <Button :disabled="!(billingPeriod && description && year)" label="Ok" icon="pi pi-check" @click="closeDialog(true)" autofocus />
  </div>
</template>

<script>
export default {
  inject: ['dialogRef'],
  name: "BillingPeriodDialog",
  data() {
    return {
      billingPeriod:new Date(),
      description:"",
      year:new Date().getFullYear()
    }
  },
  created() {
    let params = this.dialogRef.data;
    if (params) {
      this.billingPeriod = params.billingPeriod;
      this.description = params.description;
      this.year = params.year;
    }
  },
  methods: {
    closeDialog(ok) {
      this.dialogRef.close(ok ? {
        billingPeriod:new Date(this.billingPeriod.getTime()+12*3600*1000).toISOString().substr(0,10),
        description:this.description,
        year:this.year
      } : null);
    }
  }
}
</script>

<style scoped>

</style>