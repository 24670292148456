import axios from "axios";
import SqliteWasmDatabase from "@/db-wasm";
import {singletons} from "@/singletons";
import {Buffer} from "buffer";
import {appState} from "@/reactive";

export class Bookeeper {
    db;
    onDataChange;
    constructor(args={}) {
        if (args.onDataChange)
            this.onDataChange = args.onDataChange;
    }
    static mapMatchMonths = {
        jan:"Januar",
        feb:"Februar",
        mrz:"März",
        mär:"März",
        mae:"März",
        apr:"April",
        mai:"Mai",
        jun:"Juni",
        jul:"Juli",
        aug:"August",
        sep:"September",
        okt:"Oktober",
        nov:"November",
        dez:"Dezember"
    };
    static Months = ["Januar","Februar","März","April","Mai","Juni","Juli","August","September","Oktober","November","Dezember"];
/*    static defaults = {
        servicetypes:[{name:"Psychotherapie",internalName:"Psychotherapie",id:1},{name:"Psychotherapie",internalName:"Psychotherapie ALT",id:2},{name:"Bericht",internalName:"Bericht",id:3},{name:"Selbsterfahrung",internalName:"Selbsterfahrung",id:4},{name:"Coaching",internalName:"Coaching",id:5},{name:"Supervision",internalName:"Supervision",id:6}],
        catalogs:[{name:"Tarmed", taxUnit:1, taxRate: 2.58, id:1},{name:"IV", taxUnit:15, taxRate: 35.50, id:2}]
    };*/
    static defaults = {
        settings:[
            {
                "_key": "mail-ssl",
                "val": "false"
            },
            {
                "_key": "mail-port",
                "val": "587"
            },
            {
                "_key": "backend-host",
                "val": "localhost"
            },
            {
                "_key": "backend-port",
                "val": "3435"
            },
            {
                "_key": "send-mail-delay",
                "val": "2"
            },
            {
                "_key": "ebanking-gateway",
                "val": "zkb"
            },
            {
                "_key": "mail-host",
                "val": "mail.codefury.ch"
            },
            {
                "_key": "mail-user",
                "val": "test.meine-klienten@codefury.ch"
            },
            {
                "_key": "mail-pass",
                "val": "*************"
            },
            {
                "_key": "mail-name",
                "val": "Meine Klienten (Test)"
            },
            {
                "_key": "creditor-countrycode",
                "val": ""
            },
            {
                "_key": "creditor-street",
                "val": ""
            },
            {
                "_key": "creditor-name",
                "val": ""
            },
            {
                "_key": "creditor-city",
                "val": ""
            },
            {
                "_key": "creditor-iban",
                "val": "CH02 0070 0110 0003 8789 6"
            },
            {
                "_key": "ebanking-pass",
                "val": ""
            },
            {
                "_key": "ebanking-home",
                "val": ""
            },
            {
                "_key": "ebanking-account",
                "val": ""
            },
            {
                "_key": "ebanking-user",
                "val": ""
            },
            {
                "_key": "invoice-level-2",
                "val": "<p>Guten Tag ${name}</p><p><br></p><p>In der Anlage befindet sich Ihre Rechnung ${description} ${year} (2. Mahnung).</p><p><br></p><p>Freundliche Grüsse</p><p><br></p><p>***</p><p>SIGNATUR</p>"
            },
            {
                "_key": "invoice-subject-level-2",
                "val": "Rechnung ${description} ${year}: 2. Mahnung"
            },
            {
                "_key": "invoice-level-1",
                "val": "<p>Guten Tag ${name}</p><p><br></p><p>In der Anlage befindet sich Ihre Rechnung ${description} ${year} (1. Mahnung).</p><p><br></p><p>Freundliche Grüsse</p><p><br></p><p>***</p><p>SIGNATUR</p>"
            },
            {
                "_key": "invoice-subject-level-1",
                "val": "Rechnung ${description} ${year}: 1. Mahnung"
            },
            {
                "_key": "invoice-level-0",
                "val": "<p>Guten Tag ${name}</p><p><br></p><p>In der Anlage befindet sich Ihre Rechnung ${description} ${year}.</p><p><br></p><p>Freundliche Grüsse</p><p><br></p><p>***</p><p>SIGNATUR</p>"
            },
            {
                "_key": "invoice-subject-level-0",
                "val": "Rechnung ${description} ${year}"
            }
        ],
        catalogs:[
            {
                "id": 1,
                "name": "Tarif 590",
                "taxUnit": 1,
                "taxRate": 11
            }
        ],
        catalogitems:[
            {
                "label": "1052",
                "description": "Fussreflexzonen-Therapie pro 5 Min.",
                "catalogId": 1
            },
            {
                "label": "1058",
                "description": "Hydrotherapie, pro 5 Minuten",
                "catalogId": 1
            },
            {
                "label": "1062",
                "description": "Klassische Massage inkl. Colon-Massage, pro 5 Min.",
                "catalogId": 1
            },
            {
                "label": "1073",
                "description": "Muskelreflexzonenmassage, pro 5 Min.",
                "catalogId": 1
            },
            {
                "label": "1085",
                "description": "Phytotherapie, pro 5 Min.",
                "catalogId": 1
            },
            {
                "label": "1134",
                "description": "Reflexzonentherapie, pro 5 Min.",
                "catalogId": 1
            },
            {
                "label": "1146",
                "description": "Einwirkzeit im Rahmen einer Behandlung, pro 5 Min.",
                "catalogId": 1
            },
            {
                "label": "1200",
                "description": "Anamnese/Untersuchung/Diagnostik/Befunderhebung, pro 5 Min.",
                "catalogId": 1
            },
            {
                "label": "1205",
                "description": "Ausleitende Verfahren, pro 5 Min.",
                "catalogId": 1
            },
            {
                "label": "1206",
                "description": "Ernährungstherapie, pro 5 Min.",
                "catalogId": 1
            },
            {
                "label": "1250",
                "description": "Verpasste Konsultation",
                "catalogId": 1
            },
            {
                "label": "1251",
                "description": "Zuschlag Nacht, Sonn- und Feiertage",
                "catalogId": 1
            },
            {
                "label": "1252",
                "description": "Zuschlag ausserordentliche Konsultation in Akutsituationen",
                "catalogId": 1
            },
            {
                "label": "1253",
                "description": "Formalisierter Bericht",
                "catalogId": 1
            },
            {
                "label": "1254",
                "description": "Nicht formalisierter Bericht",
                "catalogId": 1
            },
            {
                "label": "1255",
                "description": "Blutegel, pro Stück",
                "catalogId": 1
            },
            {
                "label": "1256",
                "description": "Wegentschädigung",
                "catalogId": 1
            }
        ],
        servicetypes:[
            {
                "id": 1,
                "name": "Behandlung",
                "internalName": "Behandlung"
            }
        ]
    };
    async initDefaults(loadDefaultsOnlyFor=false) { // false: load any, // []: load none
        return await Promise.all(Object.keys(Bookeeper.defaults).map(async tableName=>{
            if (!loadDefaultsOnlyFor || loadDefaultsOnlyFor.indexOf(tableName)>-1)
                await this.db.insert(tableName,Bookeeper.defaults[tableName],{orIgnore:true});
        }));
    }
    async init(loadDefaultsOnlyFor=false) {
        this.db = await (new SqliteWasmDatabase({onDataChange:this.onDataChange})).init();
        try {
            await this.db.exec([{
                sql: "create table if not exists invoices(id integer not null primary key, clientId integer not null, description text not null, year integer not null, billingPeriod timestamp default current_timestamp, value float not null, templateId int default 0, unique (clientId,billingPeriod) on conflict ignore)"
            },{
                sql: "create table if not exists payments(id integer not null primary key, uid text not null,date timestamp default current_timestamp, description text not null, details text not null default '', value float not null, balance float not null, unique (uid) on conflict ignore)"
            },{
                sql: "create table if not exists payments_invoices(invoiceId integer not null, paymentId integer not null, unique (invoiceId) on conflict replace)"
            },{
                sql: "create table if not exists invoices_warnings(invoiceId integer not null, level integer not null, unique (invoiceId) on conflict replace)"
            },{
                sql: "create table if not exists payments_ignore(_key text not null, val text not null, unique (_key,val) on conflict replace)"
            },{
                sql: "create table if not exists clients(id integer not null primary key, name text not null, givenName text not null default '', email text not null default '', address text not null default '', address1 text not null default '', gender text not null default '', salutation text not null default '', birthdate text not null default '', ahv text not null default '', alias text not null default '', rate float not null default 0, " +
                    "cr_name text not null default '', cr_givenName text not null default '', cr_email text not null default '', cr_address text not null default '', cr_address1 text not null default '', cr_salutation text not null default '', " +
                    "veka_nr text not null default '', versi_nr text not null default '', kanton text not null default '', comp_mode text not null default '')"
            },{
                sql: "create table if not exists servicetypes(id integer not null primary key, name text not null, internalName text not null default '')"
            },{
                sql: "create table if not exists services(id integer not null primary key, typeId int not null default 1, clientId int not null, date timestamp not null default current_timestamp, billingPeriod timestamp not null default current_timestamp, quantity float not null default 1, rate float not null, description text not null default '', importedId text, " +
                    "case_nr text not null default '', case_date timestamp text not null default '', law text not null default '', treatment_type text not null default '', treatment_cause text not null default '', referrer text not null default '', diagnose text not null default '', remarks text not null default '', "+
                    "unique(typeId,clientId,date) on conflict ignore)"
            },{
                sql: "create table if not exists records (id integer not null primary key, clientId int not null, sectionId not null default 1, category text not null, content text not null, date timestamp not null default current_timestamp)"
            },{
                sql: "create table if not exists invoices_sent(invoiceId integer not null, warningLevel integer not null, sent boolean not null, messageResult text not null, unique (invoiceId,warningLevel) on conflict replace)"
            },{
                sql: "create table if not exists settings(_key text not null, val text not null, unique (_key) on conflict replace)"
            },{
                sql: "create table if not exists invoices_customtexts(invoiceId integer not null, warningLevel integer not null, customText text not null, customSubject text not null, unique (invoiceId,warningLevel) on conflict replace)"
            },{
                sql: "create table if not exists items(id integer not null primary key, serviceId integer not null, label text not null, description text not null default '', taxUnit integer not null default 1, taxRate float not null default 2.58, quantity integer not null)"
            },{
                sql: "create table if not exists catalogs(id integer not null primary key, name text not null, taxUnit integer not null, taxRate float not null, unique (name) on conflict ignore)"
            },{
                sql: "create table if not exists catalogitems(label text not null, description text not null default '', catalogId integer not null, unique (label) on conflict ignore)"
            },{
                sql: "create table if not exists servicetypes_defaultitems(typeId integer not null, itemLabel text not null, quantity integer not null)"
            },{
                sql: "create table if not exists additional_templates(id integer not null primary key, name text not null)"
            }]);
            await this.initDefaults(loadDefaultsOnlyFor);
        } catch (err) {
            console.log(err);
        }
        return this;
    }
    async getClients(id=false,activeOnly=false,newOnly=false) {
        return await this.db.query("select c.*,round(sum(s.quantity*s.rate)+ifnull(sum(it.quantity*it.taxRate),0),2) as invoiceTotal,max(s.rate) as maxRate,min(s.rate) as minRate,count(distinct s.id) as countServices from clients as c " +
            "left join services as s on clientId=c.id " +
            "left join items as it on it.serviceId=s.id"
            +(id!==false ? " where (c.id=?)" : "")
            +(id===false && !newOnly && activeOnly ? " where (c.id in (select clientId from services where date>=date('now','-2 month')))" : "")
            +(id===false && newOnly ? " where (c.id in (select clientId from services where date>=date('now','-1 month')) and c.id not in (select clientId from services where date<date('now','-1 month')))" : "")
            +" group by c.id", id!==false ? [id] : []);
    }
    /** @deprecated: assumes client names are unique **/
    async getClientsMap() {
        let mapNameToId = {};
        let listClients = await this.getClients();
        listClients.forEach(client=>mapNameToId[client.name]=client.id);
        return mapNameToId;
    }
    async getClientsLowerCaseAliasMap() {
        let mapAliasToClient = {};
        let listClients = await this.getClients();
        listClients.forEach(client=>{
            if (client.alias)
                mapAliasToClient[client.alias.toLowerCase()]=client
        });
        return mapAliasToClient;
    }
    async getClientsLastServiceTypeMap() {
        let list = await this.db.query("select c.id,ifnull(s.typeId,1) as serviceTypeId from clients as c " +
            "left join services as s on (s.clientId=c.id " +
            "and s.date=(select max(date) from services as x where x.clientId=s.clientId order by x.id desc limit 1))");
        let map = {};
        list.forEach(({id,serviceTypeId})=>{
            map[id] = serviceTypeId;
        });
        return map;
    }
    async saveClient(id,data) {
        let qMarks = [];
        let listKeys = [];
        let values = [];
        for (let key in data) {
            qMarks.push(key+"=?");
            listKeys.push(key);
            values.push(data[key]);
        }
        return this.db.query("update clients set "+qMarks.join(",")+" where id=?",[...values,id]);
    }
    async addClient(_name="Neuer Klient") {
        let existingNames = (await this.db.query("select name from clients where name like ?",[_name+"%"])).map(item=>item.name);
        let name = _name;
        let c = 1;
        while (existingNames.indexOf(name)>-1) {
            name = _name+(" "+(c++))
        }
        return this.db.insert("clients",[{name,birthdate:""}]);
    }
    deleteClients(clientIds) {
        let qMarksIds = [];
        clientIds.forEach(()=>qMarksIds.push("?"));
        return this.db.query("delete from clients where id in ("+qMarksIds.join(",")+")",clientIds);
    }
    /** @deprecated: assumes client names are unique **/
    async importServices(data) {
        let mapNameToId = await this.importClients(data);
        let list = [];
        data.forEach(set=>{
            if (set.Name && set.services) {
                list = list.concat(set.services.map(s=>{
                    // eslint-disable-next-line no-unused-vars
                    let [all,year,month] = s.date.match(/^(\d{4})[^\d]+(\d{2})/);
                    let billingPeriod = year+"-"+month+"-25"
                    return {
                        clientId:mapNameToId[set.Name],
                        ...s,
                        ...{billingPeriod}
                    };
                }));
            }
        });
        let insertedServices = await this.db.insert("services",list);
        let insertedInvoices = await this.updateInvoices();
        return insertedServices.concat(insertedInvoices);
    }
    async getServices(order=["clientId","date"]) {
        return await this.db.query("select s.*,c.name from services as s left join clients as c on c.id=s.clientID order by "+order.join(","));
    }
    async addService(clientId,data,items=[]) {
        if (items.length)
            delete data.rate;
        let insertResults = await this.db.insert("services",[{clientId,rate:0,...data}]);
        if (insertResults.length && insertResults[0].rowsAffected>0) {
            let serviceId = insertResults[0].insertId;
            if (parseInt(data.rate||0)===0 && items.length)
                await this.db.insert("items",items.map(item=>{return {serviceId,label:item.label,taxRate:item.taxRate,quantity:item.quantity}}));
        }
        return insertResults;
    }
    async saveService(id,data,items) {
        if (items.length)
            data.rate = 0;
        let qMarks = [];
        let values = [];
        for (let key in data) {
            qMarks.push(key+"=?");
            values.push(data[key]);
        }
        await this.db.exec([{
            sql:"update services set "+qMarks.join(",")+" where id=?", args:[...values,id]
        },{
            sql:"delete from items where serviceId=?", args:[id]
        }]);
        await this.db.insert("items",items.map(item=>{delete item.id;return {serviceId:id,label:item.label,taxRate:item.taxRate,quantity:item.quantity}}));
    }
    async deleteServices(serviceIds) {
        let qMarksIds = [];
        serviceIds.forEach(()=>qMarksIds.push("?"));
        let resultSets = await this.db.query("delete from services where id in ("+qMarksIds.join(",")+")",serviceIds);
        await this.db.query("delete from items where serviceId in ("+qMarksIds.join(",")+")",serviceIds);
        return resultSets;
    }
    async getServiceTypes() {
        return this.db.query("select * from servicetypes");
    }
    async saveServiceType(id,data) {
        let qMarks = [];
        let values = [];
        for (let key in data) {
            qMarks.push(key+"=?");
            values.push(data[key]);
        }
        return await this.db.query("update servicetypes set "+qMarks.join(",")+" where id=?", [...values,id]);
    }
    async getServiceTypeDefaultItems(typeId) {
        return await this.db.query("select typeId,itemLabel as label,quantity,c.taxRate,c.name as catalogName from servicetypes_defaultitems as d left join catalogitems as ci on ci.label=d.itemLabel left join catalogs as c on c.id=ci.catalogId where typeId=?",[typeId]);
    }
    async deleteServiceTypeDefaultItems(typeId) {
        return await this.db.query("delete from servicetypes_defaultitems where typeId=?",[typeId]);
    }
    async setServiceTypeDefaultItems(typeId,data) {
        await this.deleteServiceTypeDefaultItems(typeId);
        return await this.db.insert("servicetypes_defaultitems", data.map(item=>{return {typeId,...item}}));
    }
    async deleteServiceType(id) {
        await this.deleteServiceTypeDefaultItems(id);
        return await this.db.query("delete from servicetypes where id=?",[id]);
    }
    async addServiceType() {
        let inserted = await this.db.insert("servicetypes",[{name:"Leistung",internalName:"Leistung"}]);
        if (inserted.length && inserted[0].insertId)
            await this.saveServiceType(inserted[0].insertId,{name:`Leistung ${inserted[0].insertId}`,internalName:`Leistung ${inserted[0].insertId}`});
        return inserted;
    }
    async updateInvoices(services=null) {
        if (services===null)
            services = await this.getServices();
        let invoices = [];
        let sum = 0, lastClientId = 0, lastBillingPeriod = null, lastLabelMonth = null, lastYear = null;
        // eslint-disable-next-line no-unused-vars
        let billingPeriod = null, labelMonth = null, year, month, all;
        services.forEach(service=>{
            // eslint-disable-next-line no-unused-vars
            [all,year,month] = service.date.match(/^(\d{4})[^\d]+(\d{2})/);
            labelMonth = Bookeeper.Months[Math.max(0,Math.min(11,parseInt(month)-1))];
            if (service.clientId!=lastClientId || service.billingPeriod!=lastBillingPeriod) {
                if (sum>0)
                    invoices.push({clientId:lastClientId,description:lastLabelMonth,year:lastYear,billingPeriod:lastBillingPeriod,value:sum})
                sum = 0;
            }
            sum += service.rate;
            lastClientId = service.clientId;
            lastLabelMonth = labelMonth;
            lastYear = year;
            lastBillingPeriod = service.billingPeriod
        });
        if (sum>0)
            invoices.push({clientId:lastClientId,description:lastLabelMonth,year:lastYear,billingPeriod:lastBillingPeriod,value:sum})
        return await this.db.insert("invoices",invoices);
    }
    async importClients(data) {
        let list = [];
        data.forEach(set=>{
            if (set.Name && set.Name!=="Total" && set.Name.replace(/\s+/g,"")) {
                let ext = set.ext || {};
                let days = parseInt(ext["Geburtsdatum"]||"");
                let birthdate = ext["Geburtsdatum"]||"";
                if (!isNaN(days)) {
                    let date = new Date((new Date("1.1.1900")).getTime()+24*3600*1000*(days-2));
                    birthdate = ("0"+date.getDate()).slice(-2)+"."+("0"+(date.getMonth()+1)).slice(-2)+"."+date.getFullYear()
                }

                list.push({
                    name:set.Name,
                    email:ext.Mail||"",
                    salutation:ext.Anrede||"",
                    address:ext["Adresse 1"]||"",
                    address1:ext["Adresse 2"]||"",
                    rate:ext["CHF Ansatz"]||"",
                    alias:ext["Alias"]||"",
                    birthdate:birthdate||""
                });
            }
        });
        let listResultSets = await this.db.insert("clients",list);
        return {
            listResultSets,
            mapNameToId: await this.getClientsMap()
        };
    }
    /** @deprecated: assumes client names are unique **/
    async importInvoices(data) {
        //await this.db.query("delete from invoices");
        let {mapNameToId} = await this.importClients(data);
        return new Promise((resolve,reject)=>{
            let invoices = [];
            data.forEach(set=>{
                if (set.Name && set.Name!=="Total" && mapNameToId[set.Name]) {
                    for (let key in set) {
                        if (Object.prototype.hasOwnProperty.call(set,key) && key!=="Name" && parseFloat(set[key])>0) {
                            let matches = key.match(/^(.+)\s+(\d+)$/);
                            let labelMonth = key, year = new Date().getFullYear();
                            if (matches) {
                                labelMonth = matches[1];
                                year = matches[2];
                            }
                            let shortMonth = labelMonth.replace(/\s+/,"").substr(0,3).toLowerCase();
                            labelMonth = Bookeeper.mapMatchMonths[shortMonth]||labelMonth;
                            let month = Bookeeper.Months.indexOf(labelMonth);
                            if (month>-1)
                                month++;
                            else {
                                return reject("Ungültige Monatsbezeichnung: "+key);
                            }
                            invoices.push({clientId:mapNameToId[set.Name],description:labelMonth,year,billingPeriod:year+"-"+((month+"").length===1 ? "0"+month : month)+"-25",value:set[key]});
                        }
                    }
                }
            });
            this.db.insert("invoices",invoices).then(()=>resolve());
        });
    }
    async getUnassignedInvoices(value=false,beforeDate=false,clientIds=false) {
        return new Promise((resolve)=>{
            let qMarks = [];
            if (clientIds!==false) {
                clientIds.forEach(()=>qMarks.push("?"));
            }
            this.db.query("select p.id,p.description,p.billingPeriod,p.year,clients.name,round(sum(s.quantity*s.rate)+ifnull(sum(it.quantity*it.taxRate),0),2) as val from invoices as p " +
                "left join clients on clients.id=p.clientId " +
                "left join services as s on (s.clientId=clients.id and s.billingPeriod=p.billingPeriod) " +
                "left join items as it on (it.serviceId=s.id) " +
                "where (p.id not in (select invoiceId from payments_invoices)"+(beforeDate!==false ? " and p.billingPeriod<=date('"+beforeDate+"')" : "")+(clientIds!==false ? " and clients.id in ("+qMarks.join(",")+")" : "")+") " +
                "group by p.id "+(value!==false ? "having val=? " : "")+
                "order by p.id desc",[...clientIds!==false ? clientIds : [],...value!==false ? [value] : []]).then(rows=>{
                resolve(rows.map(row=>{return {...row,value:row.val,name:this.sanitizeString(row.name)}}));
            });
        });
    }
    async addInvoice(clientId, {billingPeriod,description,year}) {
        return this.db.insert("invoices",[{clientId,billingPeriod,description,year,value:0}]);
    }
    async getAssociatedClients(paymentDescription) {
        let listClients = await this.db.query("select distinct clientId from invoices as i left join payments_invoices as x on x.invoiceId=i.id left join payments as p on p.id=x.paymentId where (i.id in (select invoiceId from payments_invoices) and p.description=?)",[paymentDescription]);
        return listClients.map(client=>client.clientId)
    }
    async importPayments(data) {
        /* todo: import no data prior to first invoice */
        let billingPeriods = (await this.getBillingPeriods()).map(item=>item.billingPeriod);
        let firstBillingPeriod = billingPeriods.length ? billingPeriods[billingPeriods.length-1] : "1970-01-01";
        await this.db.insert("payments",data.filter(set=>{
            return (set["Credit CHF"] || set["Gutschrift CHF"]) && ((set["Date"] || set["Datum"])+"").replace(/(\d{2}).(\d{2}).(\d{4})/,"$3-$2-$1")>=firstBillingPeriod
        }).map(set=>{
            return {
                date:((set["Date"] || set["Datum"])+"").replace(/(\d{2}).(\d{2}).(\d{4})/,"$3-$2-$1"),
                uid:set["ZKB reference"] || set["ZKB-Referenz"],
                description:this.sanitizeString(set["Booking text"] || set["Buchungstext"]),
                details:set["Payment purpose"] || set["Zahlungszweck"] || "",
                value:set["Credit CHF"] || set["Gutschrift CHF"],
                balance:set["Balance CHF"] || set["Saldo CHF"]};
        }).reverse());
    }
    async getUnassignedPayments() {
        return new Promise((resolve)=>{
            this.db.query("select p.*,coalesce(case when ignUid._key is null then null else 'uid' end,case when ignDesc._key is null then null else 'description' end,'') as ignoreKey from payments as p left join payments_ignore as ignUid on (ignUid.val=p.uid and ignUid._key='uid') left join payments_ignore as ignDesc on (ignDesc.val=p.description and ignDesc._key='description') where (id not in (select paymentId from payments_invoices) and ignoreKey='') order by id desc").then(rows=>{
                resolve(rows);
            });
        });
    }
    async getPayments(id=false,includeIgnored=false) {
        return new Promise((resolve)=>{
            this.db.query("select p.id,p.uid,p.date,p.description,p.details,p.value,ifnull(group_concat(x.invoiceId),0) as invoiceId,coalesce(case when ignUid._key is null then null else 'uid' end,case when ignDesc._key is null then null else 'description' end,'') as ignoreKey from payments as p left join payments_invoices as x on x.paymentId=p.id left join payments_ignore as ignUid on (ignUid.val=p.uid and ignUid._key='uid') left join payments_ignore as ignDesc on (ignDesc.val=p.description and ignDesc._key='description') where (1"+(includeIgnored===false && id===false ? " and ignoreKey=''" : "")+(id!==false ? " and p.id=?" : "")+") group by p.id",id!==false ? [id] : []).then(rows=>{
                resolve(rows);
            });
        });
    }
    async getInvoices(listId=false,maxWarningLevel=false,onlyUnmatched=false,onlyUnsent=false) {
        return new Promise((resolve)=>{
            let qMarks = [];
            if (listId!==false) {
                if (!Array.isArray(listId))
                    listId = [listId];
                listId.forEach(()=>qMarks.push("?"));
            }
            this.db.query("select p.id,c.name,c.givenName,c.salutation,c.email,c.cr_name,c.cr_givenName,c.cr_salutation,c.cr_email,c.id as clientId,p.description,p.year,p.billingPeriod,round(sum(s.quantity*s.rate)+ifnull(sum(it.quantity*it.taxRate),0),2) as value,ifnull(x.paymentId,0) as paymentId," +
                "ifnull(w.level,0) as warningLevel,ifnull(ic.customText,'') as customText,ifnull(ic.customSubject,'') as customSubject,coalesce(ic.customText,defaultText.val,'') as sendText,coalesce(ic.customSubject,defaultSubject.val,'') as sendSubject," +
                "ifnull(isent.sent,false) as sent,ifnull(isent.messageResult,'') as messageResult,ifnull(p.templateId,0) as templateId from invoices as p " +
                "left join clients as c on c.id=p.clientId " +
                "left join payments_invoices as x on x.invoiceId=p.id " +
                "left join invoices_warnings as w on w.invoiceId=p.id " +
                "left join services as s on (s.clientId=c.id and s.billingPeriod=p.billingPeriod) " +
                "left join items as it on (it.serviceId=s.id) " +
                "left join invoices_customtexts as ic on (ic.invoiceId=p.id and ic.warningLevel=ifnull(w.level,0)) " +
                "left join invoices_sent as isent on (isent.invoiceId=p.id and isent.warningLevel=ifnull(w.level,0))" +
                "left join settings as defaultText on (defaultText._key=(case when p.templateId>0 then p.templateId||'-' else '' end)||'invoice-level-'||ifnull(w.level,0))" +
                "left join settings as defaultSubject on (defaultSubject._key=(case when p.templateId>0 then p.templateId||'-' else '' end)||'invoice-subject-level-'||ifnull(w.level,0))" +
                "where (1"+(onlyUnmatched ? " and x.paymentId is null" : "")+(onlyUnsent ? " and not ifnull(isent.sent,false)" : "")+(maxWarningLevel!==false ? " and ifnull(w.level,0)<=?" : "")+(listId!==false ? " and p.id in ("+qMarks.join(",")+")" : "")+") " +
                "group by p.id,s.clientId,s.billingPeriod",
                [...maxWarningLevel!==false ? [maxWarningLevel] : [],...listId!==false ? listId : []]).then(rows=>{
                resolve(rows);
            });
        });
    }
    getInvoiceDetailsFromId(id) {
        let a=1234+8765*id
        return `Rechnung ${a.toString(16).toUpperCase()}`;
    }
    getInvoiceIdFromUid(uid) {
        return (parseInt(uid,16)-1234)/8765;
    }
    getInvoiceIdFromDetails(details) {
        return this.getInvoiceIdFromUid(details.replace(/^[^\s]+\s+/g,""));
    }
    async getBillingPeriods() {
        return this.db.query("select distinct billingPeriod,description,year from invoices group by billingPeriod order by billingPeriod desc");
    }
    async getServicesForInvoice(invoiceId,serviceTypeId=false) {
        return await this.db.query("select s.*,round(s.quantity*s.rate+ifnull(sum(it.quantity*it.taxRate),0),2) as value,t.name as type from invoices as i left join services as s on s.clientId=i.clientId left join items as it on it.serviceId=s.id left join servicetypes as t on t.id=s.typeId where (i.id=? and s.billingPeriod=i.billingPeriod"+(serviceTypeId!==false ? " and s.typeId=?" : "")+") group by s.id order by s.date desc",[invoiceId,...serviceTypeId!==false ? [serviceTypeId] : []]);
    }
    async getServicesForClient(clientId,serviceTypeId=false) {
        return await this.db.query("select s.*,round(s.quantity*s.rate+ifnull(sum(it.quantity*it.taxRate),0),2) as value,t.name as type from services as s left join items as it on it.serviceId=s.id left join servicetypes as t on s.typeId=t.id where (clientId=?"+(serviceTypeId!==false ? " and s.typeId=?" : "")+") group by s.id order by s.date desc",[clientId,...serviceTypeId!==false ? [serviceTypeId] : []]);
    }
    async getServiceItems(serviceId) {
        return await this.db.query("select *,ifnull(c.description,'') as description from items as i left join catalogitems as c on c.label=i.label where i.serviceId=?",[serviceId]);
    }
    async getServicesImportedIds() {
        return (await this.db.query("select importedId from services where (importedId is not null and importedId<>'')")).map(item=>item.importedId);
    }
    async getWriteoffs(id=false) {
        return new Promise((resolve)=>{
            this.db.query("select p.id,c.name,p.description,p.billingPeriod,p.value,ifnull(x.paymentId,0) as paymentId,ifnull(w.level,0) as warningLevel from invoices as p left join clients as c on c.id=p.clientId left join payments_invoices as x on x.invoiceId=p.id left join invoices_warnings as w on w.invoiceId=p.id where (warningLevel>3"+(id!==false ? " and id=?" : "")+") group by p.id",id!==false ? [id] : []).then(rows=>{
                resolve(rows);
            });
        });
    }
    async getMatchingInvoices(paymentId) {
        return new Promise((resolve)=>{
            this.db.query("select i.id,i.clientId,c.name,i.description,i.billingPeriod,round(sum(s.quantity*s.rate)+ifnull(sum(it.quantity*it.taxRate),0),2) as value,1 as matched from payments as p " +
                "left join payments_invoices as x on x.paymentId=p.id " +
                "left join invoices as i on x.invoiceId=i.id " +
                "left join clients as c on c.id=i.clientId " +
                "left join services as s on (s.clientId=c.id and s.billingPeriod=i.billingPeriod) " +
                "left join items as it on (it.serviceId=s.id) " +
                "where (p.id=? and i.id is not null) " +
                "group by i.id "+
                "union select i.id,i.clientId,c.name,i.description,i.billingPeriod,round(sum(s.quantity*s.rate)+ifnull(sum(it.quantity*it.taxRate),0),2) as value,0 as matched from invoices as i " +
                "left join clients as c on c.id=i.clientId " +
                "left join services as s on (s.clientId=c.id and s.billingPeriod=i.billingPeriod) " +
                "left join items as it on (it.serviceId=s.id) " +
                "where i.id not in (select invoiceId from payments_invoices) " +
                "group by i.id "+
                "order by matched desc",[paymentId]).then(rows=>{
                resolve(rows);
            });
        });
    }
    async getMatchingPayments(invoiceId,hideForeignMatched=false) {
        return new Promise((resolve)=>{
            this.db.query("select p.*,1 as matched,'' as ignoreKey, 1 as hasInvoices from invoices as i " +
                "left join payments_invoices as x on x.invoiceId=i.id " +
                "left join payments as p on x.paymentId=p.id " +
                "where (i.id=? and p.id is not null) " +
                "union select p.*, 0 as matched, coalesce(case when ignUid._key is null then null else 'uid' end,case when ignDesc._key is null then null else 'description' end,'') as ignoreKey, case when group_concat(ifnull(p_i.invoiceId,''))='' then 0 else 1 end as hasInvoices from payments as p " +
                "left join payments_invoices as p_i on p_i.paymentId=p.id " +
                "left join payments_ignore as ignUid on (ignUid.val=p.uid and ignUid._key='uid') " +
                "left join payments_ignore as ignDesc on (ignDesc.val=p.description and ignDesc._key='description') " +
                "where ("+(hideForeignMatched ? "id not in (select paymentId from payments_invoices)" : "1")+" and ignoreKey='') group by p.id order by matched desc",[invoiceId]).then(rows=>{
                if (rows.length && rows[0].matched) {
                    let matchedId = rows[0].id;
                    let i=1;
                    while (i<rows.length && rows[i].id!=matchedId)
                        i++;
                    if (i<rows.length)
                        rows.splice(i,1);
                }
                resolve(rows);
            });
            /*this.db.query("select p.*,1 as matched,'' as ignoreKey,0 as numAssigned from invoices as i " +
                "left join payments_invoices as x on x.invoiceId=i.id " +
                "left join payments as p on x.paymentId=p.id " +
                "where (i.id=? and p.id is not null) " +
                "union select p.*, 0 as matched, coalesce(case when ignUid._key is null then null else 'uid' end,case when ignDesc._key is null then null else 'description' end,'') as ignoreKey, count(x.invoiceId) as numAssigned from payments as p " +
                "left join payments_invoices as x on x.paymentId=p.id " +
                "left join payments_ignore as ignUid on (ignUid.val=p.uid and ignUid._key='uid') " +
                "left join payments_ignore as ignDesc on (ignDesc.val=p.description and ignDesc._key='description') " +
                "where ("+(hideForeignMatched ? "id not in (select paymentId from payments_invoices)" : "1")+" and ignoreKey='') group by x.paymentId order by matched desc",[invoiceId]).then(rows=>{
                resolve(rows);
            });*/
            /*this.db.query("select p.*, x.invoiceId,case when x.invoiceId=? then 1 else 0 end as matched, coalesce(case when ignUid._key is null then null else 'uid' end,case when ignDesc._key is null then null else 'description' end,'') as ignoreKey, count(x.invoiceId) as numAssigned from payments as p " +
                "left join payments_invoices as x on x.paymentId=p.id " +
                "left join payments_ignore as ignUid on (ignUid.val=p.uid and ignUid._key='uid') " +
                "left join payments_ignore as ignDesc on (ignDesc.val=p.description and ignDesc._key='description') " +
                "where ("+(hideForeignMatched ? "id not in (select paymentId from payments_invoices)" : "1")+" and ignoreKey='') group by x.invoiceId order by matched desc",[invoiceId]).then(rows=>{
                resolve(rows);
            });*/
        });
    }
    async getTotalDue(invoiceIds=[]) {
        return new Promise((resolve)=>{
            let qMarks = [];
            invoiceIds.forEach(()=>qMarks.push("?"));
            this.db.query("select round(sum(s.quantity*s.rate)+ifnull(sum(it.quantity*it.taxRate),0),2) as total,ifnull(x.level,0) as warningLevel from services as s left join items as it on (it.serviceId=s.id) left join invoices as i on (i.billingPeriod=s.billingPeriod and i.clientId=s.clientId) left join invoices_warnings as x on x.invoiceId=i.id where (warningLevel<4"+(invoiceIds.length ? " and i.id in ("+qMarks.join(",")+")" : "")+")",invoiceIds).then(rows=>{
                resolve(rows.length ? rows[0]["total"] : 0);
            });
        });
    }
    async getTotalAssigned(invoiceIds=[]) {
        return new Promise((resolve)=>{
            let qMarks = [];
            invoiceIds.forEach(()=>qMarks.push("?"));
            this.db.query("select sum(value) as total from payments where id in (select paymentId from payments_invoices"+(invoiceIds.length ? " where invoiceId in ("+qMarks.join(",")+")" : "")+")",invoiceIds).then(rows=>{
                resolve(rows.length ? rows[0]["total"] : 0);
            });
        });
    }
    async getTotalIncoming() {
        return new Promise((resolve)=>{
            this.db.query("select sum(value) as total from payments as p where (p.uid not in (select val from payments_ignore where _key='uid') and p.description not in (select val from payments_ignore where _key='description'))").then(rows=>{
                resolve(rows.length ? rows[0]["total"] : 0);
            });
        });
    }
    async getTotalWriteoffs() {
        return new Promise((resolve)=>{
            this.db.query("select sum(value) as total from invoices as i left join invoices_warnings as x on x.invoiceId=i.id where x.level>3").then(rows=>{
                resolve(rows.length ? rows[0]["total"] : 0);
            });
        });
    }
    async updateAssignments() {
        let unassignedPayments = await this.getUnassignedPayments();
        let newlyAssignedInvoices = [];
        return await (() => {
            return new Promise(resolve=>{
                let nextPayment = async (k) => {
                    if (k<unassignedPayments.length) {
                        let payment = unassignedPayments[k];
                        let paymentDate = payment.date.replace(/\./g,"-");

                        /* first, try to match payment to an invoice by payment details (if it contains the required nr/uid pattern) */
                        let candidateInvoices = [];
                        let bestMatchIx = -1;
                        let matches = payment.details.match(/Rechnung\s+([^\s]+)/i);
                        if (matches) {
                            let [,matchedUid] = matches;
                            let targetId = this.getInvoiceIdFromUid(matchedUid);
                            console.log(matchedUid,targetId);
                            candidateInvoices = await this.getInvoices([targetId],false,true);
                            if (candidateInvoices.length===1)
                                bestMatchIx = 0;
                        }

                        /* second, try to match payment to an invoice addressed to a client known to match its description */
                        if (bestMatchIx<0) {
                            let clientIds = await this.getAssociatedClients(payment.description);
                            if (clientIds.length) {
                                candidateInvoices = await this.getUnassignedInvoices(payment.value,paymentDate,clientIds);
                                let distinctClientIds = [];
                                candidateInvoices.forEach(invoice=>{
                                    if (distinctClientIds.indexOf(invoice.clientId)===-1)
                                        distinctClientIds.push(invoice.clientId)
                                });
                                if (distinctClientIds.length===1) {
                                    bestMatchIx=0;
                                }
                            }
                        }

                        /* third, if there are no such clients or the above result is ambiguous (matches multiple clients), try matching by name segments */
                        if (bestMatchIx<0) {
                            candidateInvoices = await this.getUnassignedInvoices(payment.value,paymentDate);
                            let i=0;
                            let maxWordsMatch = 0;

                            console.log(payment.description, payment.value, `before ${paymentDate}`,  candidateInvoices.length);
                            while (i<candidateInvoices.length && !payment.description.match(new RegExp(candidateInvoices[i].name,"i"))) {
                                let words = candidateInvoices[i].name.split(/[\s-,;.]+/);
                                let c=0;
                                words.forEach(word=>{
                                    word = word.replace(/^[\s-,;.]+/,"").replace(/[\s-,;.]+$/,"");
                                    if (word.length>1 && payment.description.match(new RegExp(word,"i")))
                                        c++;
                                })
                                if (c>maxWordsMatch) {
                                    maxWordsMatch=c;
                                    bestMatchIx=i;
                                }
                                i++;
                            }
                            if (i<candidateInvoices.length)
                                bestMatchIx = i;
                        }
                        if (bestMatchIx>=0) {
                            await this.assignInvoiceToPayment(candidateInvoices[bestMatchIx].id,payment.id);
                            newlyAssignedInvoices.push(candidateInvoices[bestMatchIx]);
                        }
                        nextPayment(k+1);
                    }
                    else
                        resolve(newlyAssignedInvoices);
                };
                nextPayment(0);
            });
        })();
    }
    async assignInvoiceToPayment(invoiceId,paymentId) {
        await this.db.insert("payments_invoices",[{invoiceId,paymentId}]);
    }
    async assignPaymentToInvoice(paymentIds,invoiceId) {
        await this.db.query("delete from payments_invoices where invoiceId=?",[invoiceId])
        if (paymentIds.length)
            await this.db.insert("payments_invoices",[{paymentId:paymentIds[0],invoiceId}]);
    }
    async assignInvoicesToPayment(listInvoiceIds,paymentId) {
        await this.db.query("delete from payments_invoices where paymentId=?",[paymentId])
        await this.db.insert("payments_invoices",listInvoiceIds.map(invoiceId=>{return {invoiceId,paymentId};}));
    }
    async setInvoiceWarningLevel(invoiceId,warningLevels) {
        await this.db.query("delete from invoices_warnings where invoiceId=?",[invoiceId])
        if (warningLevels.length)
            await this.db.insert("invoices_warnings",[{invoiceId,level:warningLevels[0]}]);
    }
    async setInvoiceSent(invoiceId,warningLevel=0,sent=true,messageResult="") {
        await this.db.insert("invoices_sent",[{invoiceId,warningLevel,sent,messageResult}]);
    }
    async setInvoiceTemplate(invoiceId,templateId) {
        await this.db.query("update invoices set templateId=? where id=?",[templateId,invoiceId]);
    }
    async setIgnorePayment(map) {
        for (let key in map) {
            if (Object.prototype.hasOwnProperty.call(map,key)) {
                if (!map[key].selected)
                    await this.db.query("delete from payments_ignore where (_key=? and val=?)",[key,map[key].val]);
                else
                    await this.db.insert("payments_ignore",[{_key:key,val:map[key].val}]);
            }
        }
    }
    async setIgnorePaymentsByUid(listUid) {
        await this.db.insert("payments_ignore",listUid.map(uid=>{return {_key:"uid",val:uid};}));
    }
    async deleteInvoice(id) {
        if (id) {
            await this.db.query("delete from invoices where id=?",[id]);
        }
    }
    deleteInvoices(listIds) {
        let qMarks = [];
        listIds.forEach(()=>qMarks.push("?"));
        return this.db.query("delete from invoices where (id in ("+qMarks.join(",")+") and id not in (select invoiceId from payments_invoices))",listIds);
    }
    deletePayments(listIds) {
        let qMarks = [];
        listIds.forEach(()=>qMarks.push("?"));
        return this.db.query("delete from payments where (id in ("+qMarks.join(",")+") and id not in (select paymentId from payments_invoices))",listIds);
    }
    addRecord(clientId,category="Allgemeines",content="") {
        return this.db.insert("records",[{clientId,category,content}]);
    }
    getRecord(id) {
        return this.db.query("select * from records where id=?",[id]);
    }
    getRecords(clientId) {
        return this.db.query("select * from records where clientId=? order by category,id desc",[clientId]);
    }
    getAllRecords(orsOfAndsWhere) {
        let qMarks = [];
        let values = [];
        orsOfAndsWhere.forEach(andWhere=>{
            let qAnds = [];
            let valueAnds = [];
            for (let key in andWhere) {
                qAnds.push(key+"=?");
                valueAnds.push(andWhere[key]);
            }
            qMarks.push(qAnds.join(" and "));
            values = values.concat(valueAnds);
        });
        return this.db.query("select * from records where ("+qMarks.join(" or ")+") order by category,id desc",values);
    }
    editRecord(recordId,data) {
        let qMarks = [];
        let values = [];
        for (let key in data) {
            qMarks.push(key+"=?");
            values.push(data[key]);
        }
        return this.db.exec([{
            sql:"update records set "+qMarks.join(",")+" where id=?",
            args:[...values,recordId]
        }])
    }
    editRecords(recordIds,data) {
        let qMarks = [];
        let values = [];
        for (let key in data) {
            qMarks.push(key+"=?");
            values.push(data[key]);
        }
        let qMarksIds = [];
        recordIds.forEach(()=>qMarksIds.push("?"));
        return this.db.exec([{
            sql:"update records set "+qMarks.join(",")+" where id in ("+qMarksIds.join(",")+")",
            args:[...values,...recordIds]
        }])
    }
    deleteRecords(recordIds) {
        let qMarksIds = [];
        recordIds.forEach(()=>qMarksIds.push("?"));
        return this.db.exec([{
            sql:"delete from records where id in ("+qMarksIds.join(",")+")",
            args:recordIds
        }])
    }
    getRecordCategories() {
        return this.db.query("select distinct category as name from records group by category");
    }
    async getSettings() {
        let data = await this.db.query("select * from settings");
        let map = {};
        data.forEach(item=>{
            map[item._key] = item.val;
        });
        if (!map["backend-host"]) {
            map["backend-host"] = location.origin
        }

        const apiBaseUrl = !process.env.IS_ELECTRON ? `${location.origin}/${process.env.VUE_APP_BASE_PATH ? `${process.env.VUE_APP_BASE_PATH}/` : ''}api`
            : `http://${map["backend-host"]}:${map["backend-port"]||3435}`
        return {...map, apiBaseUrl};
    }
    async saveSettings(map) {
        let data = [];
        for (let key in map) {
            data.push({_key:key,val:map[key]});
        }
        return await this.db.insert("settings",data);
    }
    async saveCustomText(invoiceId,warningLevel,customText,customSubject="") {
        if (customText)
            return await this.db.insert("invoices_customtexts",[{invoiceId,warningLevel,customText,customSubject}]);
        else
            return await this.db.query("delete from invoices_customtexts where (invoiceId=? and warningLevel=?)",[invoiceId,warningLevel]);
    }
    async updateBillingPeriod(oldBillingPeriod,{billingPeriod,description,year}) {
        return await this.db.exec([{
            sql:"update invoices set billingPeriod=?, description=?, year=? where billingPeriod=?",
            args:[billingPeriod,description,year,oldBillingPeriod]
        },{
            sql:"update services set billingPeriod=? where billingPeriod=?",
            args:[billingPeriod,oldBillingPeriod]
        }]);
    }
    async getCatalogs() {
        return await this.db.query("select * from catalogs");
    }
    async saveCatalog(id,data) {
        let qMarks = [];
        let values = [];
        for (let key in data) {
            qMarks.push(key+"=?");
            values.push(data[key]);
        }
        return await this.db.query("update catalogs set "+qMarks.join(",")+" where id=?", [...values,id]);
    }
    async deleteCatalog(id) {
        await this.deleteCatalogItems(id);
        return await this.db.query("delete from catalogs where id=?",[id]);
    }
    async addCatalog() {
        let inserted = await this.db.insert("catalogs",[{name:"Katalog",taxUnit:1,taxRate:0}]);
        if (inserted.length && inserted[0].insertId)
            await this.saveCatalog(inserted[0].insertId,{name:`Katalog ${inserted[0].insertId}`});
        return inserted;
    }
    async getCatalogItems(catalogId=false,likeLabel=false) {
        return this.db.query("select i.*,c.id as catalogId,c.name as catalogName,c.taxUnit,c.taxRate from catalogitems as i left join catalogs as c on c.id=i.catalogId where ("+(catalogId!==false ? "c.id=?" : "1")+" and "+(likeLabel!==false ? "i.label like ?" : "1")+") order by c.id", [...catalogId!==false ? [catalogId] : [],...likeLabel!==false ? [likeLabel+"%"] : []]);
    }
    async importCatalogItems(items) {
        return await this.db.insert("catalogitems",items.map(item=>({label:item.label,description:item.description,catalogId:item.catalogId})));
    }
    async deleteCatalogItems(catalogId) {
        return await this.db.query("delete from catalogitems where catalogId=?",[catalogId]);
    }
    async getAdditionalTemplates() {
        return this.db.query("select * from additional_templates");
    }
    async getTemplates() {
        let list = await this.getAdditionalTemplates();
        list.unshift({id:0,name:"Standard"})
        return list;
    }
    async addAdditionalTemplate() {
        let inserted = await this.db.insert("additional_templates",[{name:""}]);
        if (inserted.length && inserted[0].insertId)
            await this.renameAdditionalTemplate(inserted[0].insertId,`Vorlage ${inserted[0].insertId}`);
        return inserted;
    }
    async renameAdditionalTemplate(id,name) {
        return this.db.query("update additional_templates set name=? where id=?",[name,id]);
    }
    async deleteAdditionalTemplate(id) {
        return this.db.query("delete from additional_templates where id=?",[id]);
    }
    async reset() {
        return new Promise((resolve,reject)=>{
            this.db.query("select name from sqlite_master where type='table'").then(async (tables)=>{
                tables = tables.map(table=>table.name).filter(name=>!name.match(/^_/));
                let next = (i)=>{
                    if (i<tables.length) {
                        this.db.query("drop table " + tables[i]).then(()=>next(i+1)).catch(({code,message}) => {
                            reject({code,message});
                        });
                    }
                    else
                        resolve();
                };
                next(0);
            });
        });
    }
    sanitizeString(str) {
        return (str || "").replace("ä", "ae").replace("ö", "oe").replace("ü", "ue").replace("Ä", "Ae").replace("Ö", "Oe").replace("Ü", "ue");
    }
    dumpDatabase(onlyTables=false) {
        return new Promise((resolve,reject)=>{
            let tables = [];
            this.db.query("select * from sqlite_master where type='table'").then((list)=>{
                list = list.filter(table=>!table.name.match(/^_/));
                if (onlyTables!==false)
                    list = list.filter(item=>onlyTables.indexOf(item.name)>-1);
                let next = (i)=>{
                    if (i<list.length) {
                        this.db.query("select * from " + list[i].name).then((data)=>{
                            tables.push({...list[i],data})
                            next(i+1)
                        }).catch(({code,message}) => {
                            reject({code,message});
                        });
                    }
                    else
                        resolve({tables,timestamp:new Date().getTime()});
                };
                next(0);
            });
        });
    }
    restoreDatabase(dump) {
        return new Promise((resolve,reject)=>{
            let queries = [];
            dump.tables.forEach(table=>{
                queries.push({sql:"drop table if exists "+table.name});
                queries.push({sql:table.sql});
                queries.push({insert:table.data,table:table.name});
            });
            let nextQuery = (i=0)=>{
                if (i<queries.length) {
                    let q = queries[i];
                    console.log(q);
                    (q.insert ? this.db.insert(q.table,q.insert) : this.db.query(queries[i].sql)).then(()=>nextQuery(i+1)).catch(({code,message}) => {
                        reject({code,message});
                    });
                }
                else {
                    appState.resetCache();
                    resolve();
                }
            }
            nextQuery();
        });
    }
    async _migrate_20220826_names() {
        let {tables} = await this.dumpDatabase(["clients"]);
        if (tables.length) {
            let [clientsTable] = tables;
            let clients = clientsTable.data.map(client=>{
                let givenName = null, name = null;
                if (!client.givenName) {
                    let tokens = (client.name||"").split(/\s+/);
                    if (tokens.length) {
                        name = tokens[tokens.length-1];
                        tokens.pop();
                        givenName = tokens.join(" ");
                    }
                }
                return {
                    ...client,
                    ...givenName ? { givenName,name } : {}
                }
            });
            await this.db.exec([{sql:"drop table if exists clients"}]);
            await this.init([]);
            await this.db.insert("clients",clients,{orIgnore:true});
        }
    }
    async migrateDatabase() {
        let dump = await this.dumpDatabase();
        let oldTables = dump.tables;
        let queriesDropAll = [];
        oldTables.forEach(({name})=>{
            queriesDropAll.push({sql:`drop table if exists ${name}`});
        });
        await this.db.exec(queriesDropAll);
        // load defaults only for tables not present in old dump
        await this.init([]);
        dump = await this.dumpDatabase();
        let newTables = dump.tables;
        let oldTableNames = oldTables.map(({name})=>name);
        let newTableNames = newTables.map(({name})=>name);
        let tablesNotPresentInOldDump = newTableNames.filter(name=>oldTableNames.indexOf(name)===-1);
        await this.initDefaults(tablesNotPresentInOldDump);
        await new Promise((resolve,reject)=>{
            let nextQuery = (i=0)=>{
                if (i<oldTables.length) {
                    let table = oldTables[i];
                    this.db.insert(table.name,table.data,{orIgnore:true}).then(()=>nextQuery(i+1)).catch(({code,message}) => {
                        reject({code,message});
                    });
                }
                else
                    resolve();
            }
            nextQuery();
        });
        await this._migrate_20220826_names();
    }
    async saveDatabase(label) {
        try {
            let settings = await this.getSettings();
            let data = (await axios.post(`${settings.apiBaseUrl}/backup/save/${label||'auto'}`,{
                data:await this.dumpDatabase(),
                legacyApiToken:singletons.legacyApiToken
            },{
                headers: {
                    "Content-type": "application/json; charset=utf-8"
                }
            })).data||{};
            if (data.savestate)
                localStorage.setItem("savestate",data.savestate);
        } catch (err) {
            console.log(err);
        }
    }
    async getDatabaseDump(savestate,label="auto") {
        try {
            let settings = await this.getSettings();
            const dumpBase64Json = (await axios.get(`${settings.apiBaseUrl}/backup/get/${label}/${savestate}`,{
                params:{legacyApiToken:singletons.legacyApiToken}
            })).data||"";
            return JSON.parse(Buffer.from(dumpBase64Json,"base64").toString("utf-8"));
        } catch (err) {
            console.log(err);
        }
    }
    async restoreDatabaseFromSaveState(savestate) {
        try {
            await this.restoreDatabase(await this.getDatabaseDump(savestate))
            localStorage.setItem("savestate",savestate);
        } catch (err) {
            console.log(err);
        }
    }
    async getRemoteSaveState(label="auto") {
        let settings = await this.getSettings();
        console.log(singletons.legacyApiToken);
        let data = await (await axios.get(`${settings.apiBaseUrl}/backup/latest/${label}`,{
            params:{legacyApiToken:singletons.legacyApiToken}
        })).data||{};
        return data.savestate || null;
    }
    getLocalSaveState() {
        let savestate = null;
        try {
            savestate = localStorage.getItem("savestate");
        } catch (err) {
            savestate = null;
        }
        return savestate;
    }
}
/*
SELECT date('2022-01-01','+1 month','-1 day','-'||((strftime('%w', date('2022-01-01','+1 month','-1 day'))+7-4)%7)||' day')
SELECT CASE strftime('%w', date('2022-01-01', 'start of month','+1 month','-1 day')) WHEN '0' THEN date('2022-01-01', 'start of month','+1 month','-3 day') WHEN '6' THEN date('2022-01-01', 'start of month','+1 month','-2 day') ELSE date('42022-01-01', 'start of month','+1 month','-1 day') END;
SELECT date('2022-01-01','+1 month','-1 week');
 */
