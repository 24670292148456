<template>
  <div>
    <div v-for="preview of listPreviews" v-bind:key="preview.id">
      <div  class="flex flex-row">
        <div class="flex-grow-1">
          <p>Von: {{preview.fromName ? preview.fromName+" " : ""}}&lt;{{preview.from}}&gt;</p>
          <p><b>An: {{preview.name}} &lt;{{preview.to.join(",")}}&gt;</b></p>
          <p>Betreff: {{preview.subject}}</p>
          <p>Anhang: <a :href="getAttachmentUrl(preview.invoice.uuid)" target="_blank">{{preview.invoice.name}}</a></p>
          <div v-html="preview.html"></div>
        </div>
        <div class="flex-column flex justify-content-center">
          <Button icon="pi pi-trash" class="p-button-rounded p-button-plain p-button-text p-button-lg" @click="removeItem(preview)"/>
        </div>
      </div>
      <hr>
    </div>
    <div class="flex flex-row mt-4 justify-content-end w-full">
      <Button label="Schliessen" icon="pi pi-times" @click="closeDialog(false)" class="p-button-text"/>
      <Button label="Senden" icon="pi pi-envelope" @click="closeDialog(true)" autofocus />
    </div>
  </div>
</template>

<script>

import {Bookeeper} from "@/bookeeper";
import {singletons} from "@/singletons";

export default {
  inject: ['dialogRef'],
  name: "InvoicesPreviewDialog",
  data() {
    return {
      listPreviews:"",
      mapSettings:{},
      store:null
    }
  },
  async created() {
    this.store = await new Bookeeper().init();
    this.mapSettings = await this.store.getSettings();
    let params = this.dialogRef.data;
    if (params)
      this.listPreviews = params.listPreviews;
  },
  methods: {
    getAttachmentUrl(uuid) {
      return `${this.mapSettings.apiBaseUrl}/download/invoice/${uuid}?legacyApiToken=${singletons.legacyApiToken}`;
    },
    closeDialog(ok) {
      this.dialogRef.close(ok ? {listPreviews:this.listPreviews} : null);
    },
    removeItem({id}) {
      this.$confirm.require({
        acceptClass:"p-button-primary",
        rejectClass:"p-button-text p-button-plain",
        acceptLabel:"Ja",
        rejectLabel:"Nein",
        message: 'Sicher?',
        header: 'Eintrag löschen?',
        icon: 'pi pi-question-circle',
        acceptIcon:'pi pi-check',
        accept: async () => {
          let i=0;
          while (i<this.listPreviews.length && this.listPreviews[i].id!==id)
            i++;
          if (i<this.listPreviews.length)
            this.listPreviews.splice(i,1);
          if (!this.listPreviews.length)
            this.closeDialog();
        }
      });
    }
  }
}
</script>

<style scoped>

</style>