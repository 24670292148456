<script>

export default {
  name: "AuthView",
  data() {
    return {
      deferred:null
    }
  },
  async created() {
    this.deferred = new Promise((resolve)=>{
      this.authService.ensureAuth(this.requiredUserLevel||false)
      .then(isAuth=>{
        let root = process.env.VUE_APP_CLUSTER_ROOT || false;

        if (!isAuth)
          this.$router.push("/login");
        else {
          console.log(isAuth);
          let { expired, confirmation } = isAuth;
          if (!confirmation)
            this.$router.push("/confirmation");
          else if (expired)
            this.$router.push("/password-expired");
          else {
            let primaryNode = isAuth.node || "m01";
            let usedNodes = root!==false ? (isAuth.usedNodes || "").split(",").map(n => n.trim()).filter(n=>n) : [];
            if (!usedNodes.find(n=>n===primaryNode))
              usedNodes.push(primaryNode);
          }
        }
        resolve(isAuth);
      })
      .catch(err => {
        this.$toast.add({severity:'error', summary: err.messageTitle||'Fehler', detail:err.message||err, life: 3000})
        this.$router.push("/login");
        resolve(false);
      });
    });
  }
};
</script>