class Gapi {
    constructor() {
        this.gapiInit = false;
        this.tokenClient = null;
    }
    async init() {
        if (!this.gapiInit) {
            console.log("init gapi");
            await new Promise(resolve=>{
                window.gapi.load('client', async () => {
                    await window.gapi.client.init({
                        apiKey: "AIzaSyAtkoZ-gSfAEmsCo4ebEoTbdAnr59exs_g",
                        discoveryDocs: ["https://www.googleapis.com/discovery/v1/apis/calendar/v3/rest"],
                    });
                    this.gapiInit = true;
                    resolve();
                });
            });
        }
        if (!this.tokenClient) {
            console.log("init token client");
            this.tokenClient = window.google.accounts.oauth2.initTokenClient({
                client_id: "351662566264-j3vlimvtdgdjfp7ug5dfjcnpjq3rq8pm.apps.googleusercontent.com",
                scope: "https://www.googleapis.com/auth/calendar.readonly",
                callback: '', // defined later
            });
        }
        return {gapi:window.gapi,tokenClient:this.tokenClient};
    }
}
export default Gapi;