<template>
  <Panel class="w-full mt-1 pr-1 templates-panel" :key="trigger">
    <template #header>
      <span class="p-panel-title" contenteditable="" @blur="onChangeName" @keyup="onKeyupName">{{nameInput}}</span>
    </template>
    <div class="flex flex-row justify-content-between align-items-center">
      <div class="flex flex-row align-items-start">
        <Button @click="editInvoiceTemplate(0,'Vorlage: Rechnung')" label="Rechnung" :icon="'pi pi-'+(hasInvoiceTemplate[0] ? 'check' : 'upload')" :class="'mt-2 ml-2 mb-2p-button p-button-'+(hasInvoiceTemplate[0] ? 'success bg-green-500 border-green-500 focus:border-green-500' : 'primary')">
        </Button>
        <Button @click="editInvoiceTemplate(1,'Vorlage: 1. Mahnung')" label="Rechnung" :icon="'pi pi-'+(hasInvoiceTemplate[1] ? 'check' : 'upload')" :class="'mt-2 ml-2 mb-2p-button p-button-'+(hasInvoiceTemplate[1] ? 'success bg-green-500 border-green-500 focus:border-green-500' : 'primary')">
        </Button>
        <Button @click="editInvoiceTemplate(2,'Vorlage: 2. Mahnung')" label="Rechnung" :icon="'pi pi-'+(hasInvoiceTemplate[2] ? 'check' : 'upload')" :class="'mt-2 ml-2 mb-2p-button p-button-'+(hasInvoiceTemplate[2] ? 'success bg-green-500 border-green-500 focus:border-green-500' : 'primary')">
        </Button>
        <Button label="Rechnung" icon="pi pi-pencil" class="p-button-secondary mt-2 ml-2 mb-2" @click="showTemplateEditorDialog('level-0')"></Button>
        <Button label="Mahnung 1" icon="pi pi-pencil" class="p-button-secondary mt-2 ml-2 mb-2 white-space-nowrap" @click="showTemplateEditorDialog('level-1')"></Button>
        <Button label="Mahnung 2" icon="pi pi-pencil" class="p-button-secondary mt-2 ml-2 mb-2 white-space-nowrap" @click="showTemplateEditorDialog('level-2')"></Button>
      </div>
      <Button v-if="id" icon="pi pi-trash" class="p-button-rounded p-button-plain p-button-text p-button-lg" @click="$emit('onRequestDelete')"/>
    </div>
  </Panel>
</template>
<script>
import axios from "axios";
import EditorDialog from "@/components/EditorDialog";
import {Bookeeper} from "@/bookeeper";
import InvoiceTemplateDialog from "@/components/InvoiceTemplateDialog";
import {singletons} from "@/singletons";

export default {
  name: "TemplatesComponent",
  emits: ['onRequestDelete'],
  props: {
    name: {
      type:String,
      default:"Standard",
    },
    id: {
      type:Number,
      default:0
    }
  },
  data() {
    return {
      store:null,
      mapSettings: {},
      trigger:0,
      hasInvoiceTemplate:[false,false,false]
    }
  },
  setup(props) {
    return {templateId:props.id,nameInput:props.name}
  },
  async created() {
    this.store = await new Bookeeper().init();
    this.mapSettings = await this.store.getSettings();
    await this.loadInvoiceTemplates();
  },
  methods: {
    editInvoiceTemplate(level,header) {
      this.$dialog.open(InvoiceTemplateDialog, {
        props: {
          header,
          modal: true,
          dismissableMask: true
        },
        data: {
          templateId:this.templateId,
          level,
          hasInvoiceTemplate:this.hasInvoiceTemplate[level]
        },
        onClose: () => {
          this.loadInvoiceTemplates();
        }
      });
    },
    async uploadInvoiceTemplate(e,warningLevel=0) {
      let formData = new FormData();
      formData.append("template", e.files[0]);
      formData.append("legacyApiToken", singletons.legacyApiToken);
      try {
        await axios.post(`${this.mapSettings.apiBaseUrl}/upload/template/${this.templateId ? `${this.templateId}-` : ''}invoice${warningLevel ? `-${warningLevel}` : ''}`, formData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        }).then(()=>{
          this.$toast.add({severity:'success', summary: 'Erfolgreich', detail:'Vorlage hochgeladen', life: 3000})
          this.loadInvoiceTemplates();
        }).catch((err)=>{
          this.$toast.add({severity:'error', summary: 'Fehler', detail:err, life: 3000});
        })
      } catch (err) {
        this.$toast.add({severity:'error', summary: 'Fehler', detail:err, life: 3000});
      }
    },
    async loadInvoiceTemplates() {
      this.hasInvoiceTemplate = await Promise.all([0,1,2].map(async warningLevel => (await axios.get(`${this.mapSettings.apiBaseUrl}/template/${this.templateId ? `${this.templateId}-` : ''}invoice${warningLevel ? `-${warningLevel}` : ''}`,{params:{legacyApiToken:singletons.legacyApiToken}})).data));
    },
    async showTemplateEditorDialog(key) {
      let map  = await this.store.getSettings();
      const prefix = `${this.templateId ? `${this.templateId}-` : ''}`;
      let val = map[`${prefix}invoice-${key}`] || "";
      let title = map[`${prefix}invoice-subject-${key}`] || "";
      this.$dialog.open(EditorDialog, {
        props: {
          header: 'Vorlage bearbeiten',
          modal: true
        },
        data: {
          val,
          title,
          titleLabel:"Betreff"
        },
        onClose: (options) => {
          const data = options.data;
          if (data) {
            this.store.saveSettings({[`${prefix}invoice-${key}`]:data.val, [`${prefix}invoice-subject-${key}`]:data.title})
          }
        }
      });
    },
    onChangeName(e) {
      this.nameInput = e.target.innerHTML.replace(/<[^>]*>/g,"").replace(/\n+/g,"").replace(/\s+$/g,"").replace(/&[^;];/g,"");
      this.trigger++;
      this.store.renameAdditionalTemplate(this.id,this.nameInput);
    },
    onKeyupName(e) {
      if (e.keyCode===13)
        e.target.blur();
      else if (e.keyCode===27) {
        e.target.innerHTML = this.nameInput;
        this.trigger++;
      }
    }
  }
};
</script>