import { sqlite3Worker1Promiser } from '@sqlite.org/sqlite-wasm';

class SqliteWasmDatabase {
    promiser;
    dbId;
    onDataChange;

    constructor(args = {}) {
        if (args.onDataChange) {
            this.onDataChange = (sql)=> {
                console.log("onDataChange",sql);
                args.onDataChange();
                let savestate = localStorage.getItem("savestate")+"";
                if (savestate) {
                    let matches = savestate.match(/^([^.]+)\.(\d+)$/);
                    let [,state,mod] = matches  ? matches : ["",savestate,"0"];
                    if (matches)
                        mod = parseInt(mod)+1;
                    savestate = `${state}.${mod}`;
                    localStorage.setItem("savestate",savestate);
                    dispatchEvent(new CustomEvent('savestate-change', {}));
                }
            }
        }
    }

    async init() {
        // eslint-disable-next-line no-async-promise-executor
        return new Promise(async (resolve) => {
            try {
                this.promiser = await new Promise((res) => {
                    const _promiser = sqlite3Worker1Promiser({
                        onready: () => {
                            res(_promiser);
                        },
                    });
                });
                const response = await this.promiser('open', {
                    filename: 'file:mydatabase.db?vfs=opfs',
                });
                this.dbId = response.dbId;
                resolve(this);
            } catch (err) {
                this.handleError(err);
            }
        });
    }

    async exec(queries) {
        let out = [];
        for (let data of queries) {
            try {
                const db = await this.promiser('exec', {
                    dbId: this.dbId,
                    sql:data.sql.match(/^((insert)|(update)|(delete))/i) ? `${data.sql} returning *` : data.sql,
                    ...data.args ? {bind:data.args} : {},
                    returnValue:"resultRows",
                    rowMode: "object"
                });
                let rows= db.result.resultRows;
                if (data.sql.match(/^((insert)|(update)|(delete))/i) && this.onDataChange && rows.length) {
                    this.onDataChange(data.sql);
                }
                out.push(rows.length ? { rowsAffected : rows.length, insertId : rows[rows.length-1].id } : {});
            } catch (err) {
                this.handleError(err);
            }
        }
        return out;
    }

    async query(sql, bind = []) {
        let isSelect = sql.match(/^select/i);
        let isNonReturning = sql.match(/^((drop)|(create))/i);
        try {
            let db = await this.promiser('exec', {
                dbId: this.dbId,
                sql:isSelect || isNonReturning ? sql : `${sql} returning *`,
                bind,
                returnValue:"resultRows",
                rowMode: "object"
            });
            let rows= db.result.resultRows;
            if (sql.match(/^((insert)|(update)|(delete))/i) && this.onDataChange && rows.length) {
                this.onDataChange(sql);
            }
            return isSelect ? rows : (rows.length ? [{ rowsAffected : rows.length, insertId : rows[rows.length-1].id }] : []);
        } catch (err) {
            this.handleError(err);
        }
    }

    async insert(table, data, opts = {}) {
        let row0 = data.length > 0 ? data[0] : {};
        let cols = Object.keys(row0);
        let placeholders = cols.map(() => "?").join(",");

        let queries = [];
        let bucketSize = 1000;
        while (data.length) {
            let bucket = data.splice(0,Math.min(bucketSize,data.length));
            let sql = `INSERT ${opts.orIgnore ? "OR IGNORE" : ""} INTO ${table} (${cols.join(",")}) VALUES ${bucket.map(()=>`(${placeholders})`).join(",")}`;
            let args = bucket.map(row=>cols.map(col => row[col])).flat();
            queries.push({sql,args});
        }
        if (queries.length) {
            return await this.exec(queries)
        }
        return [];
    }

    getAffectedRows(resultSets) {
        return resultSets.reduce((acc, res) => acc + (res.rowsAffected || 0), 0);
    }

    async close() {
        try {
            await this.promiser('close', { dbId: this.dbId });
        } catch (err) {
            this.handleError(err);
        }
    }

    handleError(err) {
        if (!(err instanceof Error)) {
            err = new Error(err.result.message);
        }
        console.error(err.name, err.message);
    }
}

export default SqliteWasmDatabase;
